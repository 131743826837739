import { postRequestOptions, setOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;
const serviceRoute = 'auth';

export const signIn = (body) => fetch(
  `${SERVER_URL}/${serviceRoute}/v1/signIn`,
  {
    ...postRequestOptions,
    body: JSON.stringify(body),
  },
);

export const signUp = (body) => fetch(
  `${SERVER_URL}/${serviceRoute}/v1/signUp`,
  { ...postRequestOptions, body: JSON.stringify(body) },
);

export const forgotPassword = (email, language) => fetch(
  `${SERVER_URL}/${serviceRoute}/v1/forgotPassword?email=${email}&preferredLanguageId=${language}`,
  { ...postRequestOptions },
);

export const resetPassword = (body) => fetch(
  `${SERVER_URL}/${serviceRoute}/v1/resetPassword`,
  { ...postRequestOptions, body: JSON.stringify(body) },
);

export const verifyResetToken = (resetToken) => fetch(
  `${SERVER_URL}/${serviceRoute}/v1/verifyResetToken?token=${resetToken}`,
  { ...postRequestOptions },
);

export const updatePassword = (body) => fetch(
  `${SERVER_URL}/${serviceRoute}/v1/updateCurrentPassword`,
  { ...setOptions('POST'), body: JSON.stringify(body) },
);
