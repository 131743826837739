/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AuthStates } from '../../constants/authConstants';

const initialState = {
  authStatus: AuthStates.LOADING,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.authStatus = AuthStates.LOGGED_IN;
    },
    logout: (state) => {
      state.authStatus = AuthStates.LOGGED_OUT;
    },
  },
});

export const { login, logout } = authSlice.actions;

const authState = (state) => state.authSlice;

export const authStatusSelector = createSelector([authState], (state) => state.authStatus);

export default authSlice.reducer;
