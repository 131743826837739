import { getRequestOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;
const SERVER_VERSION = process.env.REACT_APP_MENSIS_API_VERSION;

const fetchPricingPackages = (countryID, packageTypeName, lengueje) => fetch(
  `${SERVER_URL}${SERVER_VERSION}/packagesPrice?countryId=${countryID}&packageTypeName=${packageTypeName}&preferredLanguageId=${lengueje}`,
  { ...getRequestOptions },
);

export default fetchPricingPackages;
