import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  Box, Card, Grid, styled, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Fade } from 'react-reveal';
import Footer from '../components/footer/Footer';
import { useGetCurrentUserQuery } from '../store/slices/clientApi';
import FindMentorButton from '../components/button/FindMentorButton';
import theme from '../styles/theme';
import RecommendedMentorCard from '../components/cards/MentorCard/RecommendedMentorCard';
import { useGetRecommendedMentorsQuery } from '../store/slices/recommendedMentorsApi';
import { useGetDemonstrationsByClientQuery } from '../store/slices/mentoringHistoryByClientApi';
import SimpleMentoringCard from '../components/cards/MentoringCard/SimpleMentoringCard';
import useDetectMobile from '../hooks/useDetectMobile';
import PrivateBasicLayout from '../components/layout/PrivateBasicLayout';
import ga from '../AnalyticsEventTracker';

export const howItWorksData = [
  { stepName: 'UserHome.Paso1', description: 'UserHome.Desc1' },
  { stepName: 'UserHome.Paso2', description: 'UserHome.Desc2' },
  { stepName: 'UserHome.Paso3', description: 'UserHome.Desc3' },
];

const StyledCard = styled(Card)({
  backgroundColor: theme.palette.cardsBackground.main,
  borderRadius: '5px',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 2%), 0px 1px 3px 0px rgb(0 0 0 / 9%)',
});

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  marginTop: '8px',
  marginBottom: '5px',
}));

function UserHome() {
  const navigate = useNavigate();
  const { isMobile } = useDetectMobile();
  const [t] = useTranslation('common');
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: recommendedMentors } = useGetRecommendedMentorsQuery();
  const { data: demonstrationsHistory } = useGetDemonstrationsByClientQuery();
  const { state: routerState } = useLocation();

  useEffect(() => {
    if (currentUser && currentUser.client) {
      document.title = `${t('UserHome.Welcome')} ${currentUser.client.name} ${currentUser.client.lastName} | Mensis Mentor`;
    }
  }, [routerState, currentUser, t]);

  return (
    <PrivateBasicLayout showBanner displayLinks={false}>
      <Box
        sx={{
          my: 5,
        }}
      >
        <section id="welcome" style={{ paddingTop: '0px' }}>
          <div className="container">
            <Fade right>
              <Grid
                container
                spacing={2}
                mt={{ xs: 10, sm: 10, md: 2 }}
                sx={{ mt: 0 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  {currentUser && (
                    <Typography
                      variant="h1"
                      sx={{
                        textAlign: 'left', fontSize: '26px', color: '#273B7C', marginBottom: '35px',
                      }}
                    >
                      {t('UserHome.Hola')}
                      {' '}
                      <span style={{ color: '#ec5c24' }}>{currentUser.client?.name}</span>
                      {t('UserHome.Avanza')}
                    </Typography>
                  )}
                  <StyledCard>
                    <Typography
                      sx={{
                        fontSize: '20px', color: '#273B7C', marginBottom: '15px', marginLeft: '15px', marginTop: '5px',
                      }}
                    >
                      {t('UserHome.ComoFunciona')}
                    </Typography>
                    <Grid container onClick={() => navigate('/onboarding', { replace: true })} sx={{ cursor: 'pointer', marginLeft: '-15px' }}>
                      {howItWorksData.map((data) => (
                        <Grid
                          key={data.stepName}
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{ px: 2, width: '100%', textAlign: 'center' }}
                        >
                          <StyledTypography style={{ color: '#ec5c24', fontWeight: 'bold', fontSize: isMobile ? '22px' : '20px' }}>
                            {t(data.stepName)}
                          </StyledTypography>
                          <StyledTypography style={{
                            color: '#273B7C', fontWeight: 'bold', paddingBottom: '35px', fontSize: isMobile ? '12px' : '15px',
                          }}
                          >
                            {t(data.description)}
                          </StyledTypography>
                        </Grid>
                      ))}
                    </Grid>
                  </StyledCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <div sx={{ textAlign: 'center', boxShadow: 'none', backgroundColor: '#fff0' }}>
                    <FindMentorButton onClick={() => { ga('UserHome', 'Click', 'FindMyMentor'); navigate('/onboarding', { replace: true }); }} />
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </div>
        </section>
        <section id="recommended-mentors" style={{ paddingTop: '10px' }}>
          <div className="container">
            <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c' }}>{t('UserHome.MentoresRecomendados')}</h2>
            <p style={{ fontFamily: 'Montserrat', fontSize: '22px !important' }}>
              {t('UserHome.TePreparamos')}
            </p>
            <Grid
              container
              spacing={2}
              mt={{ xs: 10, sm: 10, md: 2 }}
              sx={{ mt: 0 }}
            >
              { recommendedMentors && recommendedMentors.mentors
                ? recommendedMentors.mentors.map((mentor) => (
                  <Grid key={mentor.mentorId} item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <RecommendedMentorCard showBackgroundColor={false} {...mentor} />
                  </Grid>
                )) : (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p style={{ fontFamily: 'Montserrat', fontSize: '22px !important', textAlign: 'center' }}>
                      {t('ClientOnboarding.onboardingSteps.BuscandoMentores')}
                    </p>
                  </Grid>
                )}
            </Grid>
          </div>
        </section>
        <section id="purchased-packages" style={{ paddingTop: '10px' }}>
          <div className="container">
            <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c' }}>{t('UserHome.Mentorios')}</h2>
            <p style={{ fontFamily: 'Montserrat', fontSize: '22px !important' }}>
              {t('UserHome.Trayectoria')}
            </p>
            <Grid
              container
              spacing={2}
              mt={{ xs: 10, sm: 10, md: 2 }}
              sx={{ mt: 0, marginTop: '20px' }}
            >
              <Grid
                key="find-mentor"
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
              >
                <Box
                  sx={{
                    width: isMobile ? '100%' : '300px',
                    boxShadow: 'none',
                    backgroundColor: '#fff0',
                  }}
                >
                  <FindMentorButton margin="0px" onClick={() => { ga('UserHome', 'Click', 'FindMyMentor'); navigate('/onboarding', { replace: true }); }} />
                </Box>
              </Grid>
              { demonstrationsHistory && demonstrationsHistory.demonstrations
                && demonstrationsHistory.demonstrations.map((demonstration) => (
                  <Grid
                    key={demonstration.demonstrationId}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: isMobile ? '100%' : '300px',
                        backgroundColor: 'white',
                        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                        '&:hover': {
                          backgroundColor: '#E9EBF2',
                        },
                      }}
                    >
                      <div style={{ padding: '10px' }}>
                        <SimpleMentoringCard {...demonstration} />
                      </div>
                      <Box sx={{ textAlign: 'right', paddingRight: '10px', marginBottom: '-10px' }}>
                        <p style={{ color: theme.palette.grey[500] }}>{`${t('UserHome.Hace')} ${moment().diff(moment.utc(demonstration.demonstrationCreatedAt).locale('es').local(), 'days')} ${t('UserHome.dias')}${t('UserHome.HaceEn')}`}</p>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </div>
        </section>
      </Box>
      <Footer showImportantLinks={false} />
    </PrivateBasicLayout>
  );
}

UserHome.propTypes = {};

UserHome.defaultProps = {};

export default UserHome;
