export const AuthStates = {
  LOADING: 'loading',
  LOGGED_IN: 'loggedIn',
  LOGGED_OUT: 'loggedOut',
};

export const MensisToken = 'MENSIS_TOKEN';
export const JSessionToken = 'JSESSIONID';

export const auth = false;
