import React from 'react';
import '@fontsource/montserrat';
import './App.css';
import ReactGA from 'react-ga4';
import log from 'loglevel';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import Hotjar from '@hotjar/browser';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import RecommenderTrain from './components/mlModel/RecommenderTrain';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Business from './pages/Business';
import Auth from './pages/Auth';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Bye from './pages/Bye';
import UserHome from './pages/UserHome';
import ClientOnboarding from './pages/ClientOnboarding';
import UpdatePassword from './pages/UpdatePassword';
import ClientProfile from './pages/ClientProfile';
import Academy from './pages/Academy';
import Mentors from './pages/Mentors';

function setGA() {
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send('Mensis App');
  } else {
    window.console.log('Google Analytic ID does not exist.');
    log.info('Google Analytic ID does not exist.');
  }
}

function setPixel() {
  const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID;
  if (FB_PIXEL_ID) {
    const advancedMatching = {
      em: 'info@mensismentor.com',
    }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(FB_PIXEL_ID, advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view
  } else {
    window.console.log('Facebook Pixel ID does not exist.');
    log.info('Facebook Pixel ID does not exist.');
  }
}

function setHotJar() {
  const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
  if (HOTJAR_ID) {
    const siteId = HOTJAR_ID;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
  } else {
    window.console.log('HotJar ID does not exist.');
    log.info('HotJar ID does not exist.');
  }
}
function App() {
  setGA();
  setPixel();
  setHotJar();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/onboarding" element={<ClientOnboarding />} />
        <Route path="/business" element={<Business />} />
        <Route path="/p/:userId" element={<Profile />} />
        <Route path="/login" element={<Auth signIn />} />
        <Route path="/signup" element={<Auth />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="/bye" element={<Bye />} />
        <Route path="/home" element={<UserHome />} />
        <Route path="/profile" element={<ClientProfile />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/mentors" element={<Mentors />} />
        <Route path="*" element={<Home />} />
        <Route path="/recommender" element={<RecommenderTrain />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
