/* eslint-disable max-len */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import {
  DialogContent, DialogContentText, DialogTitle, IconButton, Slide,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo515 } from '../../assets/img/logo515.svg';

const Transition = React.forwardRef((props, ref) => <Slide direction="right" ref={ref} {...props} />);

function LoginAgainDialog({
  showModal, onClose,
}) {
  const [t] = useTranslation('common');
  return (
    <Dialog
      open={showModal}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{ backgroundColor: '#8fa7f58c' }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}><IconButton onClick={onClose}><Close /></IconButton></DialogTitle>
      <DialogContent style={{
        backgroundColor: 'rgba(255,255,255,0.62)', display: 'table-cell', justifyContent: 'center', textAlign: 'center',
      }}
      >
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{
            fontFamily: 'Montserrat', fontSize: '1.2rem', color: '#273B7C', paddingBottom: '10px',
          }}
        >
          <Logo515 style={{ marginRight: '5%' }} width={46} height={46} />
          <b>{t('LoginAgainDialog.Message')}</b>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

LoginAgainDialog.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

LoginAgainDialog.defaultProps = {
  showModal: false,
};

export default LoginAgainDialog;
