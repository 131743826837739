import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextFormField from '../formFields/TextFormField';

function TextField({
  name, control, label, rules, multiline, type, isPasswordType, ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState }) => (
        <TextFormField
          label={label}
          id={name}
          type={type}
          value={value}
          multiline={multiline}
          onChange={onChange}
          helperText={!!fieldState.error && fieldState.error.message}
          error={!!fieldState.error}
          isPasswordType={isPasswordType}
          {...props}
        />

      )}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  multiline: PropTypes.bool,
  isPasswordType: PropTypes.bool,
};

TextField.defaultProps = {
  label: null,
  type: 'text',
  rules: null,
  multiline: false,
  isPasswordType: false,
};

export default TextField;
