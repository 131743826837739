import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
import { YouTube as YouTubeIcon } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import ga from '../../../AnalyticsEventTracker';

function VideoPlayer({ videoUrl, title }) {
  return (
    <Grid
      sx={{ paddingRight: '15px', paddingBottom: '15px' }}
      item
      xs={12}
      sm={6}
      md={6}
      lg={6}
      xl={6}
    >
      <Box sx={{ border: '1px solid #CDCDCD', borderRadius: '5px' }}>
        <ReactPlayer
          url={videoUrl}
          light
          playing
          loop
          controls
          width="100%"
          height="250px"
          style={{ backgroundColor: 'white' }}
          playIcon={<YouTubeIcon sx={{ color: '#f00', fontSize: 70 }} />}
          onClickPreview={() => ga('VideoPlayer', 'Click', 'PlayVideo')}
        />
        <p style={{
          fontFamily: 'Montserrat', fontSize: '22px !important', textAlign: 'center', padding: '10px',
        }}
        >
          {title}
        </p>
      </Box>
    </Grid>
  );
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
};

VideoPlayer.defaultProps = {
  title: null,
};

export default VideoPlayer;
