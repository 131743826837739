import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Fade } from 'react-reveal';
import ga from '../AnalyticsEventTracker';
import useDetectMobile from '../hooks/useDetectMobile';
import LoadingDialog from '../components/dialog/LoadingDialog';
import BasicLayout from '../components/layout/BasicLayout';
import { fetchMentorProfile } from '../services/fetchMentors';
import ServicesStep from '../components/onboarding/ServicesStep';
import ConfirmMentorshipStep from '../components/onboarding/ConfirmMentorshipStep';
import { updateMentors } from '../store/slices/clientOnboardingSlice';
import GalileoStep from '../components/onboarding/GalileoStep';
import { loadingDialogMessageSelector, setMessage, unsetMessage } from '../store/slices/loadingDialogMessageSlice';

const StyledStep = styled(Step)({
  '& .MuiStepLabel-root .Mui-completed': {
    fill: '#C7440D',
  },
  '& .MuiStepLabel-root .Mui-active': {
    fill: '#C7440D',
  },
});

const StyledStepLabel = styled(StepLabel)({
  fontFamily: 'Montserrat',
  '.MuiStepLabel-label': { fontFamily: 'Montserrat' },
});
function Profile() {
  const [t] = useTranslation('common');
  const [step, setStep] = useState(0);
  const { message } = useSelector(loadingDialogMessageSelector);
  const [isThereServerError, setIsThereServerError] = useState(false);
  const { isMobile } = useDetectMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  window.scrollTo(0, 0);
  const { userId } = useParams();
  useEffect(() => {
    ga('MentorProfile', 'Event', 'OpenProfile');
    dispatch(setMessage(t('Profile.Cargando')));
    fetchMentorProfile(userId, t('Idioma'))
      .then((response) => response.json())
      .then((mentorInfo) => {
        if (mentorInfo.status === 200) {
          if (mentorInfo.mentor) {
            dispatch(updateMentors([mentorInfo.mentor]));
            document.title = `${mentorInfo.mentor.mentorName} ${mentorInfo.mentor.mentorLastName} | Mensis Mentor`;
            ga('MentorProfile', 'Event', 'MentorProfileLoadedSuccessful');
          } else {
            ga('MentorProfile', 'Event', 'MentorProfileNotFound');
            navigate('/');
          }
        } else {
          ga('MentorProfile', 'Event', 'MentorProfileFailed');
          navigate('/');
        }
        dispatch(unsetMessage());
      })
      .catch(() => {
        ga('MentorProfile', 'Event', 'MentorProfileFailed');
        dispatch(unsetMessage());
        navigate('/');
      });
  }, [userId, navigate, dispatch, t]);

  const clickReturn = () => {
    setIsThereServerError(false);
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/');
      window.scrollTo(0, 0);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };
  const onboardingSteps = [
    {
      title: t('Profile.onboardingSteps.title1'),
      steppersTitle: t('Profile.onboardingSteps.steppersTitle1'),
      description: t('Profile.onboardingSteps.description1'),
      component: <GalileoStep
        onChange={nextStep}
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
        showPagination={false}
      />,
    },
    {
      title: t('Profile.onboardingSteps.title2'),
      steppersTitle: t('Profile.onboardingSteps.steppersTitle2'),
      description: t('Profile.onboardingSteps.description2'),
      component: <ServicesStep
        onChange={nextStep}
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
      />,
    },
    {
      title: t('Profile.onboardingSteps.title3'),
      steppersTitle: t('Profile.onboardingSteps.steppersTitle3'),
      description: t('Profile.onboardingSteps.description3'),
      component: <ConfirmMentorshipStep
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
      />,
    },
  ];

  return (
    <BasicLayout showBanner displayLinks={false} showBackToTopAnchor={false}>
      <Fade right>
        <div style={{ marginTop: '15px' }}>
          {message && (
          <LoadingDialog
            showModal={!!message}
            text={message}
          />
          )}
          {isThereServerError && (
            <Alert
              sx={{ fontFamily: 'Montserrat' }}
              onClose={() => setIsThereServerError(false)}
              severity="error"
            >
              {t('Profile.Error')}
            </Alert>
          )}
          <Grid container>
            <Grid item md={2} sm={12} xs={12} />
            <Grid item md={8} sm={12} xs={12}>
              <Paper elevation={3} sx={{ mx: 5, my: 2, p: 2 }} style={{ margin: 0 }}>
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step}>
                    {onboardingSteps.map((stepItem) => (
                      <StyledStep key={stepItem.steppersTitle}>
                        <StyledStepLabel>
                          {!isMobile && stepItem.steppersTitle}
                        </StyledStepLabel>
                      </StyledStep>
                    ))}
                  </Stepper>
                  {onboardingSteps[step].component}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </BasicLayout>
  );
}

export default Profile;
