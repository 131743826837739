import { postRequestOptions, setOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;
const serviceRoute = '/onboarding';

export const registerOnboardingV1 = (body) => fetch(
  `${SERVER_URL}/v1${serviceRoute}/demonstration/register`,
  { ...setOptions('POST'), body: JSON.stringify(body) },
);

export const registerOnboardingV2 = (body) => fetch(
  `${SERVER_URL}/v2${serviceRoute}/demonstration/register`,
  { ...setOptions('POST'), body: JSON.stringify(body) },
);

export const registerOnboardingResponsesV2 = (body) => fetch(
  `${SERVER_URL}/v2${serviceRoute}/responses`,
  { ...postRequestOptions, body: JSON.stringify(body) },
);

export const registerOnboardingResponsesV3 = (body) => fetch(
  `${SERVER_URL}/v3${serviceRoute}/responses`,
  { ...setOptions('POST'), body: JSON.stringify(body) },
);
