import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {
  Box, Card, Grid, Typography,
} from '@mui/material';
import {
  GroupWork as GroupWorkIcon,
  Article as ArticleIcon,
  Speed as SpeedIcon,
  Public as PublicIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material';
import { Fade, Zoom } from 'react-reveal';
import './styles.css';
import '../styles/Home.css';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import MensisButton from '../components/button/MensisButton';
import ControlledAccordion from '../components/accordion/ControlledAccordion';
import Footer from '../components/footer/Footer';
import {
  accordionData, statisticalData, areasButtons, publicContentVideos,
} from '../data/GeneralData';
import PreOnboarding from '../components/forms/PreOnboarding';
import BasicLayout from '../components/layout/BasicLayout';
import IconButton from '../components/button/IconButton';
import useDetectMobile from '../hooks/useDetectMobile';
import mensisShareTeamIcon from '../assets/img/mensisShareTeamIcon.png';
import ga from '../AnalyticsEventTracker';
import VideoPlayer from '../components/video/youtube/VideoPlayer';

function PublicHome() {
  const [t] = useTranslation('common');
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const { isMobile } = useDetectMobile();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${t('MensisSlogan')} | Mensis Mentor`;
  }, [t]);

  const handleChangeAccordion = (panel) => (_event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  return (
    <BasicLayout showBanner displayLinks>
      <Box>
        <main id="main">
          <section id="onboarding">
            <div className="container">
              <Fade right>
                <Grid
                  container
                  spacing={2}
                  mt={{ xs: 10, sm: 10, md: 2 }}
                  sx={{ mt: isMobile ? -5 : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ boxShadow: 0, backgroundColor: '#FCFCFC' }}>
                      <PreOnboarding onChange={() => navigate('signup')} />
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    {isMobile ? (
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=fNoEaoFLj1s"
                        light
                        playing
                        loop
                        controls
                        width="100%"
                        playIcon={<YouTubeIcon sx={{ color: '#f00', fontSize: 70 }} />}
                        onClickPreview={() => ga('PublicHome', 'Click', 'PlayVideo')}
                      />
                    ) : (
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=fNoEaoFLj1s"
                        light
                        playing
                        loop
                        controls
                        playIcon={<YouTubeIcon sx={{ color: '#f00', fontSize: 70 }} />}
                        onClickPreview={() => ga('PublicHome', 'Click', 'PlayVideo')}
                      />
                    )}
                  </Grid>
                </Grid>
              </Fade>
            </div>
          </section>
          <section id="information" className="container">
            <Fade bottom>
              <Grid container spacing={2}>
                {statisticalData.map((data) => (
                  <Grid
                    key={data.description}
                    item
                    container
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={4}
                    spacing={4}
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'right' }}>
                      {data.icon}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{
                        fontWeight: 600,
                        fontSize: '42px',
                        marginTop: '40px',
                      }}
                      >
                        {data.number}
                      </Typography>
                      <Typography>
                        {t(data.description)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Fade>
          </section>
          <section id="" className="container">
            <Fade bottom>
              <div>
                <Typography
                  sx={{
                    textAlign: 'left', fontSize: '2rem', color: '#273B7C', marginBottom: '30px',
                  }}
                >
                  {t('PublicHome.Titulo.p1')}
                </Typography>
                <Typography sx={{
                  textAlign: 'left', marginBottom: '15px',
                }}
                >
                  {t('PublicHome.Cuerpo.1')}
                </Typography>
                <Grid container spacing={4}>
                  {areasButtons.map((item) => (
                    <Grid
                      key={item.label}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <IconButton
                        icon={item.icon}
                        label={t(item.label)}
                        onClick={() => navigate('onboarding')}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Fade>
          </section>
          <section id="services" className="services section-bg">
            <div className="container">
              <Fade bottom>
                <div className="section-title">
                  <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c' }}>
                    {t('PublicHome.Cuerpo.2')}
                  </h2>
                  <p style={{ fontFamily: 'Montserrat', fontSize: '22px !important' }}>
                    {t('PublicHome.Cuerpo.3')}
                  </p>
                </div>
              </Fade>
              <div className="row">
                <Zoom>
                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box icon-box-pink">
                      <GroupWorkIcon sx={{ color: '#ff689b', fontSize: 48 }} />
                      <h4 className="title mt-3" style={{ fontFamily: 'Montserrat' }}>
                        {t('PublicHome.simulacionTitulo')}
                      </h4>
                      <p className="description" style={{ fontFamily: 'Montserrat' }}>
                        {t('PublicHome.simulacionCuerpo')}
                      </p>
                    </div>
                  </div>
                </Zoom>
                <Zoom delay={100}>
                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box icon-box-cyan">
                      <ArticleIcon sx={{ color: '#3fcdc7', fontSize: 48 }} />
                      <h4
                        className="title mt-3"
                        style={{ fontFamily: 'Montserrat' }}
                      >
                        {t('PublicHome.RevisionCvTitulo')}
                      </h4>
                      <p
                        className="description"
                        style={{ fontFamily: 'Montserrat' }}
                      >
                        {t('PublicHome.RevisionCvCuerpo')}
                      </p>
                    </div>
                  </div>
                </Zoom>
                <Zoom delay={200}>
                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box icon-box-green">
                      <SpeedIcon sx={{ color: '#41cf2e', fontSize: 48 }} />
                      <h4
                        className="title mt-3"
                        style={{ fontFamily: 'Montserrat' }}
                      >
                        {t('PublicHome.RevisionLinkTitulo')}
                      </h4>
                      <p
                        className="description"
                        style={{ fontFamily: 'Montserrat' }}
                      >
                        {t('PublicHome.RevisionLinkCuerpo')}
                      </p>
                    </div>
                  </div>
                </Zoom>
                <Zoom delay={300}>
                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box icon-box-blue">
                      <PublicIcon sx={{ color: '#2282ff', fontSize: 48 }} />
                      <h4
                        className="title mt-3"
                        style={{ fontFamily: 'Montserrat' }}
                      >
                        {t('PublicHome.PreparacionEntrevistaTitulo')}
                      </h4>
                      <p
                        className="description"
                        style={{ fontFamily: 'Montserrat' }}
                      >
                        {t('PublicHome.PreparacionEntrevistaCuerpo')}
                      </p>
                    </div>
                  </div>
                </Zoom>
              </div>
            </div>
          </section>
          <section id="cta" className="cta">
            <div className="container">
              <Zoom>
                <div className="row">
                  <div className="col-lg-9 text-center text-lg-start">
                    <h3 style={{ fontFamily: 'Montserrat', color: '#273b7c' }}>
                      {t('PublicHome.registroTitulo')}
                    </h3>
                    <p style={{ fontFamily: 'Montserrat' }}>
                      {t('PublicHome.registroCuerpo')}
                    </p>
                  </div>
                  <div className="col-lg-3 cta-btn-container text-center">
                    <MensisButton
                      color="var(--info)"
                      label={t('PublicHome.btnUnirse')}
                      onClick={() => navigate('signup')}
                    />
                  </div>
                </div>
              </Zoom>
            </div>
          </section>
          <section id="testimonials" className="testimonials">
            <div className="container">
              <Fade bottom>
                <div className="section-title">
                  <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c', fontSize: '32px' }}>
                    {t('PublicHome.Historias.titulo')}
                    {/* <span style={{ color: '#ec5c24' }}>historias de éxito</span> */ }
                  </h2>
                  <p style={{ fontFamily: 'Montserrat' }}>
                    {t('PublicHome.Historias.cuerpo')}
                  </p>
                </div>
              </Fade>
              <Grid container>
                {publicContentVideos.map((item) => (
                  <VideoPlayer key={item.title} videoUrl={item.url} title={item.title} />
                ))}
              </Grid>
            </div>
          </section>
          <section id="cta1">
            <div className="container">
              <Zoom>
                <div className="row">
                  <Grid container spacing={2} mt={{ xs: 10, sm: 10, md: 2 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Card sx={{ boxShadow: 0, backgroundColor: '#FCFCFC' }}>
                        <img width="100%" src={mensisShareTeamIcon} alt="Sketch Mensis team" />
                      </Card>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <div style={{ marginLeft: '30px' }}>
                        <h3 style={{ fontFamily: 'Montserrat', color: '#273b7c', paddingBottom: '20px' }}>
                          {t('PublicHome.unase')}
                        </h3>
                        <MensisButton
                          color="#ec5c24"
                          label={t('PublicHome.btnUnirse')}
                          onClick={() => navigate('signup')}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Zoom>
            </div>
          </section>
          <section id="faq" className="faq">
            <div className="container">
              <Fade bottom>
                <div className="section-title">
                  <h2
                    style={{
                      fontFamily: 'Montserrat',
                      color: '#273b7c',
                    }}
                  >
                    {t('PublicHome.PreguntasTitulo')}
                  </h2>
                </div>
              </Fade>
              {accordionData.map((item, idx) => (
                <Fade key={item.key} bottom delay={(idx + 1) * 100}>
                  <ControlledAccordion
                    summary={t(item.summary)}
                    details={t(item.details)}
                    expanded={expandedAccordion === item.key}
                    onChanging={handleChangeAccordion(item.key)}
                  />
                </Fade>
              ))}
            </div>
          </section>
          <section id="contacto" className="contact section-bg">
            <div className="container">
              <Fade bottom>
                <div className="section-title">
                  <h2
                    style={{
                      fontFamily: 'Montserrat',
                      color: '#273b7c',
                    }}
                  >
                    {t('PublicHome.Contacto')}
                  </h2>
                </div>
              </Fade>
              <div className="row">
                <Fade left>
                  <div className="col-lg-5 d-flex align-items-stretch">
                    <div className="info">
                      <div className="address">
                        <i className="bi bi-geo-alt" />
                        <h4 style={{ fontFamily: 'Montserrat' }}>
                          {t('PublicHome.Locacion')}
                        </h4>
                        <p style={{ fontFamily: 'Montserrat' }}>
                          {t('PublicHome.LocacionCuerpo')}
                        </p>
                      </div>
                      <div className="email">
                        <i className="bi bi-envelope" />
                        <h4 style={{ fontFamily: 'Montserrat' }}>
                          {t('PublicHome.Correo')}
                        </h4>
                        <p style={{ fontFamily: 'Montserrat' }}>
                          <a className="mailto" href="mailto:info@mensismentor.com" style={{ textDecoration: 'none', color: '#627680' }}>info@mensismentor.com</a>
                        </p>
                      </div>
                      <div className="phone">
                        <i className="bi bi-phone" />
                        <h4 style={{ fontFamily: 'Montserrat' }}>
                          {t('PublicHome.Telefono')}
                        </h4>
                        <p style={{ fontFamily: 'Montserrat' }}>
                          <a href="tel:+50687566313" style={{ textDecoration: 'none', color: '#627680' }}>+506 8756 6313</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                    <iframe
                      title="location"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930.0327011371287!2d-84.10431268520641!3d9.931234592898317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fcacdfdb6d97%3A0xf35790826dbd35c9!2sExecutive%20Office%20Center%20La%20Sabana!5e0!3m2!1sen!2scr!4v1650547311325!5m2!1sen!2scr"
                      frameBorder="0"
                      style={{ border: 0, width: '100%', height: '300px' }}
                    />
                  </div>
                </Fade>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Box>
    </BasicLayout>
  );
}

PublicHome.propTypes = {};

PublicHome.defaultProps = {};

export default PublicHome;
