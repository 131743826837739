import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Box, CssBaseline, Fab,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetBannerMessageQuery } from '../../store/slices/bannerMessageApi';
import Banner from '../banner/Banner';
import ScrollTop from './ScrollTop';
import PrivateNavbar from '../nav/navbar/PrivateNavbar';
import useAuth from '../../hooks/useAuth';
import { AuthStates } from '../../constants/authConstants';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function PrivateBasicLayout({
  showBanner, children, showBackToTopAnchor,
}) {
  const { data: activeBannerMessage } = useGetBannerMessageQuery();
  const { authStatus } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === AuthStates.LOGGED_OUT) {
      navigate('/bye');
    }
  }, [authStatus, navigate]);

  if (authStatus === AuthStates.LOGGED_IN) {
    return (
      <Box sx={{
        display: 'flex', width: '100%', flexDirection: 'column', backgroundColor: '#FCFCFC',
      }}
      >
        <CssBaseline />
        <PrivateNavbar />
        {activeBannerMessage && activeBannerMessage.banner && showBanner && (
        <Banner
          {...activeBannerMessage?.banner}
          showCountDown={
            activeBannerMessage?.banner.showCountDown === 1 || false
          }
          customMargin={65}
        />
        )}
        <DrawerHeader id="back-to-top-anchor" />
        {children}
        { showBackToTopAnchor && (
        <ScrollTop>
          <Fab size="small" aria-label="scroll back to top" style={{ backgroundColor: 'var(--secondary-logo)', color: 'white' }}>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        )}
      </Box>
    );
  }
}

PrivateBasicLayout.propTypes = {
  showBanner: PropTypes.bool,
  children: PropTypes.node.isRequired,
  showBackToTopAnchor: PropTypes.bool,
};

PrivateBasicLayout.defaultProps = {
  showBanner: true,
  showBackToTopAnchor: true,
};

export default PrivateBasicLayout;
