import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, Grid,
  IconButton, InputAdornment, Link as MuiLink,
  styled,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import theme from '../styles/theme';
import useDetectMobile from '../hooks/useDetectMobile';
import TextField from '../components/controllerFormFields/TextField';
import { updatePassword } from '../services/authService';
import AuthCard from '../components/cards/AuthCard/AuthCard';
import LoadingDialog from '../components/dialog/LoadingDialog';
import ga from '../AnalyticsEventTracker';
import useAuth from '../hooks/useAuth';
import PrivateBasicLayout from '../components/layout/PrivateBasicLayout';

const StyledTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '20px',
});

const StyledButton = styled(Button)({
  borderRadius: '5px',
  fontSize: '24px',
  textTransform: 'inherit',
});

function StyledRequiredLabel() {
  const [t] = useTranslation('common');
  return (
    <span style={{ color: theme.palette.grey[500], fontSize: '0.7rem' }}>{t('UpdatePassword.CampoRequerido')}</span>
  );
}

function UpdatePassword() {
  useAuth();

  const {
    control,
    watch,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });
  const [t] = useTranslation('common');
  const [open, setOpen] = useState(false);
  const { windowWidth } = useDetectMobile();
  const navigate = useNavigate();
  const password = watch('password');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
  const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    const body = {
      currentPassword: data.currentPassword,
      newPassword: data.password,
    };
    setErrorMessage(null);
    setIsLoading(true);
    ga('UpdatePassword', 'Click', 'PasswordChange');
    updatePassword(body)
      .then((response) => response.json())
      .then((forgotResponse) => {
        if (forgotResponse.status === 200) {
          setOpen(true);
          ga('UpdatePassword', 'Event', 'SuccessfulPasswordChanged');
        } else {
          setErrorMessage(t('UpdatePassword.ContraseñaIncorrecta'));
          ga('UpdatePassword', 'Event', 'FailedPasswordChanged');
        }
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setOpen(false);
    navigate('/profile');
  };

  return (
    <PrivateBasicLayout showBanner displayLinks>
      <Box sx={{
        my: 5,
        marginRight: windowWidth < 800 ? '10px' : '25%',
        marginLeft: windowWidth < 800 ? '10px' : '25%',
      }}
      >
        <AuthCard>
          {isLoading && <LoadingDialog showModal={isLoading} text={t('UpdatePassword.Actualizando')} />}
          <StyledTypography sx={{ fontSize: '32px' }}>
            {t('UpdatePassword.Titulo')}
          </StyledTypography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 5 }}
          >
            <StyledTypography sx={{ weight: 'bold', size: '15px' }}>
              {t('UpdatePassword.Actual')}
              <StyledRequiredLabel />
            </StyledTypography>
            <TextField
              name="currentPassword"
              placeholder={t('UpdatePassword.Contraseña')}
              control={control}
              type={showCurrentPassword ? 'text' : 'password'}
              rules={{
                required: t('UpdatePassword.ContraseñaNecesaria'),
                maxLength: {
                  value: 30,
                  message: t('UpdatePassword.Contraseña730'),
                },
                minLength: {
                  value: 7,
                  message: t('UpdatePassword.Contraseña730'),
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoFocus
            />
            <StyledTypography sx={{ weight: 'bold', size: '15px', mt: 2 }}>
              {t('UpdatePassword.Nueva')}
              <StyledRequiredLabel />
            </StyledTypography>
            <TextField
              name="password"
              placeholder={t('UpdatePassword.Contraseña')}
              control={control}
              type={showPasswordNew ? 'text' : 'password'}
              rules={{
                required: t('UpdatePassword.ContraseñaNecesaria'),
                maxLength: {
                  value: 30,
                  message: t('UpdatePassword.Contraseña730'),
                },
                minLength: {
                  value: 7,
                  message: t('UpdatePassword.Contraseña730'),
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPasswordNew}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <StyledTypography sx={{ weight: 'bold', size: '15px', mt: 2 }}>
              {t('UpdatePassword.Confirmar')}
              <StyledRequiredLabel />
            </StyledTypography>
            <TextField
              name="confirmPassword"
              placeholder={t('UpdatePassword.Contraseña')}
              control={control}
              type={showPasswordConfirm ? 'text' : 'password'}
              rules={{
                required: t('UpdatePassword.ContraseñaNecesaria'),
                validate: {
                  positive: (v) => v === password || t('UpdatePassword.Coincidir'),
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={!isValid}
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              {t('UpdatePassword.Cambiar')}
            </StyledButton>
            {errorMessage && <Typography color="#d32f2f" variant="subtitle1" sx={{ marginTop: '-10px', fontWeight: 'bold' }}>{errorMessage}</Typography>}
            <Grid container sx={{ paddingTop: '45px' }}>
              <Grid
                item
                xs
              >
                <StyledTypography sx={{ fontSize: '18px' }}>
                  {t('UpdatePassword.volver')}
                  {'  '}
                  <MuiLink
                    onClick={() => navigate('/profile')}
                    component="button"
                    color="secondary"
                    type="button"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {t('UpdatePassword.Miperfil')}
                  </MuiLink>
                </StyledTypography>
              </Grid>
            </Grid>
          </Box>
        </AuthCard>
      </Box>
      <Footer />
      <Dialog
        onClose={closeModal}
        open={open}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ marginTop: '10px' }}>
            {t('UpdatePassword.CambioExito')}
          </Typography>
        </DialogContent>
      </Dialog>
    </PrivateBasicLayout>
  );
}

UpdatePassword.propTypes = {};

UpdatePassword.defaultProps = {};

export default UpdatePassword;
