/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Stack, styled, Typography,
} from '@mui/material';
import { useGetCurrentCountryQuery } from '../../store/slices/currentCountryApi';
import {
  onboardingSteps,
} from '../../data/QuestionsData';
import ga from '../../AnalyticsEventTracker';
import useDetectMobile from '../../hooks/useDetectMobile';

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  backgroundColor: '#273B7C',
  color: 'white',
  height: '50px',
  borderRadius: '5px',
  '&:hover': {
    color: '#273B7C',
    backgroundColor: '#B4C5FF',
    boxShadow: '2px 2px 10px #888888',
  },
  textTransform: 'none',
  marginTop: '25px',
  fontSize: '1rem',
  boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
});

function PreOnboarding({ onChange }) {
  const [t] = useTranslation('common');
  const [stepsOnboarding] = useState(onboardingSteps);
  const { data: currentCountry } = useGetCurrentCountryQuery();
  const { isMobile } = useDetectMobile();

  function isTheCountryIdInTheOptions(options, countryId) {
    return options.filter((el) => el.id === countryId).length > 0;
  }

  const onButtonSubmit = () => {
    const stepsCopy = JSON.parse(JSON.stringify(stepsOnboarding));
    if (currentCountry && currentCountry.country_code_iso3) {
      const countriesLookingForEmployment = stepsCopy?.questions.find((question) => question.id === 'jobSeekingCountries');
      if (isTheCountryIdInTheOptions(countriesLookingForEmployment.options, currentCountry.country_code_iso3)) {
        const country = countriesLookingForEmployment.options.filter((el) => el.id === currentCountry.country_code_iso3);
        stepsCopy.defaultValuesForm.jobSeekingCountries = { id: currentCountry.country_code_iso3, label: country[0].label };
      }
    }
    ga('PreOnboarding', 'Click', 'FreeAccessButton');
    onChange({ onboardingSteps: stepsCopy });
  };

  return (
    <Stack mx="10px" my="10px">
      <Typography
        variant="h1"
        sx={{
          textAlign: 'left', fontSize: '50px', color: '#ec5c24', marginBottom: '15px',
        }}
      >
        {t('PreOnboarding.Titulo')}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          textAlign: 'left', fontSize: '35px', color: '#273B7C', marginBottom: '15px',
        }}
      >
        {t('PreOnboarding.Cuerpo.1')}
      </Typography>
      <Typography
        variant="h3"
        sx={{
          textAlign: 'left', fontSize: '1rem', marginBottom: '30px', color: '#273B7C',
        }}
      >
        {t('PreOnboarding.Cuerpo.2')}
      </Typography>
      <StyledButton
        sx={{ width: isMobile ? '100%' : '250px' }}
        label="Registrarme"
        onClick={() => onButtonSubmit()}
      >
        {t('PreOnboarding.BotonAcceder')}
      </StyledButton>
    </Stack>
  );
}

PreOnboarding.propTypes = {
  onChange: PropTypes.func.isRequired,
};

PreOnboarding.defaultProps = {};

export default PreOnboarding;
