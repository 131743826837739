import PropTypes from 'prop-types';

function FluidImage({
  imgURL, altText, width, height,
}) {
  return (
    <img src={imgURL} width={width} height={height} alt={altText} />
  );
}

FluidImage.propTypes = {
  imgURL: PropTypes.string.isRequired,
  altText: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

FluidImage.defaultProps = {
  altText: '',
  width: '100%',
  height: 'auto',
};

export default FluidImage;
