import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, FormControl, Snackbar } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import ga from '../../AnalyticsEventTracker';
import MentorCard from '../cards/MentorCard/MentorCard';

function MentorCardField({
  id, options, onChange, onFinish, clientJobArea, showPagination, ...props
}) {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [isProfileCopied, setProfileCopied] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setProfileCopied(false);
  };

  const handleReturn = () => {
    ga('OnBoarding', 'Click', 'MentorCard_Back');
    if (selectedIdx > 0) setSelectedIdx(selectedIdx - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    ga('OnBoarding', 'Click', 'MentorCard_Next');
    if (selectedIdx < options.length - 1) setSelectedIdx(selectedIdx + 1);
    window.scrollTo(0, 0);
  };

  const handleAccept = () => {
    ga('OnBoarding', 'Click', 'MentorCard_Accept');
    onChange(options[selectedIdx].mentorId);
    onFinish(options[selectedIdx].mentorId);
  };

  const handleChange = (e, p) => {
    ga('OnBoarding', 'Click', 'MentorCard_Change');
    setSelectedIdx(p - 1);
  };

  const handleProfileCopy = () => {
    ga('OnBoarding', 'Click', 'CopyLink');
    setProfileCopied(true);
  };

  return (
    <FormControl sx={{ width: '100%' }} id={id}>
      <Snackbar
        open={isProfileCopied}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert color="secondary" onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Enlace copiado
        </Alert>
      </Snackbar>
      <SwipeableViews
        enableMouseEvents
        index={selectedIdx}
        onChangeIndex={(newIndex) => setSelectedIdx(newIndex)}
      >
        {options && options.map((mentor) => (
          <MentorCard
            key={mentor.mentorId}
            {...mentor}
            showReturn={selectedIdx > 0}
            showNext={selectedIdx < options.length - 1}
            handleChange={handleChange}
            onClickAccept={handleAccept}
            onClickNext={handleNext}
            onClickReturn={handleReturn}
            index={selectedIdx}
            totalElements={options.length}
            clientJobArea={clientJobArea}
            showPagination={showPagination}
            onProfileCopy={handleProfileCopy}
            {...props}
          />
        ))}
      </SwipeableViews>
    </FormControl>
  );
}

MentorCardField.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    mentorId: PropTypes.number.isRequired,
    profilePhotoUrl: PropTypes.string,
    mentorName: PropTypes.string.isRequired,
    mentorLastName: PropTypes.string,
    currentPosition: PropTypes.string.isRequired,
    liveCountry: PropTypes.string.isRequired,
    liveCountryId: PropTypes.string,
    mentorStars: PropTypes.number,
    servicesNumber: PropTypes.number,
    englishLevel: PropTypes.string.isRequired,
    experienceYears: PropTypes.number.isRequired,
    score: PropTypes.string || PropTypes.number,
    profileDescription: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  clientJobArea: PropTypes.any.isRequired,
  showPagination: PropTypes.bool,
};

MentorCardField.defaultProps = {
  showPagination: true,
};

export default MentorCardField;
