import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import {
  DialogContent, DialogTitle, IconButton, Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FluidImage from '../images/FluidImage';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

function ProfilePhotoDialog({ showModal, imgURL, onClose }) {
  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      onClose={onClose}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{ backgroundColor: '#8fa7f58c' }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: '#273B7C',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{
        backgroundColor: 'rgba(255,255,255,0.62)', display: 'table-cell', justifyContent: 'center', textAlign: 'center',
      }}
      >
        <FluidImage imgURL={imgURL} />
      </DialogContent>
    </Dialog>
  );
}

ProfilePhotoDialog.propTypes = {
  showModal: PropTypes.bool,
  imgURL: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

ProfilePhotoDialog.defaultProps = {
  showModal: false,
};

export default ProfilePhotoDialog;
