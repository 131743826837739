/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
};

const loadingDialogMessageSlice = createSlice({
  name: 'loadingDialogMessage',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    unsetMessage: (state) => {
      state.message = null;
    },
  },
});

export const { setMessage, unsetMessage } = loadingDialogMessageSlice.actions;

export const loadingDialogMessageSelector = (state) => state.loadingDialogMessageSlice;

export default loadingDialogMessageSlice.reducer;
