import { combineReducers } from 'redux';
import onboardingAnswersSlice from './slices/onboardingAnswersSlice';
import loadingDialogMessageSlice from './slices/loadingDialogMessageSlice';
import clientOnboardingSlice from './slices/clientOnboardingSlice';
import authSlice from './slices/authSlice';
import mentorsPageSlice from './slices/mentorsPageSlice';
import { currentCountryApi } from './slices/currentCountryApi';
import { bannerMessageApi } from './slices/bannerMessageApi';
import { clientApi } from './slices/clientApi';
import { recommendedMentors } from './slices/recommendedMentorsApi';
import { demonstrationsByClient } from './slices/mentoringHistoryByClientApi';
import { mentors } from './slices/mentorsApi';

const rootReducer = combineReducers({
  onboardingAnswersSlice,
  loadingDialogMessageSlice,
  clientOnboardingSlice,
  authSlice,
  mentorsPageSlice,
  [currentCountryApi.reducerPath]: currentCountryApi.reducer,
  [bannerMessageApi.reducerPath]: bannerMessageApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [recommendedMentors.reducerPath]: recommendedMentors.reducer,
  [demonstrationsByClient.reducerPath]: demonstrationsByClient.reducer,
  [mentors.reducerPath]: mentors.reducer,
});

export default rootReducer;
