import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, CardHeader, Radio, Typography, styled, SvgIcon,
} from '@mui/material';
import theme from '../../styles/theme';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => !['selected'].includes(prop),
})(({ selected }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '20px',
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
  }),
  '&:hover': {
    backgroundColor: 'rgba(39, 59, 124, 0.1)',
    boxShadow: '2px 2px 10px #888888',
    ...(selected && {
      backgroundColor: 'rgba(39, 59, 124, 0.9)',
    }),
  },
}));

const StyledRadio = styled(Radio)({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.common.white,
  },
});

const StyledIcon = styled(SvgIcon, {
  shouldForwardProp: (prop) => !['iconSize'].includes(prop),
})(({ iconSize }) => ({
  fontSize: iconSize,
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => !['selected'].includes(prop),
})(({ selected }) => ({
  color: theme.palette.primary.main,
  ...(selected && {
    color: theme.palette.common.white,
  }),
  fontFamily: 'Montserrat',
  textTransform: 'none',
  marginLeft: '8px',
  marginRight: '8px',
}));

function ComplexButton({
  label, icon, selected, iconSize, withRadioButton, viewBox, onClick,
}) {
  return (
    <StyledCard
      onClick={onClick}
      selected={selected}
      elevation={3}
      sx={{ height: '100%', cursor: 'pointer' }}
    >
      <CardHeader
        action={(
            withRadioButton && <StyledRadio checked={selected} />
          )}
        sx={{ paddingBottom: 0, paddingRight: '10px', paddingTop: '5px' }}
      />
      <CardContent sx={{ textAlign: 'center', padding: 0, paddingBottom: '5px' }}>
        {icon && (
          <StyledIcon
            iconSize={iconSize}
            selected={selected}
            htmlColor={selected ? theme.palette.common.white : theme.palette.primary.main}
            viewBox={viewBox}
            fill="none"
          >
            {React.isValidElement(icon) ? icon : <path d={icon} strokeWidth="2" /> }
          </StyledIcon>
        )}
        <StyledTypography selected={selected}>
          {label}
        </StyledTypography>
      </CardContent>
    </StyledCard>
  );
}

ComplexButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  selected: PropTypes.bool,
  withRadioButton: PropTypes.bool,
  iconSize: PropTypes.string || PropTypes.number,
  viewBox: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ComplexButton.defaultProps = {
  label: '',
  icon: null,
  selected: false,
  withRadioButton: true,
  viewBox: '0 0 24 24',
  iconSize: '1.5rem',
};

export default ComplexButton;
