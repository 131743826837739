import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RenderForm from '../forms/RenderForm';
import ga from '../../AnalyticsEventTracker';
import { fetchBestMentors } from '../../services/fetchMentors';
import { getRndInteger } from '../../common/Util';
import { onboardingSteps } from '../../data/QuestionsData';
import { useGetCurrentCountryQuery } from '../../store/slices/currentCountryApi';
import { setMessage, unsetMessage } from '../../store/slices/loadingDialogMessageSlice';
import {
  updateMentors, updateAnswers, updateRawResponses, rawResponsesSelector, setResponsesId,
} from '../../store/slices/clientOnboardingSlice';
import registerDataLogV1 from '../../services/dataLogService';
import { AuthStates } from '../../constants/authConstants';
import useAuth from '../../hooks/useAuth';
import useDetectMobile from '../../hooks/useDetectMobile';

function ClientOnboardingQuestionsStep({
  onFinish,
  onReturn,
  isServerError,
}) {
  const formData = useSelector(rawResponsesSelector);
  const dispatch = useDispatch();
  const { data: currentCountry } = useGetCurrentCountryQuery();
  const { authStatus } = useAuth();
  const { isMobile } = useDetectMobile();
  const [t] = useTranslation('common');

  window.scrollTo(0, 0);

  useEffect(() => {
    if (currentCountry) {
      const stepsCopy = JSON.parse(JSON.stringify(onboardingSteps));
      if (currentCountry && currentCountry.country_code_iso3) {
        const countriesLookingForEmployment = stepsCopy?.questions.find(
          (question) => question.id === 'jobSeekingCountries',
        );
        if (
          countriesLookingForEmployment.options.filter(
            (el) => el.id === currentCountry.country_code_iso3,
          ).length > 0
        ) {
          const country = countriesLookingForEmployment.options.filter(
            (el) => el.id === currentCountry.country_code_iso3,
          );
          if (!formData || (formData && formData.jobSeekingCountries && formData.jobSeekingCountries.id === '')) {
            stepsCopy.defaultValuesForm.jobSeekingCountries = {
              id: currentCountry.country_code_iso3,
              label: t(country[0].label),
            };
          } else {
            stepsCopy.defaultValuesForm.jobSeekingCountries = {
              id: formData.jobSeekingCountries.id,
              label: t(formData.jobSeekingCountries.label),
            };
          }
        } else if (formData && formData.jobSeekingCountries && formData.jobSeekingCountries.id) {
          stepsCopy.defaultValuesForm.jobSeekingCountries = {
            id: formData.jobSeekingCountries.id,
            label: t(formData.jobSeekingCountries.label),
          };
        }
      }
      dispatch(updateRawResponses(stepsCopy.defaultValuesForm || {}));

      if (authStatus === AuthStates.LOGGED_IN) {
        registerDataLogV1({
          event: 'START_ONBOARDING',
          description: isMobile ? 'From phone' : 'From tablet or computer',
        }).then((response) => response.json());
      }
    }
    // eslint-disable-next-line
  }, [currentCountry, dispatch, authStatus, t]);

  const callGetBestMentorsRequest = (body) => {
    const startTime = Date.now();
    dispatch(setMessage(t('ClientOnboarding.onboardingSteps.BuscandoMentores')));
    fetchBestMentors(body)
      .then((response) => response.json())
      .then((dataResponse) => {
        const duration = Date.now() - startTime;
        if (dataResponse.status === 200) {
          if (dataResponse.mentors && dataResponse.mentors.length > 0) {
            ga('OnBoarding', 'Event', 'GalileoRecommenderSuccessful');
            const waitFor = getRndInteger(1, 3) * 1000;
            if (duration < waitFor) {
              setTimeout(() => {
                dispatch(unsetMessage());
                dispatch(updateMentors(dataResponse.mentors));
                onFinish();
              }, waitFor - duration);
            } else {
              dispatch(unsetMessage());
              dispatch(updateMentors(dataResponse.mentors));
              onFinish();
            }
          } else {
            dispatch(unsetMessage());
            isServerError(true);
            ga('OnBoarding', 'Event', 'GalileoRecommenderNotMentors');
          }
        } else {
          ga('OnBoarding', 'Event', 'GalileoRecommenderNotOK');
          isServerError(true);
          dispatch(unsetMessage());
        }
      })
      .catch(() => {
        ga('OnBoarding', 'Event', 'GalileoRecommenderFailed');
        isServerError(true);
        dispatch(unsetMessage());
      });
  };

  const questionsAnswersFormat = (answersData) => {
    const questionsResponsesTemporal = {};
    Object.keys(answersData).forEach((key, index) => {
      const question = onboardingSteps.questions.find((x) => x.id === key);
      if (answersData[key] && question?.label?.length > 0) {
        if (answersData[key].length > 0) {
          const filteredResponses = answersData[key]
            .filter((answer) => answer.label)
            .map((answer) => ({
              text: t(answer.label),
              keyword: answer.id,
            }));
          if (filteredResponses && filteredResponses.length > 0) {
            questionsResponsesTemporal[key] = {
              id: index + 2,
              dependenciesIds: [0],
              question: {
                text: t(question.label),
                keyword: key,
              },
              responses: filteredResponses,
            };
          }
        } else if (answersData[key].label) {
          questionsResponsesTemporal[key] = {
            id: index + 2,
            dependenciesIds: [0],
            question: {
              text: t(question.label),
              keyword: key,
            },
            responses: [
              {
                text: t(answersData[key].label),
                keyword: answersData[key].id,
              },
            ],
          };
        }
      }
    });
    dispatch(updateAnswers(questionsResponsesTemporal));
  };

  const onFinishStep = (stepData) => {
    const answersData = { ...formData, ...stepData };
    if (answersData && !answersData.desiredEmploymentArea) {
      answersData.desiredEmploymentArea = {
        id: 'other',
        label: t('ClientOnboarding.onFinishStep'),
      };
    }
    dispatch(updateRawResponses(answersData));
    dispatch(setResponsesId(null));
    questionsAnswersFormat(answersData);

    const body = {
      features: {},
      preferredLanguageId: t('Idioma'),
    };

    if (
      answersData.desiredEmploymentArea.id
      && answersData.desiredEmploymentArea !== 'other'
    ) {
      body.features.desiredEmploymentArea = answersData.desiredEmploymentArea.id;
    }
    if (answersData.englishLevel) {
      body.features.englishLevel = answersData.englishLevel.id;
    }
    if (answersData.experienceYears) {
      body.features.experienceYears = answersData.experienceYears.id;
    }
    if (answersData.jobSeekingCountries) {
      body.features.country = answersData.jobSeekingCountries.id;
    }

    callGetBestMentorsRequest(body);
  };

  return (
    <RenderForm
      isStep
      questions={onboardingSteps.questions}
      defaultValuesForm={formData}
      onChange={onFinishStep}
      onReturn={onReturn}
      id="client-onboarding"
      formData={formData}
    />
  );
}

ClientOnboardingQuestionsStep.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  isServerError: PropTypes.func.isRequired,
};

ClientOnboardingQuestionsStep.defaultProps = {};

export default ClientOnboardingQuestionsStep;
