import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import useCountdown from '../../hooks/useCountdown';
import MensisButton from '../button/MensisButton';
import useDetectMobile from '../../hooks/useDetectMobile';

function Banner({
  title,
  subtitle,

  buttonText,
  buttonColor,
  buttonURL,

  showCountDown,
  countDownTitle,
  countDownEndDate,
  countDowBackgroundColor,

  bannerPosition,
  customMargin,
  backgroundColor,
  textColor,
  ...props
}) {
  const [topMargin, setTopMargin] = useState(0);
  const [buttonMargin, setButtonMargin] = useState(0);
  const {
    days, hours, minutes, seconds,
  } = useCountdown(countDownEndDate);
  const { isMobile } = useDetectMobile();

  useEffect(() => {
    if (bannerPosition === 'button') {
      setTopMargin('auto');
      setButtonMargin(customMargin);
    } else {
      setTopMargin(customMargin);
      setButtonMargin('auto');
    }
  }, [bannerPosition, customMargin]);

  const handleButtonClick = () => {
    window.open(buttonURL, '_blank');
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundColor,
        color: textColor,
        position: 'sticky',
        top: topMargin,
        bottom: buttonMargin,
        right: 0,
        left: 0,
        paddingBottom: 5,
        paddingTop: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 100,
      }}
      {...props}
    >
      <div style={{ marginLeft: 10 }}>
        <div style={{ fontSize: subtitle ? '1.1rem' : '1.3rem', fontWeight: 'bold' }}>{title}</div>
        <div style={{ fontSize: '0.8rem' }}>{subtitle}</div>
      </div>
      {showCountDown
        && countDownEndDate
        && (
          <div style={{
            marginLeft: '3px',
            marginRight: 10,
            paddingTop: isMobile ? '5%' : 5,
            paddingBottom: isMobile ? '5%' : 5,
            paddingLeft: '3px',
            paddingRight: '3px',
            textAlign: 'center',
            fontSize: '0.8rem',
            backgroundColor: countDowBackgroundColor,
            width: '180px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          >
            <div>{countDownTitle}</div>
            <div>
              {days}
              {' '}
              D
              {' '}
              {hours}
              {' '}
              H
              {' '}
              {minutes}
              {' '}
              M
              {' '}
              {seconds}
              {' '}
              S
            </div>
          </div>
        )}
      {buttonText && (
        <div style={{ marginRight: 10 }}>
          <MensisButton label={buttonText} color={buttonColor} onClick={handleButtonClick} />
        </div>
      )}
    </div>
  );
}

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,

  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonURL: PropTypes.string,

  showCountDown: PropTypes.bool,
  countDownTitle: PropTypes.string,
  countDownEndDate: PropTypes.string,
  countDowBackgroundColor: PropTypes.string,

  bannerPosition: PropTypes.oneOf(['top', 'button']),
  customMargin: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

Banner.defaultProps = {
  title: '',
  subtitle: '',

  buttonText: null,
  buttonColor: 'var(--info)',
  buttonURL: 'http://mensismentor.com',

  showCountDown: false,
  countDownTitle: null,
  countDownEndDate: null,
  countDowBackgroundColor: 'black',

  bannerPosition: 'top',
  customMargin: null,
  backgroundColor: '#444444',
  textColor: 'white',
};

export default Banner;
