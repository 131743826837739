import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;

export const bannerMessageApi = createApi({
  reducerPath: 'bannerMessageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['bannerMessage'],
  endpoints: (build) => ({
    getBannerMessage: build.query({
      query: () => ({ url: 'v1/banner' }),
    }),
  }),
});

export const { useGetBannerMessageQuery } = bannerMessageApi;
