import { postRequestOptions, getRequestOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;
const serviceRoute = '/payment';

export const createPayment = ({ pricePlanId, clientId, mentorName }) => fetch(
  `${SERVER_URL}${serviceRoute}/v1/createPayment`,
  { ...postRequestOptions, body: JSON.stringify({ pricePlanId, clientId, mentorName }) },
);

// Source: https://markmichon.com/automatic-retries-with-fetch
export const getConfig = (retries) => fetch(
  `${SERVER_URL}${serviceRoute}/v1/config`,
  { ...getRequestOptions },
).then((res) => {
  if (res.ok) {
    return res.json();
  }
  if (retries > 0) {
    return getConfig(retries - 1);
  }
  throw new Error(res.status);
}).catch(() => {
  if (retries > 0) {
    return getConfig(retries - 1);
  }
  throw new Error(500);
});
