import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box } from '@mui/material';
import Signup from '../forms/Signup';
import Login from '../forms/Login';
import useDetectMobile from '../../hooks/useDetectMobile';
import ga from '../../AnalyticsEventTracker';

function AuthField({ onSuccess, showLoginFirst }) {
  const [login, setLogin] = useState(showLoginFirst);

  const { windowWidth } = useDetectMobile();

  return (
    <Box
      sx={{
        my: 5,
        mx: windowWidth < 750 ? 0 : 20,
        marginRight: (() => {
          if (windowWidth < 900) return '10px';
          if (login && windowWidth >= 200) return '20%';
          return '10%';
        }),
        marginLeft: (() => {
          if (windowWidth < 900) return '10px';
          if (login && windowWidth >= 200) return '20%';
          return '10%';
        }),
      }}
    >
      {login ? (
        <Login
          changeAuth={() => {
            ga('Login', 'Click', 'Signup');
            setLogin(false);
          }}
          onLogin={onSuccess}
        />
      ) : (
        <Signup
          changeAuth={() => {
            ga('Signup', 'Click', 'Login');
            setLogin(true);
          }}
          onSignup={onSuccess}
        />
      )}
    </Box>
  );
}

AuthField.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  showLoginFirst: PropTypes.bool,
};

AuthField.defaultProps = {
  showLoginFirst: true,
};

export default AuthField;
