import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, TextField, styled, InputAdornment, IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import theme from '../../styles/theme';
import ga from '../../AnalyticsEventTracker';

const CHARACTER_LIMIT_MULTILINE = 200;
const CHARACTER_LIMIT_SINGLE = 50;

const StyledInput = styled(TextField)({
  fontFamily: 'Montserrat',
  '&.MuiTextField-root .MuiFormHelperText-root': {
    fontFamily: 'Montserrat',
  },
  fieldset: {
    legend: {
      width: 'unset',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    fontFamily: 'Montserrat',
  },
});

function TextFormField({
  label, id, value, multiline, rowsNumber, placeholder, onChange, type, isPasswordType, ...props
}) {
  const [text, setText] = useState(value);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = (event) => {
    ga('General', 'Type', `TextField_${id}`);
    onChange(event.target.value);
    setText(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <StyledInput
        value={text}
        id={id}
        data-testid={id}
        placeholder={placeholder}
        label={label}
        type={showPassword && isPasswordType ? 'text' : type}
        onChange={handleChange}
        multiline={multiline}
        rows={rowsNumber}
        InputProps={{
          maxLength: multiline ? CHARACTER_LIMIT_MULTILINE : CHARACTER_LIMIT_SINGLE,
          endAdornment: isPasswordType ? (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        helperText={multiline ? `${text.length}/${CHARACTER_LIMIT_MULTILINE}` : null}
        {...props}
      />
    </FormControl>
  );
}

TextFormField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  rowsNumber: PropTypes.number,
  placeholder: PropTypes.string,
  isPasswordType: PropTypes.bool,
};

TextFormField.defaultProps = {
  id: `textfield_${Math.random().toString(5)}`,
  label: null,
  value: '',
  type: 'text',
  multiline: false,
  rowsNumber: 1,
  placeholder: 'Escriba aquí',
  isPasswordType: false,
};

export default TextFormField;
