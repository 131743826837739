import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import SwitchFormField from '../formFields/SwitchFormField';

function Switch({
  name, control, label, rules, other, options, ...props
}) {
  const [t] = useTranslation('common');
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <SwitchFormField
          label={t(label)}
          id={name}
          value={value && value.id}
          other={other}
          options={options}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  other: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

Switch.defaultProps = {
  label: null,
  rules: null,
  other: false,
};

export default Switch;
