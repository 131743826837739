import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#334da2',
  },
  '.MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
    color: '#273b7c',
  },
}));

function ControlledAccordion({
  summary, details, onChanging, expanded,
}) {
  const changing = (e, status) => {
    onChanging(e, status);
  };

  return (
    <StyledAccordion elevation={0} onChange={changing} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {summary}
      </AccordionSummary>
      <StyledAccordionDetails>
        {details}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

ControlledAccordion.propTypes = {
  summary: PropTypes.string,
  details: PropTypes.string,
  expanded: PropTypes.bool || null,
  onChanging: PropTypes.func.isRequired,
};

ControlledAccordion.defaultProps = {
  summary: '',
  details: '',
  expanded: null,
};

export default ControlledAccordion;
