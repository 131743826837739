import { getRequestOptions, postRequestOptions, setOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;
const SERVER_VERSION = process.env.REACT_APP_MENSIS_API_VERSION;

export const fetchBestMentors = (body) => fetch(`${SERVER_URL}${SERVER_VERSION}/bestMentors`, { ...postRequestOptions, body: JSON.stringify(body) });

export const fetchMentorProfile = (mentorId, lenguaje) => fetch(
  `${SERVER_URL}/v1/mentorProfile/${mentorId}?preferredLanguageId=${lenguaje}`,
  { ...getRequestOptions },
);

export const fetchRecommendedMentorsByClient = () => fetch(
  `${SERVER_URL}/v1/recommendedMentorsByClient`,
  { ...setOptions('GET') },
);

export const fetchMentorSkills = (mentorId) => fetch(
  `${SERVER_URL}/v1/mentorSkills/${mentorId}`,
  { ...getRequestOptions },
);
