import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const WEB_URL = process.env.REACT_APP_MENSIS_WEB_URL;
function Footer({ showImportantLinks }) {
  const [t] = useTranslation('common');
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6">
              <div className="footer-info">
                <a href="/" className="brand-name logo">
                  <img alt="Logo" src="./logo-nav.svg" width="180px" />
                </a>
              </div>
            </div>

            {showImportantLinks && (
              <div className="col-lg-2 col-md-6 footer-links">
                <h4 style={{ fontFamily: 'Montserrat' }}>{t('Footer.link')}</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />
                    {' '}
                    <a href="/#onboarding" style={{ fontFamily: 'Montserrat' }}>{t('Footer.inicio')}</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />
                    {' '}
                    <a href="/#services" style={{ fontFamily: 'Montserrat' }}>{t('Footer.acerca')}</a>
                  </li>
                </ul>
              </div>
            )}

            <div className="col-lg-3 col-md-6 footer-links">
              <h4 style={{ fontFamily: 'Montserrat' }}>{t('Footer.terminos')}</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" />
                  {' '}
                  <a href={`${WEB_URL}/terms`} style={{ fontFamily: 'Montserrat' }}>{t('Footer.terminos')}</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />
                  {' '}
                  <a href={`${WEB_URL}/privacy`} style={{ fontFamily: 'Montserrat' }}>{t('Footer.politica')}</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4 style={{ fontFamily: 'Montserrat' }}>{t('Footer.encuentranos')}</h4>
              <div className="social-links mt-3">
                <a href="https://www.facebook.com/Mensis-113581956965750" className="facebook" aria-label="facebook link"><i className="bx bxl-facebook" /></a>
                <a href="https://www.instagram.com/mensis_mentor/?hl=en" className="instagram" aria-label="instagram link"><i className="bx bxl-instagram" /></a>
                <a href="https://www.linkedin.com/company/mensis2020/mycompany/?viewAsMember=true" className="linkedin" aria-label="linkedin link"><i className="bx bxl-linkedin" /></a>
                <a href="https://www.tiktok.com/@mensis_mentor" className="tiktok" aria-label="tiktok link"><i className="bx bxl-tiktok" /></a>
                <a href="https://www.youtube.com/channel/UC9UB9UDkumz1Hr7HCDBKYKw" className="youtube" aria-label="youtube link"><i className="bx bxl-youtube" /></a>
                <a href="https://api.whatsapp.com/send/?phone=50687566313&text=¡Hola%21&type=phone_number&app_absent=0" className="whatsapp" aria-label="whatsapp link"><i className="bx bxl-whatsapp" /></a>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright
          {' '}
          <strong>
            <span>
              Mensis Mentor
              {' '}
              {new Date().getFullYear()}
            </span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  showImportantLinks: PropTypes.bool,
};

Footer.defaultProps = {
  showImportantLinks: true,
};

export default Footer;
