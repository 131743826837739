import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Button, Stack,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import ButtonGroup from '../controllerFormFields/ButtonGroup';
import Switch from '../controllerFormFields/Switch';
import Select from '../controllerFormFields/Select';
import TextField from '../controllerFormFields/TextField';
import Autocomplete from '../controllerFormFields/Autocomplete';
import MentorSelection from '../controllerFormFields/MentorSelection';
import ga from '../../AnalyticsEventTracker';
import PricingPlanSelection from '../controllerFormFields/PricingPlanSelectionControlled';
import TextFieldWithId from '../controllerFormFields/TextFieldWithId';

function RenderForm({
  questions, onChange, onReturn, isStep, defaultValuesForm, id,
  formData, showMentorPagination, ...props
}) {
  const {
    control, handleSubmit, reset,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: useMemo(() => defaultValuesForm, [defaultValuesForm]),
  });

  useEffect(() => {
    reset(defaultValuesForm);
  }, [defaultValuesForm, reset]);

  const onSubmit = (data) => {
    ga('OnBoarding', 'Click', `Siguiente_${id}`);
    if (isValid) { onChange(data); }
  };

  const onReturnClick = () => {
    onReturn();
  };
  const [t] = useTranslation('common');
  return (
    <Stack
      as="form"
      direction="column"
      onSubmit={handleSubmit(onSubmit)}
      ql="qa-ctnr-resetpassword"
      {...props}
    >
      {questions && questions
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map((question) => {
          switch (question.type) {
            case 'buttonGroup':
              return (
                <ButtonGroup
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  other={question.other}
                  otherIcon={question.otherIcon}
                  otherPlaceholderTextField={t(question.otherPlaceholderTextField)}
                  options={question.options}
                />
              );
            case 'radioSwitch':
              return (
                <Switch
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  other={question.other}
                  options={question.options}
                />
              );
            case 'selectMultiple':
              return (
                <Select
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  other={question.other}
                  otherPlaceholderTextField={question.otherPlaceholderTextField}
                  otherSelectedByDefault={question.otherSelectedByDefault}
                  options={question.options}
                />
              );
            case 'selectSimple':
              return (
                <Select
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  other={question.other}
                  otherPlaceholderTextField={question.otherPlaceholderTextField}
                  otherSelectedByDefault={question.otherSelectedByDefault}
                  options={question.options}
                  isSelectMultiple={false}
                />
              );
            case 'autocomplete':
              return (
                <Autocomplete
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  other={question.other}
                  options={question.options}
                  placeholder={t(question.placeholder)}
                />
              );
            case 'textMultiline':
              return (
                <TextFieldWithId
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  rowsNumber={3}
                  placeholder={question.placeholder}
                  multiline
                />
              );
            case 'textSingleLine':
              return (
                <TextFieldWithId
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                  rowsNumber={1}
                  placeholder={question.placeholder}
                />
              );
            case 'mentors':
              return (
                <MentorSelection
                  key={question.id}
                  name={question.id}
                  control={control}
                  options={question.options}
                  rules={{ required: question.required }}
                  onComplete={onSubmit}
                  formData={formData}
                  showPagination={showMentorPagination}
                />
              );
            case 'pricing':
              return (
                <PricingPlanSelection
                  label={t(question.label)}
                  key={question.id}
                  name={question.id}
                  control={control}
                  options={question.options}
                  rules={{ required: question.required }}
                  onComplete={onSubmit}
                />
              );
            default:
              return (
                <TextField
                  name={question.id}
                  key={question.id}
                  control={control}
                  label={t(question.label)}
                  rules={{ required: question.required }}
                />
              );
          }
        })}
      <div style={{ alignSelf: 'flex-end', marginTop: '40px' }}>
        {onReturn && (
        <Button
          variant="contained"
          onClick={() => {
            ga('OnBoarding', 'Click', `Anterior_${id}`);
            onReturnClick();
          }}
          sx={{
            borderRadius: '20px',
            margin: '5px',
            fontFamily: 'Montserrat',
            textTransform: 'none',
            color: '#273B7C',
            backgroundColor: '#fff',
            '&:hover': { backgroundColor: 'rgba(39, 59, 124, 0.04)' },
          }}
          data-testid="back"
        >
          {t('ClientOnboarding.onboardingSteps.Atras')}
        </Button>
        )}
        {
        (questions && questions.findIndex((item) => item.type === 'calendar') < 0
        && questions.findIndex((item) => item.type === 'mentors') < 0
        && questions.findIndex((item) => item.type === 'pricing') < 0)
        && (
        <Button
          variant="contained"
          type="submit"
          sx={{
            borderRadius: '20px',
            margin: '5px',
            fontFamily: 'Montserrat',
            textTransform: 'none',
          }}
          data-testid="next"
        >
          { isStep ? t('ClientOnboarding.onboardingSteps.Sig') : 'Enviar'}
        </Button>
        )
}
      </div>
    </Stack>
  );
}

RenderForm.propTypes = {
  id: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    otro: PropTypes.bool,
    require: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string || PropTypes.element,
    })),
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onReturn: PropTypes.func,
  isStep: PropTypes.bool,
  defaultValuesForm: PropTypes.any,
  formData: PropTypes.any,
  showMentorPagination: PropTypes.bool,
};

RenderForm.defaultProps = {
  onReturn: null,
  isStep: false,
  formData: {},
  defaultValuesForm: {},
  showMentorPagination: true,
};

export default RenderForm;
