import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Grid, Rating, Stack, styled, Typography,
} from '@mui/material';
import {
  WorkspacePremium,
  WorkspacePremiumOutlined as WorkspacePremiumOutlinedIcon,
} from '@mui/icons-material';
import theme from '../../../styles/theme';
import { addThousandSeparator } from '../../../common/Util';
import ProfilePhotoDialog from '../../dialog/ProfilePhotoDialog';
import TruncateText from '../../text/TruncateText';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  marginTop: '5px',
});

function OnboardingMentorCard({
  mentorId,
  profilePhotoUrl,
  mentorName,
  mentorLastName,
  userName,
  currentPosition,
  profileDescription,
  mentorStars,
  servicesNumber,
  avatarSize,
  ...props
}) {
  const [showFullProfilePhoto, setShowFullProfilePhoto] = useState(false);
  const [t] = useTranslation('common');
  return (
    <Box
      sx={{
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#F7F7F7',
      }}
      {...props}
    >
      <Box sx={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        padding: '10px',
      }}
      >
        <Avatar
          onClick={() => setShowFullProfilePhoto(true)}
          src={profilePhotoUrl}
          sx={{
            width: avatarSize, height: avatarSize, marginTop: '15px', cursor: 'pointer',
          }}
        />
        {profilePhotoUrl && (
          <ProfilePhotoDialog
            showModal={showFullProfilePhoto}
            imgURL={profilePhotoUrl}
            onClose={() => setShowFullProfilePhoto(false)}
          />
        )}
        <StyledTypography sx={{ mt: 1, textAlign: 'center', color: theme.palette.primary.main }}>
          <WorkspacePremium />
          <b>{mentorName}</b>
          {' '}
          <b>{mentorLastName}</b>
        </StyledTypography>
        <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>{currentPosition}</Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: 'right', paddingRight: '15px' }}>
            {mentorStars && (
            <Rating readOnly defaultValue={mentorStars} precision={0.5} />
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ borderLeft: '2px solid #E0E0E0', textAlign: 'left' }}>
            {servicesNumber > -1 && (
            <Typography>
              <WorkspacePremiumOutlinedIcon sx={{ color: 'rgba(199, 68, 13, 1)' }} />
              {` ${addThousandSeparator(servicesNumber)} ${t('SimpleMentorCard.serviciosCertificados')}`}
            </Typography>
            )}
          </Grid>
        </Grid>

        {profileDescription && profileDescription.trim() && (
          <Stack
            direction="column"
            spacing={1}
            sx={{
              my: 5, width: '100%', px: 8, paddingRight: '32px', paddingLeft: '32px',
            }}
          >
            <StyledTypography sx={{ textAlign: 'left', color: theme.palette.primary.main }}>
              {t('MentorCard.AcercaDeMi')}
            </StyledTypography>
            <TruncateText text={profileDescription} numberLines={8} />
          </Stack>
        )}
      </Box>
    </Box>
  );
}

OnboardingMentorCard.propTypes = {
  mentorId: PropTypes.number.isRequired,
  profilePhotoUrl: PropTypes.string,
  mentorName: PropTypes.string.isRequired,
  mentorLastName: PropTypes.string,
  userName: PropTypes.string,
  currentPosition: PropTypes.string.isRequired,
  profileDescription: PropTypes.string.isRequired,
  mentorStars: PropTypes.number,
  servicesNumber: PropTypes.number,
  avatarSize: PropTypes.string,
};

OnboardingMentorCard.defaultProps = {
  profilePhotoUrl: null,
  mentorLastName: null,
  userName: null,
  mentorStars: 0,
  servicesNumber: 0,
  avatarSize: '150px',
};

export default OnboardingMentorCard;
