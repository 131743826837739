import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import AutocompleteField from '../formFields/AutocompleteField';

function Autocomplete({
  name, control, label, rules, other, options, ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <AutocompleteField
          label={label}
          id={name}
          value={value}
          other={other}
          options={options}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
}

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  other: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

Autocomplete.defaultProps = {
  label: null,
  rules: null,
  other: false,
};

export default Autocomplete;
