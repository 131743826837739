import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, TextField, styled, Typography,
} from '@mui/material';
import theme from '../../styles/theme';
import ga from '../../AnalyticsEventTracker';

const CHARACTER_LIMIT_MULTILINE = 200;
const CHARACTER_LIMIT_SINGLE = 80;

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: '1.5rem',
});

const StyledInput = styled(TextField)({
  fontFamily: 'Montserrat',
  '&.MuiTextField-root .MuiFormHelperText-root': {
    fontFamily: 'Montserrat',
  },
  width: '80%',
  marginLeft: '10%',
  fieldset: {
    legend: {
      width: 'unset',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    fontFamily: 'Montserrat',
  },
});

function TextFormFieldWithId({
  label, id, value, multiline, rowsNumber, placeholder, onChange, ...props
}) {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleChange = (event) => {
    ga('OnBoarding', 'Type', `TextField_${id}`);
    onChange({
      id,
      label: event.target.value,
    });
    setText(event.target.value);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      {label && <StyledTypography sx={{ my: 5, marginBottom: '10px' }}>{label}</StyledTypography>}
      <StyledInput
        value={text}
        id={id}
        data-testid={id}
        placeholder={placeholder}
        onChange={handleChange}
        multiline={multiline}
        rows={rowsNumber}
        inputProps={{ maxLength: multiline ? CHARACTER_LIMIT_MULTILINE : CHARACTER_LIMIT_SINGLE }}
        helperText={multiline ? `${text.length}/${CHARACTER_LIMIT_MULTILINE}` : null}
        {...props}
      />
    </FormControl>
  );
}

TextFormFieldWithId.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  rowsNumber: PropTypes.number,
  placeholder: PropTypes.string,
};

TextFormFieldWithId.defaultProps = {
  id: `textfield_${Math.random().toString(5)}`,
  label: null,
  value: '',
  multiline: false,
  rowsNumber: 1,
  placeholder: 'Escriba aquí',
};

export default TextFormFieldWithId;
