import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, styled, Typography } from '@mui/material';
import ComplexButton from '../button/ComplexButton';
import TextFormFieldWithId from './TextFormFieldWithId';
import ga from '../../AnalyticsEventTracker';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: '1.5rem',
});

function ComplexButtonField({
  id, label, options, value, other, otherIcon, otherPlaceholderTextField,
  onChange, gridSpacing, withRadioButton, ...props
}) {
  const [selectedId, setSelectedId] = useState(value);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherText, setOtherText] = useState('');

  useEffect(() => {
    if (selectedId === 'other') {
      onChange({
        id: 'other',
        label: otherText,
      });
    }
  }, [selectedId, otherText, onChange]);

  useEffect(() => {
    setSelectedId(value);
    if (value === 'other') {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
    }
  }, [value]);

  const handleButtonClick = (buttonId) => {
    setSelectedId(buttonId);
    if (buttonId === 'other') {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
    }
    const temporalIdx = options.findIndex((x) => x.id === buttonId);
    if (temporalIdx > -1) {
      ga('OnBoarding', 'Click', `ComplexButton_${options[temporalIdx].id}`);
      onChange(options[temporalIdx]);
    } else {
      ga('OnBoarding', 'Click', 'ComplexButton_otro');
    }
  };
  const [t] = useTranslation('common');
  return (
    <>
      {label && <StyledTypography sx={{ my: 5, marginBottom: '10px' }}>{label}</StyledTypography>}
      <Grid
        key={id}
        container
        spacing={gridSpacing}
        alignItems="stretch"
        justifyContent="center"
      >
        {options
      && options.map(
        (item) => (
          <Grid
            key={item.id}
            item
            md={4}
            sm={6}
            xs={12}
          >
            <ComplexButton
              label={t(item.label)}
              icon={item.icon}
              iconSize="2rem"
              withRadioButton={withRadioButton}
              selected={selectedId === item.id}
              onClick={() => handleButtonClick(item.id)}
              {...props}
            />
          </Grid>
        ),
      )}
        {other && (
        <Grid item md={4} sm={6} xs={12}>
          <ComplexButton
            key="other"
            label={t('ClientOnboarding.motivacion.otro')}
            withRadioButton={withRadioButton}
            icon={otherIcon}
            selected={selectedId === 'other'}
            onClick={() => handleButtonClick('other')}
            {...props}
          />
        </Grid>
        )}
        { otherSelected && (
        <Grid item md={12} sm={12} xs={12}>
          <TextFormFieldWithId
            id={`${id}_other`}
            value={otherText}
            onChange={(text) => setOtherText(text.label)}
            placeholder={otherPlaceholderTextField}
            sx={{ mt: 2 }}
          />
        </Grid>
        )}
      </Grid>
    </>
  );
}

ComplexButtonField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string || PropTypes.element,
  })).isRequired,
  gridSpacing: PropTypes.number,
  withRadioButton: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  other: PropTypes.bool,
  otherIcon: PropTypes.string,
  otherPlaceholderTextField: PropTypes.string,
  value: PropTypes.string,
};

ComplexButtonField.defaultProps = {
  id: `complex_${Math.random().toString(5)}`,
  label: null,
  withRadioButton: true,
  gridSpacing: 2,
  other: false,
  otherIcon: null,
  otherPlaceholderTextField: 'Escriba aquí',
  value: null,
};

export default ComplexButtonField;
