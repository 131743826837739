import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Box, LinearProgress } from '@mui/material';
import ga from '../../AnalyticsEventTracker';
import { useGetCurrentUserQuery } from '../../store/slices/clientApi';
import useDetectMobile from '../../hooks/useDetectMobile';

function CalendlyField({
  calendlyURL, onFinish, ...props
}) {
  const [isLoading, setIsLoading] = useState(true);
  const { data: userData } = useGetCurrentUserQuery();
  const { isMobile } = useDetectMobile();

  useCalendlyEventListener({
    // onProfilePageViewed: (b) => window.console.log('[b]', b),
    onDateAndTimeSelected: (t) => {
      window.console.info('[T]', t);
      ga('OnBoarding', 'Event', 'CalendlyTimeSelected');
    },
    onEventTypeViewed: (a) => {
      window.console.log('[a]', a);
      ga('OnBoarding', 'Event', 'CalendlyTimeDisplayed');
      setIsLoading(false);
    },
    onEventScheduled: (e) => {
      const eventURL = e.data.payload.event.uri;
      const inviteeURL = e.data.payload.invitee.uri;
      if (eventURL && inviteeURL) {
        const event = eventURL.split('/')[eventURL.split('/').length - 1];
        const invitee = inviteeURL.split('/')[inviteeURL.split('/').length - 1];
        if (event && invitee) {
          const calendlyLinks = {
            calendly: {
              calendlyEventId: event,
              calendlyInviteeId: invitee,
            },
          };
          ga('OnBoarding', 'Event', 'CalendlyEventScheduled');
          onFinish(calendlyLinks);
        } else {
          ga('OnBoarding', 'Event', 'CalendlyErrorEventScheduled');
          window.console.log(`Could not get the ids from the Calendly payload: ${e.data.payload}`);
        }
      } else {
        ga('OnBoarding', 'Event', 'CalendlyErrorEventScheduled');
        window.console.log(`Could not get the urls from the Calendly payload: ${e.data.payload}`);
      }
    },
  });

  return (
    <div {...props}>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          {' '}
          <LinearProgress />
          {' '}
        </Box>
      )}
      {calendlyURL && isMobile && (
      <InlineWidget
        styles={{ height: '800px' }}
        pageSettings={{ hideLandingPageDetails: false, hideGdprBanner: true }}
        url={calendlyURL}
        prefill={{ name: userData?.client?.name ? `${userData?.client?.name} ${userData?.client?.lastName}` : '', email: userData?.client?.email ? userData?.client?.email : '' }}
      />
      )}
      {calendlyURL && !isMobile && (
        <InlineWidget
          pageSettings={{ hideLandingPageDetails: false, hideGdprBanner: true }}
          url={calendlyURL}
          prefill={{ name: userData?.client?.name ? `${userData?.client?.name} ${userData?.client?.lastName}` : '', email: userData?.client?.email ? userData?.client?.email : '' }}
        />
      )}
    </div>
  );
}

CalendlyField.propTypes = {
  calendlyURL: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
};

CalendlyField.defaultProps = {
  calendlyURL: null,
};

export default CalendlyField;
