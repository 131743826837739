import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import ga from '../../AnalyticsEventTracker';
import Payment from '../forms/payment/Payment';
import CalendarStep from '../formFields/CalendarField';
import { getConfig } from '../../services/paymentService';
import { registerOnboardingV1 } from '../../services/onboardingService';
import {
  questionsAndResponsesSelector,
  responsesIdSelector,
  selectedPricePackageSelector,
  selectedMentorSelector,
} from '../../store/slices/clientOnboardingSlice';
import { setMessage, unsetMessage } from '../../store/slices/loadingDialogMessageSlice';
import { changeAnswers } from '../../store/slices/onboardingAnswersSlice';
import { useLazyGetDemonstrationsByClientQuery } from '../../store/slices/mentoringHistoryByClientApi';
import registerMentoringV1 from '../../services/mentoringService';

function ConfirmMentorshipStep({
  onReturn, isServerError,
}) {
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.const { i18n } = useTranslation('common');
  const [calendlyURL, setcalendlyURL] = useState();
  const [calendlyURLAfterPayment, setcalendlyURLAfterPayment] = useState();
  const [stripePromise, setStripePromise] = useState(null);
  const [showPayment, setShowPayment] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionsAndResponses = useSelector(questionsAndResponsesSelector);
  const responsesId = useSelector(responsesIdSelector);
  const selectedPricePackage = useSelector(selectedPricePackageSelector);
  const selectedMentor = useSelector(selectedMentorSelector);
  const [triggerDemonstrationsByClient] = useLazyGetDemonstrationsByClientQuery();
  const [t] = useTranslation('common');

  useEffect(() => {
    getConfig(5)
      .then((data) => setStripePromise(loadStripe(data.publishableKey, { locale: t('Idioma') })))
      .catch(() => {
        ga('OnBoarding', 'Event', 'Payment_config_error');
      });
  }, [t]);

  useEffect(() => {
    setShowPayment(selectedPricePackage.pricePlanAmount > 0);
  }, [selectedPricePackage.pricePlanAmount]);

  useEffect(() => {
    if (t('Idioma') === 'es') {
      setcalendlyURLAfterPayment(process.env.REACT_APP_CALENDLY_URL_AFTER_PAYMENT_ES);
      setcalendlyURL(process.env.REACT_APP_CALENDLY_URL_ES);
    } else {
      setcalendlyURLAfterPayment(process.env.REACT_APP_CALENDLY_URL_AFTER_PAYMENT_EN);
      setcalendlyURL(process.env.REACT_APP_CALENDLY_URL_EN);
    }
  }, [t]);

  const isCommunicatingStripe = (loading) => {
    if (loading) {
      dispatch(setMessage(t('Payment.Paying')));
    } else {
      dispatch(unsetMessage());
    }
  };

  const finalRedirect = () => {
    navigate('/', { state: { showSuccessOnboardingModal: true } });
  };

  const onSubmitDemostration = (calendlyData) => {
    if (
      questionsAndResponses
        && questionsAndResponses.desiredEmploymentArea
        && questionsAndResponses.desiredEmploymentArea.responses[0]
        && questionsAndResponses.desiredEmploymentArea.responses[0].text
          .toLowerCase()
          .includes('empleos en')
    ) {
      const newArea = questionsAndResponses.desiredEmploymentArea.responses[0].text
        .toLowerCase()
        .replace('empleos en', '')
        .trim();
      questionsAndResponses.desiredEmploymentArea.responses[0].text = newArea[0]
        .toUpperCase() + newArea.slice(1);
    }
    const defaultQyA = {
      desiredEmploymentArea: {
        id: 1, dependenciesIds: [0], question: { text: '¿En qué área desea encontrar empleo?', keyword: 'desiredEmploymentArea' }, responses: [{ text: 'Mentores en otras áreas', keyword: 'other' }],
      },
    };
    const body = {
      localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      calendlyEventId: calendlyData.calendly.calendlyEventId,
      calendlyInviteeId: calendlyData.calendly.calendlyInviteeId,
      mentor: selectedMentor,
      packagePrice: selectedPricePackage,
      questionsAndResponses: questionsAndResponses || defaultQyA,
    };

    dispatch(setMessage(t('Signup.Registrando')));
    isServerError(false);
    registerOnboardingV1(body)
      .then((response) => response.json())
      .then((dataResponse) => {
        dispatch(unsetMessage());
        if (dataResponse.status === 200) {
          dispatch(
            changeAnswers({ response: dataResponse.data }),
          );
          triggerDemonstrationsByClient();
          finalRedirect();
          ga('OnBoarding', 'Event', 'DemonstrationRegisteredSuccessful');
        } else {
          ga('OnBoarding', 'Event', 'DemonstrationRegisteredFailed');
          isServerError(true);
        }
      })
      .catch(() => {
        ga('OnBoarding', 'Event', 'DemonstrationRegisteredFailed');
        dispatch(unsetMessage());
        isServerError(true);
      });
  };

  const onSubmitMentorship = (paymentInfo) => {
    const body = {
      localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      mentor: selectedMentor,
      packagePrice: selectedPricePackage,
      currentURL: window.location.href,
      ...paymentInfo,
      responsesId,
    };
    dispatch(setMessage(t('Signup.Registrando')));
    isServerError(false);
    registerMentoringV1(body)
      .then((response) => response.json())
      .then((dataResponse) => {
        dispatch(unsetMessage());
        if (dataResponse.status === 200) {
          ga(
            'OnBoarding',
            'Event',
            'DemonstrationWithPaymentRegisteredSuccessful',
          );
          dispatch(
            changeAnswers({ ...questionsAndResponses, response: dataResponse.data }),
          );
          triggerDemonstrationsByClient();
          setShowPayment(false);
        } else {
          ga('OnBoarding', 'Event', 'DemonstrationRegisteredFailed');
          isServerError(true);
        }
      })
      .catch(() => {
        ga('OnBoarding', 'Event', 'DemonstrationRegisteredFailed');
        dispatch(unsetMessage());
        isServerError(true);
      });
  };
  return (

    showPayment && stripePromise
      ? (
        <Payment
          onChange={onSubmitMentorship}
          onReturn={onReturn}
          onLoading={isCommunicatingStripe}
          stripePromise={stripePromise}
        />
      ) : (
        <CalendarStep
          calendlyURL={selectedPricePackage.pricePlanAmount > 0
            ? calendlyURLAfterPayment : calendlyURL}
          onChange={selectedPricePackage.pricePlanAmount > 0 ? finalRedirect : onSubmitDemostration}
          onReturn={onReturn}
          showBackButton={selectedPricePackage.pricePlanAmount === 0}
        />
      )
  );
}

ConfirmMentorshipStep.propTypes = {
  isServerError: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
};

ConfirmMentorshipStep.defaultProps = {};

export default ConfirmMentorshipStep;
