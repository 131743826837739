import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

function Bye() {
  const { logOut } = useAuth();

  useEffect(() => {
    logOut({ showModalLogAgain: true, redirectTo: '/login' });
  }, [logOut]);

  return <div />;
}

export default Bye;
