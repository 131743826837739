import React, { useState } from 'react';
import {
  Box,
  Button, LinearProgress, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography, Alert,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Select from '../controllerFormFields/Select';
import { employmentAreasSelect, onboardingSteps } from '../../data/QuestionsData';

const SERVER_URL = process.env.REACT_APP_MENSIS_GALIGEO_URL;

function RecommenderTrain() {
  const [recommendedMentors, setRecommendedMentors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isThereServerError, setIsThereServerError] = useState(false);

  const {
    control, handleSubmit, reset,
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      employmentAreasSelect: '',
      englishLevel: '',
      experienceYears: '',
      jobSeekingCountries: '',
    },
  });

  const onSubmit = (data) => {
    const features = {};

    if (data.employmentAreasSelect) {
      features.employmentArea = data.employmentAreasSelect.id;
    }

    if (data.experienceYears) {
      features.experienceYears = data.experienceYears.id;
    }

    if (data.englishLevel) {
      features.englishLevel = data.englishLevel.id;
    }

    if (data.jobSeekingCountries) {
      features.country = data.jobSeekingCountries.id;
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: 10000,
      body: JSON.stringify({ features }),
    };

    setIsLoading(true);
    setIsThereServerError(false);
    fetch(`${SERVER_URL}/recommend`, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        setIsLoading(false);
        if (dataResponse.status === 200 && dataResponse.prediction) {
          setRecommendedMentors(dataResponse.prediction);
        } else {
          setIsThereServerError(true);
        }
      }).catch(() => {
        setIsLoading(false);
        setIsThereServerError(true);
      });

    // Move the scroll down
    window.scrollTo(0, 100000);
  };

  const onClean = () => {
    reset();
    // Move the scroll to the top
    window.scrollTo(0, 0);
    setRecommendedMentors(null);
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Grid container>
        <Grid item md={1} sm={12} xs={12} />
        <Grid item md={10} sm={12} xs={12}>
          {isLoading && (<LinearProgress />)}
          {isThereServerError && (
            <Alert sx={{ fontFamily: 'Montserrat' }} onClose={() => setIsThereServerError(false)} severity="error">Hay un error en el servidor. Por favor, inténtalo más tarde.</Alert>
          )}
          <Paper elevation={3} sx={{ mx: 5, my: 2, p: 2 }} style={{ margin: 0 }}>
            <Box sx={{ width: '100%' }}>
              <Stack
                as="form"
                direction="column"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid
                  container
                  rowSpacing={1}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} sd={12}>
                    <Typography
                      variant="h1"
                      sx={{
                        textAlign: 'center', fontSize: '2rem', color: '#273B7C', marginBottom: '15px', fontFamily: 'sans-serif',
                      }}
                    >
                      Encuentre al mejor mentor
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      name={employmentAreasSelect.id}
                      key={employmentAreasSelect.id}
                      control={control}
                      label="¿En qué área desea encontrar empleo?"
                      options={employmentAreasSelect.options}
                      isSelectMultiple={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      name={onboardingSteps.questions[4].id}
                      key={onboardingSteps.questions[4].id}
                      control={control}
                      label={onboardingSteps.questions[4].label}
                      options={onboardingSteps.questions[4].options}
                      isSelectMultiple={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      name={onboardingSteps.questions[5].id}
                      key={onboardingSteps.questions[5].id}
                      control={control}
                      label={onboardingSteps.questions[5].label}
                      options={onboardingSteps.questions[5].options}
                      isSelectMultiple={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      name={onboardingSteps.questions[3].id}
                      key={onboardingSteps.questions[3].id}
                      control={control}
                      label={onboardingSteps.questions[3].label}
                      options={onboardingSteps.questions[3].options}
                      isSelectMultiple={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div style={{ marginTop: '40px', textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        onClick={() => onClean()}
                        sx={{
                          borderRadius: '20px',
                          margin: '5px',
                          fontFamily: 'Montserrat',
                          textTransform: 'none',
                          color: '#273B7C',
                          backgroundColor: '#fff',
                          '&:hover': { backgroundColor: 'rgba(39, 59, 124, 0.04)' },
                        }}
                        data-testid="clean"
                      >
                        Limpiar
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          borderRadius: '20px',
                          margin: '5px',
                          fontFamily: 'Montserrat',
                          textTransform: 'none',
                        }}
                        data-testid="next"
                      >
                        Recomendar
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Paper>
          {isLoading && (<LinearProgress />)}
        </Grid>
        <Grid item md={1} sm={12} xs={12} />
      </Grid>
      <br />
      {recommendedMentors && (
        <Grid container>
          <Grid item md={1} sm={12} xs={12} />
          <Grid item md={10} sm={12} xs={12}>
            <Paper elevation={3} sx={{ mx: 5, my: 2, p: 2 }} style={{ margin: 0 }}>
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="h1"
                  sx={{
                    textAlign: 'center', fontSize: '2rem', color: '#273B7C', marginBottom: '15px', fontFamily: 'sans-serif',
                  }}
                >
                  Mentores recomendados
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Id mentor</TableCell>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="left">Apellido(s)</TableCell>
                        <TableCell align="left">Afinidad</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recommendedMentors.map((row) => (
                        <TableRow key={row.mentorId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row" sx={{ fontFamily: 'Montserrat' }}>
                            {row.mentorId}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Montserrat' }}>{row.mentorName}</TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Montserrat' }}>{row.lastName}</TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Montserrat' }}>{row.score}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={1} sm={12} xs={12} />
        </Grid>
      )}
    </div>
  );
}

export default RecommenderTrain;
