import {
  EventAvailableOutlined as EventAvailableOutlinedIcon,
  HeadphonesOutlined as HeadphonesOutlinedIcon,
} from '@mui/icons-material';
import BookIcon from '../assets/icons/BookIcon';
import FormIcon from '../assets/icons/FormIcon';

export const countryList = [
  { id: 'USA', label: 'ClientOnboarding.pais.USA' },
  { id: 'CRI', label: 'ClientOnboarding.pais.CRI' },
  { id: 'COL', label: 'ClientOnboarding.pais.COL' },
  { id: 'MEX', label: 'ClientOnboarding.pais.MEX' },
  { id: 'PAN', label: 'ClientOnboarding.pais.PAN' },
  { id: 'NIC', label: 'ClientOnboarding.pais.NIC' },
  { id: 'GTM', label: 'ClientOnboarding.pais.GTM' },
  { id: 'CHL', label: 'ClientOnboarding.pais.CHL' },
];

export const workAreaList = [
  { id: 'software_development', label: 'ClientOnboarding.Area.software_development' },
  { id: 'sales', label: 'ClientOnboarding.Area.sales' },
  { id: 'marketing', label: 'ClientOnboarding.Area.marketing' },
  { id: 'customer_service', label: 'ClientOnboarding.Area.customer_service' },
  { id: 'management', label: 'ClientOnboarding.Area.management' },
  { id: 'operations', label: 'ClientOnboarding.Area.operations' },
  { id: 'human_resources', label: 'ClientOnboarding.Area.human_resources' },
  { id: 'finance', label: 'ClientOnboarding.Area.finance' },
  { id: 'technology', label: 'ClientOnboarding.Area.technology' },
  { id: 'design', label: 'ClientOnboarding.Area.design' },
  { id: 'social_networks', label: 'ClientOnboarding.Area.social_networks' },
  { id: 'call_center', label: 'ClientOnboarding.Area.call_center' },
  { id: 'insurance', label: 'ClientOnboarding.Area.insurance' },
  { id: 'accounting', label: 'ClientOnboarding.Area.accounting' },
  { id: 'legal', label: 'ClientOnboarding.Area.legal' },
  { id: 'product_management', label: 'ClientOnboarding.Area.product_management' },
  { id: 'other', label: 'ClientOnboarding.Area.other' },
];

export const englishLevelList = [
  { id: '1', label: 'ClientOnboarding.nivelIngles.1', icon: 'M17 5v16c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h2V2h4v2h2c.55 0 1 .45 1 1zm-2 1H9v14h6V6z' },
  { id: '2', label: 'ClientOnboarding.nivelIngles.2', icon: 'M17 5v16c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h2V2h4v2h2c.55 0 1 .45 1 1zm-2 1H9v10h6V6z' },
  { id: '3', label: 'ClientOnboarding.nivelIngles.3', icon: 'M17 5v16c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h2V2h4v2h2c.55 0 1 .45 1 1zm-2 1H9v4h6V6z' },
  { id: '4', label: 'ClientOnboarding.nivelIngles.4', icon: 'M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4z' },
];

export const experienceYearsList = [
  { id: '0', label: 'ClientOnboarding.experiencia.0' },
  { id: '1', label: 'ClientOnboarding.experiencia.1' },
  { id: '3', label: 'ClientOnboarding.experiencia.3' },
  { id: '6', label: 'ClientOnboarding.experiencia.6' },
  { id: '10', label: 'ClientOnboarding.experiencia.10' },
  { id: '11', label: 'ClientOnboarding.experiencia.11' },
];

export const employmentAreasButtons = {
  id: 'employmentAreasButtons',
  type: 'customButton',
  options: [
    { id: 'software_development', label: 'Mentores en desarrollo de software' },
    { id: 'sales', label: 'Mentores en ventas' },
    { id: 'marketing', label: 'Mentores en mercadeo' },
    { id: 'customer_service', label: 'Mentores en servicio al cliente' },
    { id: 'management', label: 'Mentores en administración' },
    { id: 'other', label: 'Mentores en otras áreas' },
  ],
};

export const employmentAreasSelect = {
  id: 'employmentAreasSelect',
  type: 'select',
  options: workAreaList,
};

export const softwareDevelopmentJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'analista_TI', label: 'Analista de TI' },
    { id: 'analista_datos', label: 'Analista de datos' },
    { id: 'administrador_base_datos', label: 'Administrador de base de datos(SQL, Oracle, otras)' },
    { id: 'analista_seguidad_información', label: 'Analista de seguidad de información(Ciberseguridad)' },
    { id: 'desarrollador_web', label: 'Desarrollador Web' },
    { id: 'desarrollador_software', label: 'Desarrollador de software' },
    { id: 'desarrollador_sales_force', label: 'Desarrollador de SalesForce' },
    { id: 'desarrollador_front_end', label: 'Desarrollador front-end' },
    { id: 'desarrollador_java', label: 'Desarrollador de java' },
    { id: 'desarrollador_iOS', label: 'Desarrollador de iOS' },
    { id: 'desarrollador_Android', label: 'Desarrollador de Android' },
    { id: 'administrador_CRM', label: 'Administrador de CRM o ERP' },
    { id: 'analista_QA', label: 'Analista QA' },
    { id: 'desarrollador_Full_Stack', label: 'Desarrollador Full Stack' },
    { id: 'scrum_Master', label: 'Scrum Master' },
    { id: 'product_Manager', label: 'Product Manager' },
    { id: 'arquitecto_nube', label: 'Arquitecto en la nube AWS, Google Cloud' },
  ],
};

export const businessSalesJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'analista_comercial', label: 'Analista comercial' },
    { id: 'desarrollador_negocios', label: 'Desarrollador de negocios' },
    { id: 'ejecutivo_cuenta', label: 'Ejecutivo de cuenta' },
    { id: 'gerente_ventas', label: 'Gerente de ventas' },
    { id: 'vendedor', label: 'Vendedor' },
    { id: 'consultor_ventas', label: 'Consultor de ventas' },
    { id: 'ejecutivo_ventas', label: 'Ejecutivo de ventas' },
    { id: 'ejecutivo_cuentas_clave', label: 'Ejecutivo de cuentas clave' },
    { id: 'director_comercial', label: 'Director comercial' },
  ],
};

export const marketingJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'administrador_marca', label: 'Administrador de marca' },
    { id: 'director_marketing', label: 'Director de marketing' },
    { id: 'community_manager', label: 'Community Manager' },
    { id: 'analista_mercadeo', label: 'Analista de mercadeo' },
    { id: 'analista_mercadeo_digital', label: 'Analista de mercadeo digital' },
    { id: 'seo', label: 'SEO' },
    { id: 'content_manager', label: 'Content manager' },
    { id: 'email_marketing_manager', label: 'Email marketing manager' },
    { id: 'relaciones_publicas', label: 'Relaciones publicas' },
    { id: 'comunicaciones', label: 'Comunicaciones' },
    { id: 'portavoz', label: 'Portavoz' },
    { id: 'marketing_project_manager', label: 'Marketing project manager' },
    { id: 'marketing_lead', label: 'Marketing lead' },
    { id: 'marketing_partnership_specialist', label: 'Marketing partnership specialist' },
  ],
};

export const designJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'diseñador_grafico', label: 'Diseñador grafico' },
    { id: 'diseñador_experiencia_usuario', label: 'Diseñador de experiencia de usuario' },
    { id: 'ilustrador', label: 'Ilustrador' },
    { id: 'director_creativo', label: 'Director creativo' },
    { id: 'diseñador_web', label: 'Diseñador web' },
    { id: 'diseñador_industrial', label: 'Diseñador industrial' },
    { id: 'diseñador_juegos', label: 'Diseñador de juegos' },
  ],
};

export const socialNetworksJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'administrador_redes_sociales', label: 'Administrador de redes sociales' },
    { id: 'administrador_comunidad', label: 'Administrador de la comunidad' },
    { id: 'analista_redes_sociales', label: 'Analista de redes sociales (usos de Facebook)' },
    { id: 'embajador_marca_instagram', label: 'Embajador de marca de Instagram' },
    { id: 'influenciador_redes_sociales', label: 'Influenciador de las redes sociales' },
    { id: 'estratega_marketing_social', label: 'Estratega de marketing social (usos de Facebook)' },
    { id: 'creador_contenido_redes_sociales', label: 'Creador de contenido de redes sociales' },
    { id: 'embajador_social', label: 'Embajador Social (o Embajador de Instagram)' },
    { id: 'entrenador_redes_sociales', label: 'Entrenador de redes sociales' },
  ],
};

export const humanResourcesJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'reclutador', label: 'Reclutador' },
    { id: 'asistente_recursos_humanos', label: 'Asistente de recursos humanos' },
    { id: 'analista_rrhh', label: 'Analista de RRHH' },
    { id: 'reclutador_virtual', label: 'Reclutador virtual' },
    { id: 'gerente_recursos_humanos', label: 'Gerente de Recursos Humanos' },
    { id: 'cazatalentos', label: 'Cazatalentos' },
    { id: 'gerente_capacitación', label: 'Gerente de Capacitación' },
  ],
};

export const customerServiceJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'representante_servicio_cliente', label: 'Representante de Servicio al Cliente' },
    { id: 'gerente_servicio_cliente', label: 'Gerente del servicio al cliente' },
    { id: 'supervisor_centro_llamadas', label: 'Supervisor de centro de llamadas' },
    { id: 'csm', label: 'CSM' },
    { id: 'gerente_centro_llamadas', label: 'Gerente de centro de llamadas' },
    { id: 'representante_centro_llamadas', label: 'Representante del centro de llamadas' },
    { id: 'gerente_relaciones', label: 'Gerente de relaciones' },
    { id: 'administrador_cuentas_remotas', label: 'Administrador de cuentas remotas' },
  ],
};

export const callCenterJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'agente_chat', label: 'Agente de chat' },
    { id: 'rsc', label: 'RSC' },
    { id: 'agente_chat_remoto', label: 'Agente de chat remoto' },
    { id: 'supervisor_centro_llamadas', label: 'Supervisor de centro de llamadas' },
    { id: 'representante_centro_llamadas', label: 'Representante del centro de llamadas' },
    { id: 'agente_chat_en_línea', label: 'Agente de chat en línea' },
    { id: 'gerente_centro_llamadas', label: 'Gerente de centro de llamadas' },
    { id: 'agente_chat_en_vivo', label: 'Agente de chat en vivo' },
    { id: 'director_centro_llamadas', label: 'Director del centro de llamadas' },
    { id: 'representante_atención_cliente', label: 'Representante de Atención al Cliente' },
  ],
};

export const productManagementJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'gerente_producto', label: 'Gerente de producto' },
    { id: 'dueño_producto', label: 'Dueño del producto' },
    { id: 'diseñador_productos', label: 'Diseñador de productos' },
    { id: 'ingeniero_producto', label: 'Ingeniero de Producto' },
    { id: 'gerente_sénior_productos', label: 'Gerente sénior de productos' },
    { id: 'analista_producto', label: 'Analista de producto' },
    { id: 'ingeniero_diseño_producto', label: 'Ingeniero de Diseño de Producto' },
  ],
};

export const operationsJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'gerente_operaciones', label: 'Gerente de Operaciones' },
    { id: 'director_operacion', label: 'Director de operación' },
    { id: 'vice_presidente_operaciones', label: 'Vice presidente de operaciones' },
    { id: 'analista_investigación_operaciones', label: 'Analista de Investigación de Operaciones' },
    { id: 'analista_operaciones', label: 'Analista de Operaciones' },
    { id: 'coordinador_operaciones', label: 'Coordinador de Operaciones' },
    { id: 'consultor_soluciones', label: 'Consultor de soluciones' },
  ],
};

export const managementJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'asistente_virtual', label: 'Asistente virtual' },
    { id: 'recepcionista', label: 'Recepcionista' },
    { id: 'ayudante_administrativo', label: 'Ayudante Administrativo' },
    { id: 'asistente_personal', label: 'Asistente personal' },
    { id: 'asistente_oficina', label: 'Asistente de oficina' },
    { id: 'asistente', label: 'Asistente' },
    { id: 'archivero', label: 'Archivero' },
    { id: 'secretario', label: 'Secretario' },
    { id: 'gerente_oficina', label: 'Gerente de oficina' },
  ],
};

export const legalJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'abogado', label: 'Abogado' },
    { id: 'asistente_legal', label: 'Asistente legal' },
    { id: 'secretaria_legal', label: 'Secretaria legal' },
    { id: 'consejero_general', label: 'Consejero general' },
    { id: 'oficial_cumplimiento', label: 'Oficial de Cumplimiento' },
    { id: 'consejo', label: 'Consejo' },
    { id: 'notario', label: 'Notario' },
    { id: 'agente_patentes', label: 'Agente de patentes' },
    { id: 'administrador_contratos', label: 'Administrador de contratos' },
    { id: 'consejo_interno', label: 'Consejo Interno' },
    { id: 'director_cumplimiento', label: 'Director de Cumplimiento' },
  ],
};

export const accountingJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'contador', label: 'Contador' },
    { id: 'cpa', label: 'CPA' },
    { id: 'empleado_contabilidad', label: 'Empleado de Contabilidad' },
    { id: 'contador_personal', label: 'Contador del personal' },
    { id: 'auxiliar_contabilidad', label: 'Auxiliar de Contabilidad' },
    { id: 'controlador', label: 'Controlador' },
    { id: 'contador_senior', label: 'Contador senior' },
    { id: 'contador_fiscal', label: 'Contador Fiscal' },
    { id: 'gerente_contabilidad', label: 'Gerente de Contabilidad' },
    { id: 'especialista_en_salarios', label: 'Especialista en salarios' },
    { id: 'gerente_nomina', label: 'Gerente de nomina' },
    { id: 'empleado_la_nómina', label: 'Empleado de la nómina' },
    { id: 'administrador_nómina', label: 'Administrador de nómina' },
    { id: 'directora_nómina', label: 'Directora de nómina' },
    { id: 'contador_nómina', label: 'Contador de nómina' },
    { id: 'especialista_en_nómina_recursos_humanos', label: 'Especialista en nómina de recursos humanos' },
  ],
};

export const financeJobsName = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'analista_financiero', label: 'Analista Financiero' },
    { id: 'asesor_financiero', label: 'Asesor Financiero' },
    { id: 'economista', label: 'Economista' },
    { id: 'analista_crédito', label: 'Analista de crédito' },
    { id: 'analista_presupuesto', label: 'Analista de Presupuesto' },
    { id: 'analista_financiero_nivel_entrada', label: 'Analista financiero de nivel de entrada' },
    { id: 'director_financiero', label: 'Director financiero' },
    { id: 'gerente_finanzas', label: 'Gerente de Finanzas' },
    { id: 'agente_compras', label: 'Agente de Compras' },
    { id: 'gerente_financiero', label: 'Gerente Financiero' },
  ],
};

export const insuranceJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  options: [
    { id: 'ajustador_reclamos', label: 'Ajustador de reclamos' },
    { id: 'agente_seguros', label: 'Agente de seguros' },
    { id: 'analista_actuarial', label: 'Analista Actuarial' },
    { id: 'suscriptor_seguros', label: 'Suscriptor de seguros' },
    { id: 'investigador_seguros', label: 'Investigador de Seguros' },
  ],
};

export const otherJobsNames = {
  order: 3,
  id: 'employmentsDone',
  type: 'selectMultiple',
  label: '¿Cuáles puestos has desempeñado?',
  other: true,
  required: true,
  otherPlaceholderTextField: 'Escriba aquí los otros puestos desempeñados',
  otherSelectedByDefault: true,
  options: [
    softwareDevelopmentJobsNames.options[Math.floor(Math.random() * 4)],
    businessSalesJobsNames.options[Math.floor(Math.random() * 4)],
    marketingJobsNames.options[Math.floor(Math.random() * 4)],
    designJobsNames.options[Math.floor(Math.random() * 4)],
    socialNetworksJobsNames.options[Math.floor(Math.random() * 4)],
    humanResourcesJobsNames.options[Math.floor(Math.random() * 4)],
    customerServiceJobsNames.options[Math.floor(Math.random() * 4)],
    callCenterJobsNames.options[Math.floor(Math.random() * 4)],
    productManagementJobsNames.options[Math.floor(Math.random() * 4)],
    operationsJobsNames.options[Math.floor(Math.random() * 4)],
    managementJobsNames.options[Math.floor(Math.random() * 4)],
    legalJobsNames.options[Math.floor(Math.random() * 4)],
    accountingJobsNames.options[Math.floor(Math.random() * 4)],
    financeJobsName.options[Math.floor(Math.random() * 4)],
    insuranceJobsNames.options[Math.floor(Math.random() * 4)],
  ],
};

export const onboardingSteps = {
  id: 'profile',
  label: 'Perfil',
  order: 1,
  description: 'Queremos conocerte y generar una conexión personalizada con los mejores mentores.',
  defaultValuesForm: {
    jobSeekingCountries: '',
    favoriteEmploymentModality: '',
  },
  questions: [
    {
      order: 1,
      id: 'changeJobReasons',
      type: 'buttonGroup',
      label: 'ClientOnboarding.motivacion.titulo',
      other: true,
      otherPlaceholderTextField: 'ClientOnboarding.motivacion.motivaciontitulo',
      otherIcon: 'M23 3H1v16h7v2h8v-2h7V3zm-2 14H3V5h18v12zm-2-9H8v2h11V8zm0 4H8v2h11v-2zM7 8H5v2h2V8zm0 4H5v2h2v-2z',
      required: false,
      options: [
        {
          id: 'grow_professionally',
          label: 'ClientOnboarding.motivacion.crecer',
          icon: 'M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z',
        },
        {
          id: 'work_remotely',
          label: 'ClientOnboarding.motivacion.trabajoRemoto',
          icon: 'M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z',
        },
        {
          id: 'change_career',
          label: 'ClientOnboarding.motivacion.cambioCarrera',
          icon: 'm22.69 18.37 1.14-1-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L20 14h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1c-.08.5-.08.76 0 1.26l-1.14 1 1 1.73 1.45-.49c.32.27.68.48 1.08.63L18 24h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1c.08-.51.08-.77 0-1.27zM19 21c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM11 7v5.41l2.36 2.36 1.04-1.79-1.4-1.39V7h-2zm10 5c0-4.97-4.03-9-9-9-2.83 0-5.35 1.32-7 3.36V4H3v6h6V8H6.26C7.53 6.19 9.63 5 12 5c3.86 0 7 3.14 7 7h2zm-10.14 6.91c-2.99-.49-5.35-2.9-5.78-5.91H3.06c.5 4.5 4.31 8 8.94 8h.07l-1.21-2.09z',
        },
        {
          id: 'change_work_environment',
          label: 'ClientOnboarding.motivacion.ambiente',
          icon: 'M11 14H9c0-4.97 4.03-9 9-9v2c-3.87 0-7 3.13-7 7zm7-3V9c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3zM7 4c0-1.11-.89-2-2-2s-2 .89-2 2 .89 2 2 2 2-.89 2-2zm4.45.5h-2C9.21 5.92 7.99 7 6.5 7h-3C2.67 7 2 7.67 2 8.5V11h6V8.74c1.86-.59 3.25-2.23 3.45-4.24zM19 17c1.11 0 2-.89 2-2s-.89-2-2-2-2 .89-2 2 .89 2 2 2zm1.5 1h-3c-1.49 0-2.71-1.08-2.95-2.5h-2c.2 2.01 1.59 3.65 3.45 4.24V22h6v-2.5c0-.83-.67-1.5-1.5-1.5z',
        },
        {
          id: 'salary_increase',
          label: 'ClientOnboarding.motivacion.aumento',
          icon: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z',
        },
      ],
    },
    {
      order: 2,
      id: 'desiredEmploymentArea',
      type: 'autocomplete',
      label: 'ClientOnboarding.Area.titulo',
      required: false,
      options: workAreaList,
      placeholder: 'ClientOnboarding.Area.placeholder',
    },
    {
      order: 3,
      id: 'haveEmployment',
      type: 'buttonGroup',
      label: 'ClientOnboarding.TienesEmpleo.titulo',
      required: false,
      options: [
        { id: 'yes', label: 'ClientOnboarding.TienesEmpleo.SiTengoEmpleo', icon: 'M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z' },
        { id: 'no', label: 'ClientOnboarding.TienesEmpleo.NoTengoEmpleo', icon: 'M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM4 12c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7L5.7 16.9C4.6 15.5 4 13.8 4 12zm8 8c-1.8 0-3.5-.6-4.9-1.7L18.3 7.1C19.4 8.5 20 10.2 20 12c0 4.4-3.6 8-8 8z' },
      ],
    },
    {
      order: 4,
      id: 'jobSeekingCountries',
      type: 'selectSimple',
      label: 'ClientOnboarding.pais.titulo',
      required: false,
      addCountryByDefault: true,
      options: countryList,
    },
    {
      order: 5,
      id: 'experienceYears',
      type: 'radioSwitch',
      label: 'ClientOnboarding.experiencia.titulo',
      other: false,
      required: false,
      options: experienceYearsList,
    },
    {
      order: 6,
      id: 'englishLevel',
      type: 'buttonGroup',
      label: 'ClientOnboarding.nivelIngles.titulo',
      required: false,
      options: englishLevelList,
    },
  ],
};

export const mentorsFakeData = [
  {
    mentorId: 1,
    profilePhotoUrl: 'https://storage.googleapis.com/storage.mensismentor.com/profiles/Erica-Mensis.jpeg',
    mentorName: 'Erica',
    mentorLastName: 'Vergel Lian',
    currentPosition: 'Directora Regional de Talento Humano',
    liveCountry: 'Colombia',
    mentorStars: 5,
    servicesNumber: 0,
    englishLevel: 'Intermedio',
    experienceYears: 5,
    score: '0.9',
    profileDescription: 'Psicóloga especialista en Desarrollo Organizacional y Procesos Humanos, egresada de la Universidad del Norte, con mas del 10 años de experiencia liderando equipos de Gestión Humana en diversos sectores laborales, de empresas nacionales, multinacionales y con organización sindical. Persona con actitud de liderazgo y servicio, con una alta capacidad para integrar y coordinar los recursos organizacionales, para alcanzar los objetivos definidos de la manera más eficaz y eficiente posible.',
    canWorkAreas: [
      'Recursos humanos',
      'Desarrollo de software',
      'Tecnología',
    ],
  },
  {
    mentorId: 2,
    profilePhotoUrl: 'https://storage.googleapis.com/storage.mensismentor.com/profiles/Jose-Mensis.jpeg',
    mentorName: 'Jose',
    mentorLastName: 'Vindas',
    currentPosition: 'Process Engineer',
    liveCountry: 'Costa Rica',
    mentorStars: 4,
    servicesNumber: 3,
    englishLevel: 'Avanzado',
    experienceYears: 11,
    score: '0.6',
    profileDescription: 'Profesional con amplia experiencia en servicio al cliente, procesos y consultorías de empleabilidad',
    canWorkAreas: [],
  },
  {
    mentorId: 3,
    profilePhotoUrl: 'https://storage.googleapis.com/storage.mensismentor.com/profiles/Dunnia-Mensis.jpeg',
    mentorName: 'Dunnia',
    mentorLastName: 'Vargas González',
    currentPosition: 'Directora de Marca',
    liveCountry: 'Costa Rica',
    mentorStars: 5,
    servicesNumber: 42,
    englishLevel: 'Básico',
    experienceYears: 11,
    score: '0.5',
    profileDescription: 'En la actualidad, como Consultora he brindado servicios diversos a mis clientes y trabajado con equipos de distintas áreas del marketing y eventos como Jurgen Klaric, Nora Beltrán, Biia Lab y Kike Ramírez, tanto en ramas de ingeniería de procesos, logística, análisis y optimización de redes sociales, en busca de simplificación de procesos y potenciar que se logren sus objetivos.',
  },
];

export const pricingPlansFakeData = [
  {
    pricePlanId: 1,
    pricePlanAmount: 100000,
    pricePlanCurrency: 'USD',
    pricePlanSymbol: '$',
    pricePlanName: 'Básico',
    pricePlanDescription: 'Este programa es para profesionales que desean llevar un servicio especifico, ya sea simular entrevistas, revisar linkedin o revisar el Curriculum Vitae.',
    pricePlanOptions: [
      '2 sesiones personalizadas',
      'Sesiones de 1 hora',
      'Revisión de CV y linkedin',
      'Simulación de entrevistas (1)',
      'Soporte 24/7',
    ],
    pricePlanIsPopular: 0,
  },
  {
    pricePlanId: 2,
    pricePlanAmount: 150,
    pricePlanCurrency: 'USD',
    pricePlanSymbol: '$',
    pricePlanName: 'Estandar',
    pricePlanDescription: 'Este programa es para profesionales que requieren trabajar el programa completo de empleabilidad.',
    pricePlanOptions: [
      '4 sesiones personalizadas',
      'Sesiones de 1 hora',
      'Revisión de CV y LinkedIn',
      'Simulaciones de entrevistas(2)',
      'Recomendaciones de empleos',
      'Soporte 24/7',
    ],
    pricePlanIsPopular: 1,
  },
  {
    pricePlanId: 3,
    pricePlanAmount: 250,
    pricePlanCurrency: 'USD',
    pricePlanSymbol: '$',
    pricePlanName: 'Pro',
    pricePlanDescription: 'Programa de empleabilidad y acceso a perfil en Mensis para que todos los reclutadores dentro de la plataforma y a las empresas aliadas estratégicas que buscan candidatos en Mensis, puedan conectar.',
    pricePlanOptions: [
      '4 sesiones personalizadas',
      'Sesiones de 1 hora',
      'Validación de CV y LinkedIn',
      'Simulaciones de entrevistas',
      'Recomendaciones de empleo',
      'Acceso a perfil Mensis',
      'Conexión con empresas reales',
    ],
    pricePlanIsPopular: 0,
  },
];

export const onboardingRegistrationFakeData = {
  demonstrationDetails: {
    clientName: 'Pablo',
    clientLastName: 'Madrigal',
    clientEmail: 'me@pablomadrigal.com',
    phone: '+506 7105 9897',
    meetingLink: '',
    demonstrationTimeUTC: '',
    timeZone: '',
    status: 200,
    message: '',
  },
  questionsAndResponses: {
    englishLevel: {
      id: 8,
      dependenciesIds: [
        0,
      ],
      question: {
        text: '¿Cuál es tu nivel de inglés?',
        keyword: 'englishLevel',
      },
      responses: [
        {
          text: 'Intermedio',
          keyword: '3',
        },
      ],
    },
    haveEmployment: {
      id: 6,
      dependenciesIds: [
        0,
      ],
      question: {
        text: '¿Actualmente tienes empleo?',
        keyword: 'haveEmployment',
      },
      responses: [
        {
          text: 'Si tengo empleo',
          keyword: 'yes',
        },
      ],
    },
    desiredEmploymentArea: {
      id: 5,
      dependenciesIds: [
        0,
      ],
      question: {
        text: '¿En qué área desea encontrar empleo?',
        keyword: 'desiredEmploymentArea',
      },
      responses: [
        {
          text: 'Desarrollo de software',
          keyword: 'software_development',
        },
      ],
    },
    jobSeekingCountries: {
      id: 2,
      dependenciesIds: [
        0,
      ],
      question: {
        text: '¿En cuál país estás buscando empleo?',
        keyword: 'jobSeekingCountries',
      },
      responses: [
        {
          text: 'Costa Rica',
          keyword: 'CRI',
        },
      ],
    },
    changeJobReasons: {
      id: 4,
      dependenciesIds: [
        0,
      ],
      question: {
        text: '¿Cuál es tu principal motivación para encontrar un mejor empleo?',
        keyword: 'changeJobReasons',
      },
      responses: [
        {
          text: 'Deseo crecer profesionalmente',
          keyword: 'grow_professionally',
        },
      ],
    },
    experienceYears: {
      id: 7,
      dependenciesIds: [
        0,
      ],
      question: {
        text: '¿Cuántos años de experiencias laboral tienes?',
        keyword: 'experienceYears',
      },
      responses: [
        {
          text: 'De 1 a 3 años',
          keyword: '3',
        },
      ],
    },
  },
  paymentId: 242,
};

export const serviceDetailsData = [
  {
    id: 0, icon: <EventAvailableOutlinedIcon color="primary" fontSize="large" sx={{ m: 2 }} />, title: 'serviceDetailsData.titleFlex', description: 'serviceDetailsData.descriptionFlex',
  },
  {
    id: 1, icon: <BookIcon color="primary" fontSize="large" sx={{ m: 2 }} />, title: 'serviceDetailsData.titleAse', description: 'serviceDetailsData.descriptionAse',
  },
  {
    id: 2, icon: <HeadphonesOutlinedIcon color="primary" fontSize="large" sx={{ m: 2 }} />, title: 'serviceDetailsData.titleSes', description: 'serviceDetailsData.descriptionSes',
  },
  {
    id: 3, icon: <FormIcon color="primary" fontSize="large" sx={{ m: 2 }} />, title: 'serviceDetailsData.titleEnt', description: 'serviceDetailsData.descriptionEnt',
  },
];
