import { useTranslation } from 'react-i18next';
import ContentWithIndex from '../components/contentWithIndex/ContentWithIndex';
import Heading from '../components/contentWithIndex/Heading';
import BasicLayout from '../components/layout/BasicLayout';
import useAuth from '../hooks/useAuth';
import { AuthStates } from '../constants/authConstants';

const WEB_URL = process.env.REACT_APP_MENSIS_WEB_URL;

function PrivacyData() {
  const [t] = useTranslation('common');
  return (
    <>

      <h1>{t('Privacy.Titulo')}</h1>
      <p>{t('Privacy.Cuerpo')}</p>
      <Heading as="h2">{t('Privacy.Antecedentes.Titulo')}</Heading>
      <p>
        <span>{t('Privacy.Antecedentes.Cuerpo.p1')}</span>
        <a href={WEB_URL}>mensismentor.com</a>
        <span>{t('Privacy.Antecedentes.Cuerpo.p2')}</span>
        <a href="mailto:adrian.diaz@mensismentor.com">adrian.diaz@mensismentor.com</a>
        <span>{t('Privacy.Antecedentes.Cuerpo.p3')}</span>
      </p>
      <Heading as="h3">{t('Privacy.Servicios.Titulo')}</Heading>
      <p>
        {t('Privacy.Servicios.Cuerpo.p1')}
      </p>
      <Heading as="h3">{t('Privacy.Cambios.Titulo')}</Heading>
      <p>
        {t('Privacy.Cambios.Cuerpo.p1')}
      </p>

      <Heading as="h2">{t('Privacy.ConsentimientoInformado.Titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.ConsentimientoInformado.Cuerpo.p2')}
      </p>
      <p>
        {t('Privacy.ConsentimientoInformado.Cuerpo.p3')}
      </p>
      <Heading as="h3">{t('Privacy.ConsentimientoInformado.disposicion.1.titulo')}</Heading>
      <p>
        <span>{t('Privacy.ConsentimientoInformado.disposicion.1.cuerpo.p1')}</span>
        {' '}
        <a href="tel:+50687566313">
          +506 8756 6313
        </a>
        {t('Privacy.ConsentimientoInformado.disposicion.1.cuerpo.p2')}
        {' '}
        <a href="mailto:info@mensismentor.com">info@mensismentor.com</a>
        {t('Privacy.ConsentimientoInformado.disposicion.1.cuerpo.p3')}
      </p>
      <Heading as="h3">{t('Privacy.ConsentimientoInformado.disposicion.2.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.1.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.1.cuerpo.p1')}
      </p>
      <ul>
        <li>{t('Privacy.ConsentimientoInformado.disposicion.2.1.items.nombre')}</li>
        <li>{t('Privacy.ConsentimientoInformado.disposicion.2.1.items.correo')}</li>
        <li>{t('Privacy.ConsentimientoInformado.disposicion.2.1.items.numtelefono')}</li>
        <li>{t('Privacy.ConsentimientoInformado.disposicion.2.1.items.contrasena')}</li>
      </ul>
      <br />
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.2.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.2.cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.3.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.3.cuerpo.p1')}
      </p>

      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.4.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.4.cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.5.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.5.cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.6.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.6.cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.7.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.7.cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.ConsentimientoInformado.disposicion.2.8.titulo')}</Heading>
      <p>
        {t('Privacy.ConsentimientoInformado.disposicion.2.8.cuerpo.p1')}
      </p>

      <Heading as="h3">{t('Privacy.FinalidadDeLosDatos.3.Titulo')}</Heading>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.Cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.FinalidadDeLosDatos.3.1.Titulo')}</Heading>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.1.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.1.Cuerpo.p2')}
      </p>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.1.Cuerpo.p3')}
      </p>
      <Heading as="h4">{t('Privacy.FinalidadDeLosDatos.3.2.Titulo')}</Heading>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.2.Cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.FinalidadDeLosDatos.3.3.Titulo')}</Heading>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.3.Cuerpo.p1')}
      </p>
      <Heading as="h4">
        {t('Privacy.FinalidadDeLosDatos.3.4.Titulo')}
      </Heading>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.4.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.4.Cuerpo.p2')}
      </p>
      <p>
        {t('Privacy.FinalidadDeLosDatos.3.4.Cuerpo.p3')}
      </p>

      <Heading as="h3">{t('Privacy.ComoCompartimosLaInformacion.4.Titulo')}</Heading>
      <Heading as="h4">{t('Privacy.ComoCompartimosLaInformacion.4.1.Titulo')}</Heading>
      <Heading as="h5">{t('Privacy.ComoCompartimosLaInformacion.4.1.items.1.Titulo')}</Heading>
      <p>
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.1.Cuerpo.p1')}
      </p>
      <Heading as="h5">
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.2.Titulo')}
      </Heading>
      <p>
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.2.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.ComoCompartimosLaInformacion.4.1.items.3.Titulo')}</Heading>
      <p>
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.3.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.ComoCompartimosLaInformacion.4.1.items.4.Titulo')}</Heading>
      <p>
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.4.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.ComoCompartimosLaInformacion.4.1.items.5.Titulo')}</Heading>
      <p>
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.5.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.ComoCompartimosLaInformacion.4.1.items.6.Titulo')}</Heading>
      <p>
        {t('Privacy.ComoCompartimosLaInformacion.4.1.items.6.Cuerpo.p1')}
      </p>

      <Heading as="h3">{t('Privacy.PoliticaDeCookies.5.Titulo')}</Heading>
      <Heading as="h4">{t('Privacy.PoliticaDeCookies.5.1.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.1.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.PoliticaDeCookies.5.1.Cuerpo.p2')}
      </p>
      <Heading as="h4">{t('Privacy.PoliticaDeCookies.5.2.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.2.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.PoliticaDeCookies.5.2.items.1.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.2.items.1.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.PoliticaDeCookies.5.2.items.2.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.2.items.2.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.PoliticaDeCookies.5.2.items.3.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.2.items.3.Cuerpo.p1')}
      </p>
      <Heading as="h5">{t('Privacy.PoliticaDeCookies.5.2.items.4.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.2.items.4.Cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.PoliticaDeCookies.5.3.Titulo')}</Heading>
      <p>
        {t('Privacy.PoliticaDeCookies.5.3.Cuerpo.p1')}
      </p>
      <ul>
        <li>
          <a href="https://support.mozilla.org/es/kb/Borrar%20cookies">
            Firefox
          </a>
        </li>
        <li>
          <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=es">
            Chrome
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">
            Internet Explorer
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
            Safari
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/es-es/HT201265">
            Safari para iPhone, iPad o iPod Touch
          </a>
        </li>
      </ul>
      <p>
        {t('Privacy.PoliticaDeCookies.5.3.Cuerpo.p2')}
        {' '}
        <a href="https://www.torproject.org/download/download.html">TOR</a>
        {' '}
        {t('Privacy.PoliticaDeCookies.5.3.Cuerpo.p3')}
        {' '}
        <a href="https://brave.com/?ref=mrn887/">BRAVE</a>
        {' '}
        {t('Privacy.PoliticaDeCookies.5.3.Cuerpo.p4')}
      </p>

      <Heading as="h3">{t('Privacy.PrincipiosAplicadosAlTratamientoDeDatos.6.Titulo')}</Heading>
      <p>
        {t('Privacy.PrincipiosAplicadosAlTratamientoDeDatos.6.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.PrincipiosAplicadosAlTratamientoDeDatos.6.Cuerpo.p2')}
      </p>

      <Heading as="h3">{t('Privacy.GoogleCalendarIntegration.title')}</Heading>
      <p>
        {t('Privacy.GoogleCalendarIntegration.begin-p1')}
        <a target="_blank" href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" rel="noreferrer">
          {t('Privacy.GoogleCalendarIntegration.p1.a')}
        </a>
        {t('Privacy.GoogleCalendarIntegration.end-p1')}
      </p>
      <Heading as="h5">{t('Privacy.GoogleCalendarIntegration.GoogleCompliance.title')}</Heading>
      <ul>
        <li>{t('Privacy.GoogleCalendarIntegration.GoogleCompliance.li1')}</li>
      </ul>
      <br />
      <Heading as="h5">{t('Privacy.GoogleCalendarIntegration.DataAccess.title')}</Heading>
      <ul>
        <li>{t('Privacy.GoogleCalendarIntegration.DataAccess.li1')}</li>
        <li>{t('Privacy.GoogleCalendarIntegration.DataAccess.li2')}</li>
      </ul>
      <br />
      <Heading as="h5">{t('Privacy.GoogleCalendarIntegration.CalendarManagement.title')}</Heading>
      <ul>
        <li>{t('Privacy.GoogleCalendarIntegration.CalendarManagement.li1')}</li>
        <li>{t('Privacy.GoogleCalendarIntegration.CalendarManagement.li2')}</li>
      </ul>
      <br />
      <Heading as="h5">{t('Privacy.GoogleCalendarIntegration.TransparencyControl.title')}</Heading>
      <ul>
        <li>{t('Privacy.GoogleCalendarIntegration.TransparencyControl.li1')}</li>
        <li>{t('Privacy.GoogleCalendarIntegration.TransparencyControl.li2')}</li>
      </ul>
      <br />
      <Heading as="h3">{t('Privacy.DerechosDeUsuario.8.Titulo')}</Heading>
      <p>
        {t('Privacy.DerechosDeUsuario.8.Cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.DerechosDeUsuario.8.1.Titulo')}</Heading>
      <p>
        {t('Privacy.DerechosDeUsuario.8.1.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.DerechosDeUsuario.8.1.Cuerpo.p2')}
      </p>
      <Heading as="h4">{t('Privacy.DerechosDeUsuario.8.2.Titulo')}</Heading>
      <p>
        {t('Privacy.DerechosDeUsuario.8.2.Cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.DerechosDeUsuario.8.3.Titulo')}</Heading>
      <p>
        {t('Privacy.DerechosDeUsuario.8.3.Cuerpo.p1')}
      </p>
      <Heading as="h4">{t('Privacy.DerechosDeUsuario.8.4.Titulo')}</Heading>
      <p>
        {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.p2')}
        {' '}
        <a href="mailto:info@mensismentor.com">info@mensismentor.com</a>
        {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.p3')}
      </p>
      <span>
        {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.p4')}
      </span>
      <a href="http://www.prodhab.go.cr/">(http://www.prodhab.go.cr/)</a>
      <p>
        {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.p5')}
      </p>
      <ul>
        <li>
          <a href="http://www.prodhab.go.cr/download/PROCEPROTDERECHOS/FormularioAccesoaDatos.docx">
            {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.items.1')}
          </a>
        </li>
        <li>
          <a href="http://www.prodhab.go.cr/download/PROCEPROTDERECHOS/FormularioSupresion.docx">
            {t('Privacy.DerechosDeUsuario.8.4.Cuerpo.items.2')}
          </a>
        </li>
      </ul>
      <br />
      <Heading as="h3">{t('Privacy.SeguridadDeLosDatosPersonales.9.Titulo')}</Heading>
      <p>
        {t('Privacy.SeguridadDeLosDatosPersonales.9.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.SeguridadDeLosDatosPersonales.9.Cuerpo.p2')}
      </p>
      <p>
        {t('Privacy.SeguridadDeLosDatosPersonales.9.Cuerpo.p3')}
      </p>

      <Heading as="h3">
        {t('Privacy.ModificacionDePoliticaDePrivacidad.10.Titulo')}
      </Heading>
      <p>
        {t('Privacy.ModificacionDePoliticaDePrivacidad.10.Cuerpo.p1')}
      </p>
      <p>
        {t('Privacy.ModificacionDePoliticaDePrivacidad.10.Cuerpo.p2')}
      </p>
    </>
  );
}

function Privacy() {
  const { authStatus } = useAuth();

  return (
    <BasicLayout displayLinks={authStatus !== AuthStates.LOGGED_IN}>
      <ContentWithIndex>
        <PrivacyData />
      </ContentWithIndex>
    </BasicLayout>
  );
}

Privacy.propTypes = {};

Privacy.defaultProps = {};

export default Privacy;
