import { useTranslation } from 'react-i18next';
import { Fade } from 'react-reveal';
import { Grid } from '@mui/material';
import BasicLayout from '../components/layout/BasicLayout';
import VideoPlayer from '../components/video/youtube/VideoPlayer';
import { contentVideos } from '../data/GeneralData';
import Footer from '../components/footer/Footer';

function Academy() {
  const [t] = useTranslation('common');
  return (
    <BasicLayout showBanner displayLinks>
      <section id="testimonials" className="testimonials">
        <div className="container">
          <Fade bottom>
            <div style={{ textAlign: 'left' }}>
              <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c', fontSize: '32px' }}>
                {t('Academy.Titulo')}
              </h2>
              <p style={{ fontFamily: 'Montserrat' }}>
                {t('Academy.Cuerpo')}
              </p>
            </div>
          </Fade>
          <Grid container>
            {contentVideos.map((item) => (
              <VideoPlayer key={item.title} videoUrl={item.url} title={item.title} />
            ))}
          </Grid>
        </div>
      </section>
      <Footer showImportantLinks={false} />
    </BasicLayout>
  );
}

Academy.propTypes = {};

Academy.defaultProps = {};

export default Academy;
