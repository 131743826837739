/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  rawResponses: null,
  questionsAndResponses: null,
  responsesId: null,
  pricePackages: [],
  selectedPricePackage: null,
  mentors: [],
  selectedMentor: null,
};

const clientOnboardingSlice = createSlice({
  name: 'clientOnboarding',
  initialState,
  reducers: {
    updateRawResponses: (state, action) => {
      state.rawResponses = action.payload;
    },
    updateAnswers: (state, action) => {
      state.questionsAndResponses = action.payload;
    },
    setResponsesId: (state, action) => {
      state.responsesId = action.payload;
    },
    updatePricePackages: (state, action) => {
      state.pricePackages = action.payload;
    },
    selectPricePackage: (state, action) => {
      state.selectedPricePackage = action.payload;
    },
    updateMentors: (state, action) => {
      state.mentors = action.payload;
    },
    selectMentor: (state, action) => {
      state.selectedMentor = action.payload;
    },
    resetOnboarding: () => initialState,
  },
});

export const {
  updateRawResponses,
  updateAnswers,
  setResponsesId,
  updatePricePackages,
  selectPricePackage,
  updateMentors,
  selectMentor,
  resetOnboarding,
} = clientOnboardingSlice.actions;

const selectState = (state) => state.clientOnboardingSlice;

export const rawResponsesSelector = createSelector([selectState], (state) => state.rawResponses);
export const questionsAndResponsesSelector = createSelector(
  [selectState],
  (state) => state.questionsAndResponses,
);
export const responsesIdSelector = createSelector([selectState], (state) => state.responsesId);
export const pricePackagesSelector = createSelector([selectState], (state) => state.pricePackages);
export const selectedPricePackageSelector = createSelector(
  [selectState],
  (state) => state.selectedPricePackage,
);
export const mentorsSelector = createSelector([selectState], (state) => state.mentors);
export const selectedMentorSelector = createSelector(
  [selectState],
  (state) => state.selectedMentor,
);

export default clientOnboardingSlice.reducer;
