import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Card, Grid, styled, Typography, Stack,
} from '@mui/material';
import theme from '../../../styles/theme';
import FluidImage from '../../images/FluidImage';
import Logo from '../../../assets/img/logo-mensis.png';
import Image from '../../../assets/img/IMG_0859.png';
import useDetectMobile from '../../../hooks/useDetectMobile';

const StyledCard = styled(Card)({
  backgroundColor: theme.palette.cardsBackground.main,
  borderRadius: '20px',
  boxShadow: '0px 0px 0px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.12), 0px 1px 3px 0px rgba(0,0,0,0.12)',
});

function AuthCard({ children }) {
  const { windowWidth } = useDetectMobile();
  const [t] = useTranslation('common');
  return (
    <StyledCard>
      <Grid container>
        {windowWidth >= 1200 && (
        <Grid
          item
          md={3}
          lg={4}
          sx={{ px: 5, backgroundColor: 'rgba(39, 59, 124, 0.07)', width: '100%' }}
        >
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ py: 10, height: '100%' }}
          >
            <Box sx={{ paddingBottom: '20px', marginTop: '-40px' }}>
              <FluidImage width="210px" imgURL={Logo} />
            </Box>
            <FluidImage width="300px" imgURL={Image} />
            <Typography sx={{ paddingTop: '30px' }} align="center" color={theme.palette.primary.main} fontSize="25px">{t('AuthCard')}</Typography>
          </Stack>
        </Grid>
        )}
        <Grid item xs={12} sm={12} md={windowWidth >= 1200 ? 9 : 12} lg={8} sx={{ px: 5 }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            my: 5,
          }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </StyledCard>
  );
}

AuthCard.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthCard.defaultProps = {};

export default AuthCard;
