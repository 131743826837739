import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MensisToken, JSessionToken } from '../constants/authConstants';
import { authStatusSelector, login, logout } from '../store/slices/authSlice';
import { getCookie, setCookie, deleteCookie } from '../utils/cookiesUtils';

export default function useAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authStatus = useSelector(authStatusSelector);

  const getSessionToken = () => {
    const cookie = getCookie(MensisToken);
    return cookie || null;
  };

  const triggerGetCookie = () => {
    const cookie = getCookie(MensisToken);
    if (cookie) {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  };

  const setSessionToken = (value) => {
    const numberOfHours = 1;
    setCookie(MensisToken, value, numberOfHours);
    triggerGetCookie();
  };

  const deleteSessionToken = () => {
    deleteCookie(MensisToken);
  };

  const getJSessionID = () => {
    const cookie = getCookie(JSessionToken);
    return cookie || null;
  };

  const setJSessionID = (value) => {
    const numberOfHours = 1;
    setCookie(JSessionToken, value, numberOfHours);
  };

  const deleteSessionID = () => {
    deleteCookie(JSessionToken);
  };

  const logOut = ({ showModalLogAgain, redirectTo }) => {
    deleteCookie(MensisToken);
    deleteCookie(JSessionToken);
    navigate(redirectTo || '/', { state: { showLoginAgainModal: showModalLogAgain } });
  };

  useEffect(() => {
    const cookie = getCookie(MensisToken);
    if (cookie) {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return {
    authStatus,
    getSessionToken,
    setSessionToken,
    deleteSessionToken,
    getJSessionID,
    setJSessionID,
    deleteSessionID,
    triggerGetCookie,
    logOut,
  };
}
