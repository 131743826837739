import { useTranslation } from 'react-i18next';
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Typography,
  styled,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import theme from '../../styles/theme';
import useAuth from '../../hooks/useAuth';
import { validateEmail } from '../../common/Util';
import { signUp } from '../../services/authService';
import TextField from '../controllerFormFields/TextField';
import Phone from '../controllerFormFields/Phone';
import { useGetCurrentCountryQuery } from '../../store/slices/currentCountryApi';
import LoadingDialog from '../dialog/LoadingDialog';
import ga from '../../AnalyticsEventTracker';

const StyledTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '20px',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  '&:hover': {
    color: '#f1652a',
  },
});

function StyledRequiredLabel() {
  return (
    <span style={{ color: theme.palette.grey[500], fontSize: '1rem' }}> *</span>
  );
}

const StyledButton = styled(Button)({
  borderRadius: '5px',
  fontSize: '24px',
  textTransform: 'inherit',
});

function Signup({ onSignup, changeAuth }) {
  const {
    control,
    handleSubmit,
    watch,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });

  const { data: currentCountry } = useGetCurrentCountryQuery();
  const { setSessionToken, setJSessionID } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const password = watch('password');
  const [t] = useTranslation('common');
  const onSubmit = (data) => {
    const payload = {
      ...data,
      localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      preferredLanguageId: t('Idioma'),
    };
    ga('Signup', 'Click', 'Register');
    setErrorMessage(null);
    setIsLoading(true);

    signUp(payload)
      .then((response) => response.json())
      .then((authResponse) => {
        if (authResponse.status === 200) {
          ga('Signup', 'Event', 'SuccessfulRegister');
          setSessionToken(authResponse.token);
          setJSessionID(authResponse.sessionId);
          onSignup();
        } else {
          ga('Signup', 'Event', 'FailedRegister');
          setErrorMessage(t('Signup.CorreoEnUso'));
        }
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        {isLoading && <LoadingDialog showModal={isLoading} text={t('Signup.Registrando')} />}
        <StyledTypography sx={{ fontSize: '32px' }}>
          {t('Signup.CrearCuenta')}
        </StyledTypography>
        <span style={{ color: theme.palette.grey[500], fontSize: '1rem', top: '-10px' }}>{t('Signup.CampoRequerido')}</span>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 5 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTypography>
                {t('Signup.Nombre')}
                <StyledRequiredLabel />
              </StyledTypography>
              <TextField
                name="name"
                placeholder={t('Signup.Nombre')}
                control={control}
                rules={{ required: t('Signup.NombreNecesario') }}
                autoComplete="name"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTypography>
                {t('Signup.Apellidos')}
                <StyledRequiredLabel />
              </StyledTypography>
              <TextField
                name="lastName"
                placeholder={t('Signup.Apellidos')}
                control={control}
                rules={{ required: t('Signup.ApellidosN') }}
                autoComplete="lastName"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTypography>
                {t('Signup.Correo')}
                <StyledRequiredLabel />
              </StyledTypography>
              <TextField
                name="email"
                placeholder={t('Signup.Dcorreo')}
                control={control}
                rules={{ required: t('Signup.DcorreoN'), validate: { email: (v) => validateEmail(v.trim()) || t('Signup.EmailInvalido') } }}
                autoComplete="email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTypography>
                {t('Signup.Telefono')}
                <StyledRequiredLabel />
              </StyledTypography>
              <Phone
                name="phone"
                key="phone"
                placeholder=""
                required
                control={control}
                fullWidth
                currentCountry={currentCountry?.country_code}
                sx={{ marginBottom: '8px' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTypography sx={{ weight: 'bold', size: '15px' }}>
                {t('Signup.Contraseña')}
                <StyledRequiredLabel />
              </StyledTypography>
              <TextField
                name="password"
                placeholder={t('Signup.Contraseña')}
                control={control}
                type="password"
                isPasswordType
                rules={{
                  required: t('Signup.ContraseñaN'),
                  maxLength: {
                    value: 30,
                    message: t('Signup.Mensaje'),
                  },
                  minLength: {
                    value: 7,
                    message: t('Signup.Mensaje'),
                  },
                }}
                autoComplete="current-password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <StyledTypography sx={{ weight: 'bold', size: '15px' }}>
                {t('Signup.ConfirmarContraseña')}
                <StyledRequiredLabel />
              </StyledTypography>
              <TextField
                name="confirmPassword"
                placeholder={t('Signup.Contraseña')}
                control={control}
                type="password"
                isPasswordType
                rules={{
                  required: t('Signup.ContraseñaN'),
                  validate: {
                    positive: (v) => v === password || t('Signup.ContraseñaCoincidir'),
                  },
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontFamily: 'Montserrat', mt: 2 }}>
            {t('Signup.Aceptar.Texto1')}
            {' '}
            <StyledLink to="/terms">
              {t('Signup.Aceptar.Condiciones')}
            </StyledLink>
            {t('Signup.Aceptar.Texto2')}
            {' '}
            <StyledLink to="/privacy">
              {t('Signup.Aceptar.Politicas')}
            </StyledLink>
            {' '}
            {t('Signup.Aceptar.Texto3')}
            {' '}
            <StyledLink to="/privacy#5--política-de-cookies">
              {t('Signup.Aceptar.Coockis')}
            </StyledLink>
            {t('Signup.Aceptar.Texto4')}
          </Typography>
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            disabled={!isValid}
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            {t('Signup.AceptarUnirse')}
          </StyledButton>
          {errorMessage && <Typography color="#d32f2f" variant="subtitle1" sx={{ marginTop: '-10px', fontWeight: 'bold' }}>{errorMessage}</Typography>}
          <Grid container sx={{ paddingTop: '45px' }}>
            <Grid
              item
              xs
            >
              <StyledTypography sx={{ fontSize: '18px' }}>
                {t('Signup.YaTienesCuenta')}
                {'  '}
                <MuiLink
                  onClick={changeAuth}
                  component="button"
                  color="secondary"
                  type="button"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t('login.IncioSesion')}
                </MuiLink>
              </StyledTypography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

Signup.propTypes = {
  onSignup: PropTypes.func.isRequired,
  changeAuth: PropTypes.func.isRequired,
};

Signup.defaultProps = {};

export default Signup;
