import PropTypes from 'prop-types';

function getId(children) {
  return children
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');
}

function Heading({
  children, id, as: Element, ...props
}) {
  const theId = id ?? getId(children);
  return (
    <Element id={theId} {...props}>
      {children}
    </Element>
  );
}

Heading.propTypes = {
  id: PropTypes.string,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
  as: 'h2',
  id: null,
};

export default Heading;
