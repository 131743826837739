import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

function LoadingPage({ loading, children }) {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      {loading ? <CircularProgress /> : <div>{ children }</div>}
    </Box>
  );
}

LoadingPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

LoadingPage.defaultProps = {};

export default LoadingPage;
