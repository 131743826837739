/* eslint-disable react/jsx-filename-extension */
import GianinaImage from '../assets/img/Gianina.jpeg';
import JoseUrena from '../assets/img/testimonials/joseUrena.png';
import ThomasBertsch from '../assets/img/testimonials/thomasBertsch.png';
import ChelsieAnderson from '../assets/img/testimonials/chelsieAnderson.png';

import amazonImage from '../assets/img/clients/amazon.png';
import GoqueImage from '../assets/img/clients/Goque.png';
import ForcecxImage from '../assets/img/clients/Forcecx.png';
import kaffaImage from '../assets/img/clients/kaffa.png';

import facebookImage from '../assets/img/social/facebook-logo.png';
import instagramImage from '../assets/img/social/instagram-logo.png';
import linkedinImage from '../assets/img/social/linkedin-logo.png';
import tiktokImage from '../assets/img/social/tiktok-logo.png';
import youtubeImage from '../assets/img/social/youtube-logo.png';
import whatsappImage from '../assets/img/social/whatsapp-logo.png';

import { ReactComponent as MentorIcon } from '../assets/icons/mentores.svg';
import { ReactComponent as ProfesionalesIcon } from '../assets/icons/profesionales.svg';
import { ReactComponent as VideoIcon } from '../assets/icons/video.svg';

import { ReactComponent as ClipboardIcon } from '../assets/icons/clipboard.svg';
import { ReactComponent as Computer1Icon } from '../assets/icons/computer1.svg';
import { ReactComponent as Computer2Icon } from '../assets/icons/computer2.svg';
import { ReactComponent as DoublecheckIcon } from '../assets/icons/doublecheck.svg';
import { ReactComponent as PaintingIcon } from '../assets/icons/painting.svg';
import { ReactComponent as SquareOfCirclesIcon } from '../assets/icons/squareOfCircles.svg';
import { ReactComponent as SquareOfSquaresIcon } from '../assets/icons/squareOfSquares.svg';
import { ReactComponent as TelephoneIcon } from '../assets/icons/telephone.svg';

export const statisticalData = [
  { icon: <MentorIcon />, number: '260+', description: 'GeneralData.statisticalData.Mentores' },
  { icon: <ProfesionalesIcon />, number: '3,000+', description: 'GeneralData.statisticalData.Profesionales' },
  { icon: <VideoIcon />, number: '1,000++', description: 'GeneralData.statisticalData.VideoContenido' },
];

export const businessBenefitsData = [
  { description: 'Business.DesbloqueaTitulo' },
  { description: 'Business.RetencionTitulo' },
  { description: 'Business.ConstruirTitulo' },
  { description: 'Business.RetencionClientesTitulo' },
];

export const profileServices = ['Revisar y actualizar CV', 'Revisar y actualizar LinkedIn',
  'Diagnóstico de perfil de empleabilidad', 'Recomendaciones de empleo', 'Simular entrevistas', 'Otros'];

export const areasButtons = [
  { id: 'software_development', icon: <Computer1Icon />, label: 'areasButtons.software_development' },
  { id: 'sales', icon: <TelephoneIcon />, label: 'areasButtons.sales' },
  { id: 'marketing', icon: <Computer2Icon />, label: 'areasButtons.marketing' },
  { id: 'finance', icon: <ClipboardIcon />, label: 'areasButtons.finance' },
  { id: 'management', icon: <Computer2Icon />, label: 'areasButtons.management' },
  { id: 'design', icon: <PaintingIcon />, label: 'areasButtons.design' },
  { id: 'operations', icon: <DoublecheckIcon />, label: 'areasButtons.operations' },
  { id: 'customer_service', icon: <SquareOfCirclesIcon />, label: 'areasButtons.customer_service' },
  { id: 'other', icon: <SquareOfSquaresIcon />, label: 'areasButtons.other' },
];

export const accordionData = [
  {
    key: 'panel1',
    summary: 'accordionData.titulo.p1',
    details: 'accordionData.cuerpo.p1',
  },
  {
    key: 'panel2',
    summary: 'accordionData.titulo.p2',
    details: 'accordionData.cuerpo.p2',
  },
  {
    key: 'panel3',
    summary: 'accordionData.titulo.p3',
    details: 'accordionData.cuerpo.p3',
  },
  {
    key: 'panel4',
    summary: 'accordionData.titulo.p4',
    details: 'accordionData.cuerpo.p4',
  },
  {
    key: 'panel5',
    summary: 'accordionData.titulo.p5',
    details: 'accordionData.cuerpo.p5',
  },
];

export const testimonialArrowInfo = [
  {
    name: 'Gianina Solano',
    subTitle: 'Product Manager',
    img: GianinaImage,
    rating: 5,
    title: 'Encontré el empleo que yo quería',
    quote: 'El mentor de Mensis me ayudó a conocerme como profesional, aprendí a demostrar mi valor para una empresa en mi Curriculum y durante  una entrevista, logré buscar un empleo para mí y no a adaptarme para el empleo.',
  },
  {
    name: 'José Ureña',
    subTitle: 'Director de proyectos',
    img: JoseUrena,
    rating: 5,
    title: 'Encontré empleo con un 30% de aumento en mi salario',
    quote: 'Gracias al acompañamiento de mi mentor en Mensis, logramos establecer una estrategia para buscar un mejor empleo, logré mapear a que empleos y empresas estaba aplicando, para darle seguimiento directamente con los empleadores a través de LinkedIn.',
  },
  {
    name: 'Thomas Bertsch',
    subTitle: 'Business Analyst',
    img: ThomasBertsch,
    rating: 5,
    title: 'Encontraron mis habilidades y me enseñaron a como mostrarlas',
    quote: 'Con el mentor de Mensis, logré conocer habilidades que no tenía tan claras en mi perfil, además aprendí como mostrarlas durante una entrevista, logré un nuevo empleo en menos de 1 mes.',
  },
  {
    name: 'Chelsie Anderson',
    subTitle: 'Business Developer',
    img: ChelsieAnderson,
    rating: 5,
    title: 'Perdí el miedo a responder preguntas durante una entrevista',
    quote: 'Siempre tuve muchas entrevistas, pero cuando llegaba a las entrevistas me ponía nerviosa y no respondia de la mejor forma. El mentor de Mensis, realizó más de 6 simulaciones de entrevista y conseguí un mejor empleo.',
  },
];

export const aboutMensis = [
  'Nuestro éxito llega cuando accedes a un Socio Reclutador de forma fácil y segura.',
  'Te escuchamos y a partir de ello te conectamos con el Socio Reclutador ideal, de acuerdo a tus necesidades y perfil.',
  'Nuestro socio reclutador te ayudará a sacar todo tu potencial para encontrar el empleo ideal.',
];

export const clients = [
  amazonImage, GoqueImage, ForcecxImage, kaffaImage,
];

export const socialMediaIcons = [
  {
    link: 'https://www.facebook.com/Mensis-113581956965750',
    text: 'Facebook image',
    images: facebookImage,
  },
  {
    link: 'https://www.instagram.com/mensis_mentor/?hl=en',
    text: 'Instagram image',
    images: instagramImage,
  },
  {
    link: 'https://www.youtube.com/channel/UC9UB9UDkumz1Hr7HCDBKYKw',
    text: 'Youtube image',
    images: youtubeImage,
  },
  {
    link: 'https://www.tiktok.com/@mensis_mentor',
    text: 'Tiktok image',
    images: tiktokImage,
  },
  {
    link: 'https://www.linkedin.com/company/mensis2020/mycompany/?viewAsMember=true',
    text: 'Linkedin image',
    images: linkedinImage,
  },
  {
    link: 'https://api.whatsapp.com/send/?phone=50687566313&text=¡Hola%21&type=phone_number&app_absent=0',
    text: 'Whatsapp image',
    images: whatsappImage,
  },
];

export const publicContentVideos = [
  {
    url: 'https://www.youtube.com/watch?v=jpu8BA_Wk-Y',
    title: 'Evelyn Gómez - Gerente General Goque Group',
  },
  {
    url: 'https://www.youtube.com/watch?v=eH1tQbV5OFc',
    title: 'Félix Gómez - Service Manager en Boston Consulting Group',
  },
  {
    url: 'https://www.youtube.com/watch?v=hX2EwWO3rBs',
    title: 'Ronald Fernández - Gestor de proyectos',
  },
  {
    url: 'https://www.youtube.com/watch?v=R4_Yg5Iy3rY',
    title: 'José Vargas - Analista de Datos',
  },
];

export const contentVideos = [
  {
    url: 'https://www.youtube.com/watch?v=fNoEaoFLj1s',
    title: 'Introducción a Mensis y comienza a cambiar tu futuro',
  },
  {
    url: 'https://www.youtube.com/watch?v=Ofi71tnV1hE',
    title: 'Hoja de ruta para encontrar un empleo de tu interés',
  },
  {
    url: 'https://www.youtube.com/watch?v=P8v939zOrvM',
    title: '¿Tu Curriculum te está perjudicando? ',
  },
  {
    url: 'https://www.youtube.com/watch?v=eH1tQbV5OFc',
    title: 'Félix Gómez - Service Manager en Boston Consulting Group',
  },
  {
    url: 'https://www.youtube.com/watch?v=AtwKnLmC5pE',
    title: 'Crecer en tu carrera nunca fue tan fácil',
  },
  {
    url: 'https://www.youtube.com/watch?v=hX2EwWO3rBs',
    title: 'Ronald Fernández - Gestor de proyectos',
  },
  {
    url: 'https://www.youtube.com/watch?v=jpu8BA_Wk-Y',
    title: 'Evelyn Gómez - Gerente General Goque Group',
  },
  {
    url: 'https://www.youtube.com/watch?v=R4_Yg5Iy3rY',
    title: 'José Vargas - Analista de Datos',
  },
];
