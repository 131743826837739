import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie } from '../../utils/cookiesUtils';
import { MensisToken } from '../../constants/authConstants';

const SERVER_URL = `${process.env.REACT_APP_MENSIS_API_URL}/clients/`;

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: (headers) => {
      const token = getCookie(MensisToken);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.href = '/bye';
      }
      return headers;
    },
  }),
  tagTypes: ['client'],
  endpoints: (build) => ({
    getCurrentUser: build.query({
      query: () => ({ url: 'v1/getCurrentClient', method: 'POST' }),
    }),
    getClientProfile: build.query({
      query: () => ({ url: 'v1/getClientProfile', method: 'GET' }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetClientProfileQuery,
  useLazyGetClientProfileQuery,
} = clientApi;
