import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton, InputAdornment,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import BasicLayout from '../components/layout/BasicLayout';
import theme from '../styles/theme';
import useDetectMobile from '../hooks/useDetectMobile';
import TextField from '../components/controllerFormFields/TextField';
import { resetPassword, verifyResetToken } from '../services/authService';
import AuthCard from '../components/cards/AuthCard/AuthCard';
import LoadingPage from '../components/loadingPage/LoadingPage';
import LoadingDialog from '../components/dialog/LoadingDialog';
import ga from '../AnalyticsEventTracker';

const StyledTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '20px',
});

const StyledButton = styled(Button)({
  borderRadius: '5px',
  fontSize: '24px',
  textTransform: 'inherit',
});

function StyledRequiredLabel() {
  const [t] = useTranslation('common');
  return (
    <span style={{ color: theme.palette.grey[500], fontSize: '0.7rem' }}>{t('ResetPassword.CampoRequerido')}</span>
  );
}

function ResetPassword() {
  const {
    control,
    watch,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validToken, setValidToken] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const password = watch('password');
  const [t] = useTranslation('common');
  const { windowWidth } = useDetectMobile();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleClickShowPasswordNew = () => setShowPasswordNew((show) => !show);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setLoading(true);
    ga('ResetPassword', 'Event', 'VerifyPasswordRecoveryToken');
    verifyResetToken(token)
      .then((response) => response.json())
      .then((tokenResponse) => {
        setLoading(false);
        if (tokenResponse.status === 200) {
          setValidToken(true);
          ga('ResetPassword', 'Event', 'SuccessfulVerifyPasswordRecoveryToken');
        } else {
          ga('ResetPassword', 'Event', 'FailedVerifyPasswordRecoveryToken');
          setValidToken(false);
        }
      });
  }, [token]);

  const onSubmit = (data) => {
    const body = {
      newPassword: data.password,
      resetToken: token,
    };
    ga('ResetPassword', 'Click', 'PasswordRecovery');
    setIsLoading(true);

    resetPassword(body)
      .then((response) => response.json())
      .then((authResponse) => {
        if (authResponse.status === 200) {
          ga('ResetPassword', 'Event', 'SuccessfulPasswordRecovery');
          setOpen(true);
        } else {
          ga('ResetPassword', 'Event', 'FailedPasswordRecovery');
          setErrorMessage(t('ResetPassword.Error'));
        }
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setOpen(false);
    navigate('/login');
  };

  return (
    <BasicLayout showBanner displayLinks>
      <Box sx={{
        my: 5,
        marginRight: windowWidth < 800 ? '10px' : '25%',
        marginLeft: windowWidth < 800 ? '10px' : '25%',
      }}
      >
        <LoadingPage loading={loading}>
          <Box>
            {validToken ? (
              <AuthCard>
                {isLoading && <LoadingDialog showModal={isLoading} text={t('ResetPassword.Guardando')} />}
                <StyledTypography sx={{ fontSize: '32px' }}>
                  {t('ResetPassword.Registar')}
                </StyledTypography>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  sx={{ mt: 5 }}
                >
                  <StyledTypography sx={{ weight: 'bold', size: '15px' }}>
                    {t('ResetPassword.NuevaContraseña')}
                    <StyledRequiredLabel />
                  </StyledTypography>
                  <TextField
                    name="password"
                    placeholder={t('ResetPassword.Contraseña')}
                    control={control}
                    type={showPasswordNew ? 'text' : 'password'}
                    rules={{
                      required: t('ResetPassword.ContraseñaNecesaria'),
                      maxLength: {
                        value: 30,
                        message: t('ResetPassword.LongitudContraseña'),
                      },
                      minLength: {
                        value: 7,
                        message: t('ResetPassword.LongitudContraseña'),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPasswordNew}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    autoFocus
                  />
                  <StyledTypography sx={{ weight: 'bold', size: '15px', mt: 2 }}>
                    {t('ResetPassword.Confirmar')}
                    <StyledRequiredLabel />
                  </StyledTypography>
                  <TextField
                    name="confirmPassword"
                    placeholder={t('ResetPassword.Contraseña')}
                    control={control}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    rules={{
                      required: t('ResetPassword.ContraseñaNecesaria'),
                      validate: {
                        positive: (v) => v === password || t('ResetPassword.Coincidir'),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <StyledButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={!isValid}
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    {t('ResetPassword.Recuperar')}
                  </StyledButton>
                  {errorMessage && <Typography color="#d32f2f" variant="subtitle1" sx={{ marginTop: '-10px', fontWeight: 'bold' }}>{errorMessage}</Typography>}
                </Box>
              </AuthCard>
            ) : <Typography>{t('ResetPassword.link')}</Typography>}
          </Box>
        </LoadingPage>
      </Box>
      <Footer />
      <Dialog
        onClose={closeModal}
        open={open}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ marginTop: '10px' }}>
            {t('ResetPassword.Actualizada')}
          </Typography>
        </DialogContent>
      </Dialog>
    </BasicLayout>
  );
}

ResetPassword.propTypes = {
};

ResetPassword.defaultProps = {
};

export default ResetPassword;
