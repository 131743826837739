import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material';
import theme from '../../styles/theme';

const StyledBoldTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  fontWeight: 900,
});

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
});

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none',
});

function PaymentSummary({ pricePlan, mentorName }) {
  const [description, setDescription] = useState(pricePlan.pricePlanDescription);

  useEffect(() => {
    if (mentorName && pricePlan.pricePlanDescription) {
      setDescription(pricePlan.pricePlanDescription.replace(/<MENTOR_NAME>/g, mentorName.trim()));
    }
  }, [mentorName, pricePlan.pricePlanDescription]);

  return (
    <TableContainer sx={{ marginTop: '40px', mx: 4, width: 'inherit' }}>
      {pricePlan && (
        <Table>
          <TableBody>
            <TableRow>
              <StyledTableCell size="small">
                <StyledBoldTypography color={theme.palette.primary.main}>
                  {pricePlan.pricePlanName}
                </StyledBoldTypography>
              </StyledTableCell>
              <StyledTableCell size="small" align="right">
                <StyledBoldTypography>
                  {pricePlan.pricePlanSymbol}
                  {pricePlan.pricePlanAmount.toLocaleString()}
                  {' '}
                  {pricePlan.pricePlanCurrency}
                </StyledBoldTypography>
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ mb: 5 }}>
              <StyledTableCell size="small" colSpan={2}>
                <StyledTypography color={theme.palette.text.secondary}>
                  {description}
                </StyledTypography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <StyledTypography>Subtotal</StyledTypography>
              </TableCell>
              <TableCell align="right">
                <StyledTypography>
                  {pricePlan.pricePlanSymbol}
                  {pricePlan.pricePlanAmount.toLocaleString()}
                  {' '}
                  {pricePlan.pricePlanCurrency}
                </StyledTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <StyledBoldTypography color={theme.palette.primary.main}>
                  Total
                </StyledBoldTypography>
              </TableCell>
              <TableCell align="right">
                <StyledBoldTypography>
                  {pricePlan.pricePlanSymbol}
                  {pricePlan.pricePlanAmount.toLocaleString()}
                  {' '}
                  {pricePlan.pricePlanCurrency}
                </StyledBoldTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

PaymentSummary.propTypes = {
  pricePlan: PropTypes.shape({
    pricePlanId: PropTypes.number.isRequired,
    pricePlanAmount: PropTypes.number,
    pricePlanCurrency: PropTypes.string,
    pricePlanSymbol: PropTypes.string,
    pricePlanName: PropTypes.string,
    pricePlanDescription: PropTypes.string,
    pricePlanOptions: PropTypes.arrayOf(PropTypes.string),
    pricePlanIsPopular: PropTypes.number,
  }).isRequired,
  mentorName: PropTypes.string,
};

PaymentSummary.defaultProps = {
  mentorName: null,
};

export default PaymentSummary;
