import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import './navbar.css';
import '@fontsource/montserrat';
import { Button, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  HomeOutlined as HomeOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  VideoLibraryOutlined as VideoLibraryOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
} from '@mui/icons-material';
import ga from '../../../AnalyticsEventTracker';
import { ReactComponent as LogoNav } from '../../../assets/img/logo-nav.svg';
import { AuthStates } from '../../../constants/authConstants';
import useAuth from '../../../hooks/useAuth';

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  transition: 'transform .2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  borderRadius: '5px',
});

const menuItems = [
  {
    id: 'home',
    ref: '/home',
    label: 'PrivateNavbar.menuItems.label1',
    icon: <HomeOutlinedIcon />,
  },
  {
    id: 'mentors',
    ref: '/mentors',
    label: 'PrivateNavbar.menuItems.label2',
    icon: <PeopleAltOutlinedIcon />,
  },
  {
    id: 'academy',
    ref: '/academy',
    label: 'PrivateNavbar.menuItems.label3',
    icon: <VideoLibraryOutlinedIcon />,
  },
  {
    id: 'profile',
    ref: '/profile',
    label: 'PrivateNavbar.menuItems.label4',
    icon: <AccountCircleOutlinedIcon />,
  },
];

function PrivateNavbar() {
  const [t] = useTranslation('common');
  const ref = useRef(this);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { authStatus } = useAuth();
  const [url, setUrl] = useState('');
  const location = useLocation();

  // destructuring pathname from location
  const { pathname } = location;

  // Javascript split method to get the name of the path in array
  const splitLocation = pathname.split('/');

  const handleClickSerMentor = () => {
    ga('Menu', 'Click', 'SerMentor');
    if (t('Idioma') === 'es') {
      setUrl('https://calendly.com/mensis/ser-socio-reclutador-en-mensis');
    } else {
      setUrl('https://calendly.com/mensis/become-a-mentor');
    }
    window.open(url, '_self');
  };

  const signInMenuItems = [
    {
      id: 'SerMentor',
      label: 'PrivateNavbar.ConviérteteMentor',
      type: 'button',
      onClick: handleClickSerMentor,
    },
  ];

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isNavExpanded && ref.current && !ref.current.contains(e.target)) {
        setIsNavExpanded(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isNavExpanded]);

  const isExpanded = (value) => (value ? 'navigation-menu expanded' : 'navigation-menu');

  return (
    <nav className="navigation" ref={ref}>
      <a href="/" className="brand-name logo">
        <LogoNav width={180} height={46} />
      </a>
      <button
        type="button"
        aria-label="hamburger menu"
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          style={{ marginLeft: '-2px', marginTop: '-2px' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          className="h-5 w-5"
          fill="white"
          height="28"
          width="28"
        >
          <path
            d="M23.5 4a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0 18a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0-9a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19z"
          />
        </svg>
      </button>
      <div className={isExpanded(isNavExpanded)}>
        <ul>
          {authStatus === AuthStates.LOGGED_IN && menuItems.map((item) => (
            <li
              key={item.id}
              className={
                (splitLocation[1] === item.id || (splitLocation[1] === '' && item.id === 'home'))
                  ? 'active'
                  : ''
              }
            >
              <a
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                  ga('Menu', 'Click', item.id);
                }}
                style={{ textAlign: 'center', paddingTop: '10px' }}
                href={item.ref}
              >
                {item.icon}
                <p style={{ margin: 0 }}>{t(item.label)}</p>
              </a>
            </li>
          ))}
          {signInMenuItems.map((item) => (
            <li key={item.id}>
              {item.type === 'button' && (
                <StyledButton
                  onClick={item.onClick}
                  color="secondary"
                  variant="contained"
                >
                  {t(item.label)}
                </StyledButton>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

PrivateNavbar.propTypes = {};

PrivateNavbar.defaultProps = {};

export default PrivateNavbar;
