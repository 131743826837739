import { useEffect, useRef, useState } from 'react';
import './navbar.css';
import '@fontsource/montserrat';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, FormControl, MenuItem, Select, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ReactComponent as LogoNav } from '../../../assets/img/logo-nav.svg';
import LogoWord from '../../../assets/img/worldgrid.png';
import ga from '../../../AnalyticsEventTracker';

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  transition: 'transform .2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  borderRadius: '5px',
});

function Navbar({ displayLinks }) {
  const [t, i18n] = useTranslation('common');
  const ref = useRef(this);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  // assigning location variable
  const location = useLocation();
  const navigate = useNavigate();

  // destructuring pathname from location
  const { pathname } = location;
  const menuItems = [
    { id: 'Personas', ref: '/#onboarding', label: t('Navbar.ParaPersonas') },
    {
      id: 'Empresas',
      ref: '/business',
      label: t('Navbar.ParaEmpresa'),
      specialClassName: true,
    },
    { id: 'Conocenos', ref: '/#services', label: t('Navbar.Conocenos') },
    { id: 'CasosDeExitto', ref: '/#testimonials', label: t('Navbar.CasosExito') },
  ];
  // Javascript split method to get the name of the path in array
  const splitLocation = pathname.split('/');

  const changeSelectedLanguage = (e) => {
    localStorage.setItem('internationalizationLanguage', e.target.value);
    setSelectedLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    setIsNavExpanded(!isNavExpanded);
  };

  const publicMenuItems = [
    {
      id: 'LogIn',
      label: t('Navbar.IniciarSesion'),
      redirect: '/login',
      variant: 'outlined',
    },
    {
      id: 'SignUp',
      label: t('Navbar.Registrarme'),
      redirect: '/signup',
      variant: 'contained',
    },
  ];
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      const containsLanguageValue = Object.entries(e.target.classList).find((item) => item[1] === 'MuiMenuItem-root') !== undefined;
      if (isNavExpanded && ref.current && !ref.current.contains(e.target)
        && !containsLanguageValue) {
        setIsNavExpanded(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isNavExpanded]);

  const isExpanded = (value) => (value ? 'navigation-menu expanded' : 'navigation-menu');

  return (
    <nav className="navigation" ref={ref}>
      <a href="/business" className="brand-name logo">
        <LogoNav width={180} height={46} />
      </a>
      <button
        type="button"
        aria-label="hamburger menu"
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          style={{ marginLeft: '-2px', marginTop: '-2px' }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          className="h-5 w-5"
          fill="white"
          height="28"
          width="28"
        >
          <path
            d="M23.5 4a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0 18a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19zm0-9a1.5 1.5 0 110 3h-19a1.5 1.5 0 110-3h19z"
          />
        </svg>
      </button>
      <div className={isExpanded(isNavExpanded)}>
        <ul>
          {displayLinks
            && menuItems.map((item) => (
              <li
                key={item.id}
                className={
                  item.specialClassName && splitLocation[1] === 'business'
                    ? 'active'
                    : ''
                }
              >
                <a
                  onClick={() => {
                    setIsNavExpanded(!isNavExpanded);
                    ga('Menu', 'Click', item.id);
                  }}
                  href={item.ref}
                >
                  {item.label}
                </a>
              </li>
            ))}
          {publicMenuItems.map((item) => (
            <li key={item.id}>
              <StyledButton
                variant={item.variant}
                color="secondary"
                onClick={() => {
                  ga('Menu', 'Click', item.id);
                  navigate(item.redirect, { replace: true });
                  if (splitLocation[1] === 'login' || splitLocation[1] === 'signup') {
                    window.location.reload(false);
                  }
                }}
              >
                {item.label}
              </StyledButton>
            </li>
          ))}

          <div style={{ marginRight: '10px', textAlign: 'center' }}>
            <img src={LogoWord} alt="Word" className="center" width={15} height={15} />
            <FormControl variant="standard" sx={{ maxWidth: 120 }}>
              <Select
                labelId="language-simple-select-standard-label"
                id="language-simple-select-standard"
                value={selectedLanguage}
                onChange={(e) => changeSelectedLanguage(e)}
                label="Language"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value="es">ES</MenuItem>
                <MenuItem value="en">EN</MenuItem>
              </Select>
            </FormControl>
          </div>
        </ul>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  displayLinks: PropTypes.bool,
};

Navbar.defaultProps = {
  displayLinks: true,
};

export default Navbar;
