import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie } from '../../utils/cookiesUtils';
import { MensisToken } from '../../constants/authConstants';

const SERVER_URL = `${process.env.REACT_APP_MENSIS_API_URL}/v1/`;

export const mentors = createApi({
  reducerPath: 'mentors',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: (headers) => {
      const token = getCookie(MensisToken);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.href = '/bye';
      }
      return headers;
    },
  }),
  tagTypes: ['mentors'],
  endpoints: (build) => ({
    getMentorsFilter: build.query({
      query: ({
        page = 0,
        size = 9,
        english = null,
        country = null,
        area = null,
        experience = null,
      }) => ({
        url: `mentors?page=${page}&size=${size}&${
          english ? `english=${english}&` : ''
        }${
          country ? `country=${country}&` : ''
        }${
          area ? `area=${area}&` : ''
        }${
          experience ? `experience=${experience}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMentorsFilterQuery,
} = mentors;
