import ReactGA from 'react-ga4';

const ga = (category = 'Home', action = 'Load', label = '') => ReactGA.event({
  action,
  category,
  label, // optional
  // value: 99, // optional, must be a number
  // nonInteraction: true, // optional, true/false
  // transport: "xhr", // optional, beacon/xhr/image
});

export const gtag = (...args) => ReactGA.gtag(...args);

export default ga;
