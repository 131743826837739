import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, styled, Button, Box,
} from '@mui/material';

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => !['numberLines', 'isTruncate'].includes(prop),
})(({ numberLines, isTruncate }) => ({
  fontFamily: 'Montserrat',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: isTruncate && numberLines,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-line',
}));

function TruncateText({
  text,
  numberLines,
  ...props
}) {
  const inputRef = useRef();
  const [isTruncate, setIsTruncate] = useState(true);
  const [initializeTruncate, setInitializeTruncate] = useState(false);

  useEffect(() => {
    const { scrollHeight, clientHeight } = inputRef.current;
    setIsTruncate(scrollHeight > clientHeight);
    setInitializeTruncate(scrollHeight > clientHeight);
  }, [inputRef]);

  const handleExpandText = () => {
    setIsTruncate(!isTruncate);
    setInitializeTruncate(true);
  };
  const [t] = useTranslation('common');
  return (
    <>
      <StyledTypography ref={inputRef} numberLines={numberLines} isTruncate={isTruncate} {...props}>
        {text}
      </StyledTypography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      >
        {initializeTruncate
        && (
        <Button
          sx={{ textTransform: 'none', fontFamily: 'Montserrat' }}
          variant="text"
          onClick={handleExpandText}
        >
          {isTruncate ? t('TruncareText.Vermas') : t('TruncareText.Vermenos') }
        </Button>
        )}
      </Box>
    </>
  );
}

TruncateText.propTypes = {
  text: PropTypes.string.isRequired,
  numberLines: PropTypes.number,
};

TruncateText.defaultProps = {
  numberLines: 8,
};

export default TruncateText;
