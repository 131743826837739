import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import './payment.css';
import PropTypes from 'prop-types';
import {
  Button, Card, Dialog, Grid, IconButton, Stack, styled, Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { createPayment } from '../../../services/paymentService';
import PaymentForm from './PaymentForm';
import theme from '../../../styles/theme';
import PaymentSummary from '../../tables/PaymentSummary';
import useDetectMobile from '../../../hooks/useDetectMobile';
import LoadingDialog from '../../dialog/LoadingDialog';
import ga from '../../../AnalyticsEventTracker';
import { ReactComponent as Logo515 } from '../../../assets/img/logo515.svg';
import { useGetCurrentUserQuery } from '../../../store/slices/clientApi';
import { selectedMentorSelector, selectedPricePackageSelector } from '../../../store/slices/clientOnboardingSlice';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
});

function Payment({
  onChange, onReturn, onLoading, stripePromise,
}) {
  const selectedPricePackage = useSelector(selectedPricePackageSelector);
  const { data: userData } = useGetCurrentUserQuery();
  const selectedMentor = useSelector(selectedMentorSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { isMobile } = useDetectMobile();

  useEffect(() => {
    if (selectedPricePackage && selectedMentor && userData) {
      setIsLoading(true);
      createPayment({
        pricePlanId: selectedPricePackage?.pricePlanId,
        clientId: userData.client.id,
        mentorName: selectedMentor.mentorName,
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
          setIsLoading(false);
        }).catch(() => {
          ga('OnBoarding', 'Event', 'Create_payment_error');
        });
    }

    return () => {
      setClientSecret(null);
    };
  }, [selectedPricePackage, selectedMentor, userData]);

  const options = {
    clientSecret,
  };
  const [t] = useTranslation('common');
  return (
    <Stack
      sx={{ marginTop: '10px' }}
      direction="column"
      ql="qa-ctnr-paymentStack"
    >
      <StyledTypography sx={{ fontSize: '20px', padding: '0 0 0 5px' }}>{t('Payment.Verifiar')}</StyledTypography>
      <Button
        variant="text"
        onClick={() => {
          ga('OnBoarding', 'Click', 'BackFromPayment');
          onReturn();
        }}
        sx={{
          borderRadius: '5px',
          fontFamily: 'Montserrat',
          textTransform: 'none',
          color: '#273B7C',
          backgroundColor: '#fff',
          width: '267px',
          padding: '0 0 0 5px',
          justifyContent: 'left',
          '&:hover': { backgroundColor: 'rgba(39, 59, 124, 0.04)' },
        }}
        data-testid="back"
      >
        {t('Payment.Regresar')}
      </Button>
      <Card sx={{ backgroundColor: theme.palette.cardsBackground.main, boxShadow: 'none', marginTop: '15px' }}>
        <Grid
          container
          spacing={5}
          ql="qa-ctnr-paymentForm"
        >
          {selectedPricePackage && selectedMentor && (
            <Grid item xs={12} md={6} sx={{ mt: 4 }}>
              {isLoading && <LoadingDialog showModal={isLoading} text={t('Payment.Cargando')} />}
              <Logo515 style={{ marginBottom: '10px', marginLeft: isMobile ? '0%' : '8%' }} width={46} height={46} />
              <StyledTypography color={theme.palette.text.secondary} sx={{ fontSize: '1rem', marginLeft: isMobile ? '0%' : '8%' }}>{t('Payment.PayToMensis')}</StyledTypography>
              <StyledTypography sx={{
                fontSize: '2.5rem', fontWeight: 900, mb: isMobile ? 0 : 5, marginLeft: isMobile ? '0%' : '8%',
              }}
              >
                {selectedPricePackage.pricePlanSymbol}
                {selectedPricePackage.pricePlanAmount.toLocaleString()}
                {' '}
                {selectedPricePackage.pricePlanCurrency}
              </StyledTypography>
              {isMobile
                ? <Button sx={{ fontFamily: 'Montserrat', textTransform: 'none' }} onClick={() => setOpenModal(true)}>{t('Payment.SeeDetails')}</Button>
                : (
                  <PaymentSummary
                    pricePlan={selectedPricePackage}
                    mentorName={selectedMentor.mentorName}
                  />
                )}
              <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenModal(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#273B7C',
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {selectedPricePackage && (
                  <PaymentSummary
                    pricePlan={selectedPricePackage}
                    mentorName={selectedMentor.mentorName}
                  />
                )}
              </Dialog>
            </Grid>
          )}
          { clientSecret && (
            <Grid item xs={12} md={6}>
              <Elements options={options} stripe={stripePromise}>
                <PaymentForm
                  onChange={onChange}
                  onReturn={onReturn}
                  onLoading={onLoading}
                />
              </Elements>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <footer className="App-Footer Footer">
              <div className="Footer-PoweredBy">
                <a
                  className="Link"
                  href="https://stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"
                  >
                    Powered by
                    {' '}
                    <svg
                      className="Footer-PoweredBy-Icon"
                      focusable="false"
                      width="33"
                      height="15"
                      role="img"
                      aria-labelledby="stripe-title"
                    >
                      <title id="stripe-title">Stripe</title>
                      <g fillRule="evenodd">
                        <path
                          fill="#646FDE"
                          d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"
                        />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
              <div className="Footer-Links">
                <a
                  className="Link"
                  href="https://stripe.com/checkout/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className="Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"
                  >
                    Terms
                  </span>
                </a>
                <a
                  className="Link"
                  href="https://stripe.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className="Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"
                  >
                    Privacy
                  </span>
                </a>
              </div>
            </footer>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
}

Payment.propTypes = {
  onChange: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  stripePromise: PropTypes.any.isRequired,
};

Payment.defaultProps = {};

export default Payment;
