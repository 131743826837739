import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Box, Button, styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SimpleMentorCard from './SimpleMentorCard';
import useDetectMobile from '../../../hooks/useDetectMobile';
import ga from '../../../AnalyticsEventTracker';

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  backgroundColor: '#273B7C',
  color: 'white',
  height: '50px',
  borderRadius: '5px',
  '&:hover': {
    color: '#273B7C',
    backgroundColor: '#B4C5FF',
    boxShadow: '2px 2px 10px #888888',
  },
  textTransform: 'none',
  marginTop: '20px',
  fontSize: '1rem',
  boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
});

function RecommendedMentorCard({
  mentorId,
  profilePhotoUrl,
  mentorName,
  mentorLastName,
  userName,
  currentPosition,
  liveCountry,
  mentorStars,
  servicesNumber,
  englishLevel,
  experienceYears,
  score,
  liveCountryId,
  showBackgroundColor,
  ...props
}) {
  const navigate = useNavigate();
  const { isMobile } = useDetectMobile();
  const [t] = useTranslation('common');
  return (
    <Box
      key={mentorId}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: isMobile ? '100%' : '300px',
        height: !isMobile ? '500px' : '100%',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
          backgroundColor: '#E9EBF2',
        },
      }}
      {...props}
    >
      <div style={{ padding: '10px' }}>
        <SimpleMentorCard
          mentorName={mentorName}
          englishLevel={englishLevel}
          currentPosition={currentPosition}
          mentorId={mentorId}
          experienceYears={experienceYears}
          liveCountry={liveCountry}
          liveCountryId={liveCountryId}
          mentorLastName={mentorLastName}
          userName={userName}
          mentorStars={mentorStars}
          profilePhotoUrl={profilePhotoUrl}
          score={score}
          servicesNumber={servicesNumber}
          avatarSize="120px"
          showBackgroundColor={showBackgroundColor}
        />
        <StyledButton
          sx={{ width: '100%' }}
          label={t('RecommendedMentorCard.Verperfil')}
          onClick={() => { ga('RecommendedMentor', 'Click', 'SeeProfile'); navigate(`/p/${userName}-${mentorId}`); }}
        >
          {t('RecommendedMentorCard.Verperfil')}
        </StyledButton>
      </div>
    </Box>
  );
}

RecommendedMentorCard.propTypes = {
  mentorId: PropTypes.number.isRequired,
  profilePhotoUrl: PropTypes.string,
  mentorName: PropTypes.string.isRequired,
  mentorLastName: PropTypes.string,
  userName: PropTypes.string.isRequired,
  currentPosition: PropTypes.string.isRequired,
  liveCountry: PropTypes.string.isRequired,
  liveCountryId: PropTypes.string,
  mentorStars: PropTypes.number,
  servicesNumber: PropTypes.number,
  englishLevel: PropTypes.string.isRequired,
  experienceYears: PropTypes.number.isRequired,
  score: PropTypes.string,
  showBackgroundColor: PropTypes.bool,
};

RecommendedMentorCard.defaultProps = {
  profilePhotoUrl: null,
  mentorLastName: null,
  liveCountryId: null,
  mentorStars: 0,
  servicesNumber: 0,
  score: '0.2',
  showBackgroundColor: true,
};

export default RecommendedMentorCard;
