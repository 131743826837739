import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, Grid, styled, Typography, Pagination, PaginationItem,
} from '@mui/material';
import {
  WorkspacePremium, Public,
} from '@mui/icons-material';
import TagsInput from 'react-tagsinput';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import theme from '../../../styles/theme';
import TruncateText from '../../text/TruncateText';
import './react-tagsinput.css';
import ProfileService from '../../profileServices/ProfileService';
import SimpleMentorCard from './SimpleMentorCard';
import { fetchMentorSkills } from '../../../services/fetchMentors';
import ga from '../../../AnalyticsEventTracker';

const WEB_URL = process.env.REACT_APP_MENSIS_WEB_URL;

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
});

const Next = React.forwardRef((props, ref) => <span {...props} ref={ref} style={{ color: '#273B7C' }}>→</span>);
const Prev = React.forwardRef((props, ref) => <span {...props} ref={ref} style={{ color: '#273B7C' }}>←</span>);

function MentorCard({
  mentorId,
  profilePhotoUrl,
  mentorName,
  mentorLastName,
  currentPosition,
  liveCountry,
  mentorStars,
  servicesNumber,
  englishLevel,
  experienceYears,
  score,
  profileDescription,
  showReturn,
  showNext,
  index,
  totalElements,
  handleChange,
  onClickAccept,
  onClickReturn,
  onClickNext,
  canWorkAreas,
  liveCountryId,
  clientJobArea,
  showPagination,
  userName,
  onProfileCopy,
  tiktokUrl,
  youtubeUrl,
  facebookUrl,
  linkedinUrl,
  instagramUrl,
  spotifyUrl,
  ...props
}) {
  const [services, setServices] = useState(null);
  const [t] = useTranslation('common');

  useEffect(() => {
    if (!services) {
      fetchMentorSkills(mentorId)
        .then((response) => response.json())
        .then((mentorSkills) => {
          if (mentorSkills && mentorSkills.status === 200 && mentorSkills.skills) {
            setServices(mentorSkills.skills);
          }
        }).catch(() => {
          ga('MentorCard', 'Event', 'GetMentorSkillsError');
        });
    }
  });

  function getMentorPublicProfileURL() {
    if (mentorId && userName) {
      return `${WEB_URL}/p/${userName}-${mentorId}`;
    }
    return null;
  }

  function processCanWorkAreas(clientArea, canWorkArea) {
    const areas = [];
    let indexAreas = 0;
    if (clientArea && clientArea.label) {
      const area = canWorkArea.find((elem) => clientArea.label
        .toLowerCase().includes(elem.toLowerCase()));
      if (area) {
        areas.push(`✓ ${area}`);
      }
      while (indexAreas < canWorkArea.length) {
        if (!clientArea.label.toLowerCase().includes(canWorkArea[indexAreas].toLowerCase())) {
          areas.push(canWorkArea[indexAreas]);
        }
        if (areas.length > 4) {
          areas.push(t('MentorCard.OthersAreas'));
          break;
        }
        indexAreas += 1;
      }
    } else {
      while (indexAreas < canWorkArea.length) {
        areas.push(canWorkArea[indexAreas]);
        if (areas.length > 4) {
          areas.push(t('MentorCard.OthersAreas'));
          break;
        }
        indexAreas += 1;
      }
    }
    return areas;
  }

  return (
    <Card id={mentorId} sx={{ backgroundColor: theme.palette.cardsBackground.main }} {...props}>
      <Grid container sx={{ mt: 5 }}>
        <Grid item xs={12} sm={12} md={5} lg={5} sx={{ px: 5 }}>
          <SimpleMentorCard
            mentorName={mentorName}
            englishLevel={englishLevel}
            currentPosition={currentPosition}
            mentorId={mentorId}
            experienceYears={experienceYears}
            liveCountry={liveCountry}
            liveCountryId={liveCountryId}
            mentorLastName={mentorLastName}
            mentorStars={mentorStars}
            profilePhotoUrl={profilePhotoUrl}
            score={score}
            servicesNumber={servicesNumber}
            tiktokUrl={tiktokUrl}
            youtubeUrl={youtubeUrl}
            facebookUrl={facebookUrl}
            linkedinUrl={linkedinUrl}
            instagramUrl={instagramUrl}
            spotifyUrl={spotifyUrl}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} sx={{ px: 5 }}>
          <Box sx={{
            display: 'flex', justifyContent: 'center', flexDirection: 'column',
          }}
          >
            {canWorkAreas && canWorkAreas.length > 0 && (
              <Grid container sx={{ alignItems: 'center', marginBottom: '10px' }}>
                <StyledTypography>
                  <WorkspacePremium sx={{ color: theme.palette.primary.main }} />
                  {t('MentorCard.Habilidades')}
                </StyledTypography>
                <span style={{ marginTop: '5px' }}>
                  <TagsInput
                    value={processCanWorkAreas(clientJobArea, canWorkAreas)}
                    disabled
                    inputProps={{ placeholder: '' }}
                  />
                </span>
              </Grid>
            )}
            {getMentorPublicProfileURL() && (
              <div style={{ wordWrap: 'break-word', marginBottom: '10px' }}>
                <StyledTypography sx={{ mt: 2 }}>
                  <Public sx={{ color: theme.palette.primary.main }} />
                  {' '}
                  {t('MentorCard.Perfil')}
                </StyledTypography>
                <a href={getMentorPublicProfileURL()} style={{ fontFamily: 'Montserrat' }}>
                  {getMentorPublicProfileURL()}
                </a>
                <CopyToClipboard
                  text={getMentorPublicProfileURL()}
                  onCopy={onProfileCopy}
                >
                  <ContentCopyIcon
                    style={{
                      marginLeft: '5px', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)', paddingBottom: '5px',
                    }}
                  />
                </CopyToClipboard>
              </div>
            )}
            {profileDescription && profileDescription.trim() && (
              <div>
                <StyledTypography sx={{ color: theme.palette.primary.main }}>
                  {t('MentorCard.AcercaDeMi')}
                </StyledTypography>
                <TruncateText text={profileDescription} numberLines={8} />
              </div>
            )}
            { !showPagination && services && (
              <div>
                <StyledTypography sx={{
                  mt: 2,
                  marginBottom: 1,
                  color: theme.palette.primary.main,
                }}
                >
                  {t('MentorCard.ServiciosCertificados')}
                </StyledTypography>
                <Grid container spacing={2}>
                  {services.map((skill) => (
                    <Grid
                      key={skill.skillId}
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      lg={6}
                      xl={6}
                    >
                      <ProfileService
                        serviceName={skill.title}
                        description={skill.description}
                        onClick={onClickAccept}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 5 }}>
        <Grid item xs={12} sm={12} md={5} lg={5} sx={{ px: 5 }} />
        <Grid item xs={12} sm={12} md={7} lg={7} sx={{ px: 5 }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              borderRadius: '20px',
              width: '100%',
              fontFamily: 'Montserrat',
              textTransform: 'none',
              backgroundColor: '#c7440d',
              '&:hover': {
                backgroundColor: '#f26f38',
              },
              marginBottom: showPagination ? '0px' : '10px',
            }}
            data-testid="mentor-connect"
            onClick={onClickAccept}
          >
            {t('MentorCard.Conectar')}
            {' '}
            {mentorName}
          </Button>
          {showPagination && (
            <Pagination
              count={totalElements}
              page={index + 1}
              onChange={handleChange}
              color="secondary"
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    next: Next,
                    previous: Prev,
                  }}
                  {...item}
                />
              )}
              sx={{
                marginTop: '10px',
                marginBottom: '5px',
                '& .MuiPaginationItem-root': {
                  fontFamily: 'Montserrat',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#c7440d',
                },
                '& .MuiPaginationItem-root.Mui-selected:hover': {
                  backgroundColor: '#f26f38',
                },
                float: 'right',
              }}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

MentorCard.propTypes = {
  mentorId: PropTypes.number.isRequired,
  profilePhotoUrl: PropTypes.string,
  mentorName: PropTypes.string.isRequired,
  mentorLastName: PropTypes.string,
  currentPosition: PropTypes.string.isRequired,
  liveCountry: PropTypes.string.isRequired,
  liveCountryId: PropTypes.string,
  mentorStars: PropTypes.number,
  servicesNumber: PropTypes.number,
  englishLevel: PropTypes.string.isRequired,
  experienceYears: PropTypes.number.isRequired,
  score: PropTypes.string,
  profileDescription: PropTypes.string.isRequired,
  canWorkAreas: PropTypes.array,
  index: PropTypes.number,
  totalElements: PropTypes.number,
  showReturn: PropTypes.bool,
  showNext: PropTypes.bool,
  handleChange: PropTypes.func,
  onClickAccept: PropTypes.func,
  onClickReturn: PropTypes.func,
  onClickNext: PropTypes.func,
  clientJobArea: PropTypes.any.isRequired,
  showPagination: PropTypes.bool,
  userName: PropTypes.string,
  onProfileCopy: PropTypes.func,
  tiktokUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  spotifyUrl: PropTypes.string,
};

MentorCard.defaultProps = {
  profilePhotoUrl: null,
  mentorLastName: null,
  liveCountryId: null,
  mentorStars: 0,
  servicesNumber: 0,
  canWorkAreas: null,
  index: null,
  totalElements: null,
  showReturn: false,
  showNext: false,
  score: '0.2',
  handleChange: () => {},
  onClickAccept: () => {},
  onClickReturn: () => {},
  onClickNext: () => {},
  showPagination: true,
  userName: '',
  onProfileCopy: () => {},
  tiktokUrl: null,
  youtubeUrl: null,
  facebookUrl: null,
  linkedinUrl: null,
  instagramUrl: null,
  spotifyUrl: null,
};

export default MentorCard;
