// Based on https://blog.openreplay.com/creating-a-table-of-content-widget-in-react/

import { styled } from '@mui/material';
import React from 'react';
import useHeadings from '../../hooks/useHeadings';
import useScrollSpy from '../../hooks/useScrollSpy';

const StyledDiv = styled('div')(() => ({
  overflowY: 'scroll',
  maxHeight: '90vh',
  '& h2': {
    fontSize: '1.25em',
  },
  '& ul': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  '& li': {
    margin: 0,
    padding: 0,
  },
  '& a': {
    display: 'block',
    padding: '0.1em 0.2em',
    marginBottom: '0.1em',
    borderRadius: '4px',
    color: '#333',
    textDecoration: 'none',
    transition: 'all 0.3s ease-out',
  },
  '& a :hover': {
    backgroundColor: '#eee',
  },
}));

function TableOfContent() {
  const { headings } = useHeadings();
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    { rootMargin: '0% 0% -25% 0%' },
  );

  return (
    <StyledDiv>
      <h2>Tabla de contenido</h2>
      <ul>
        {headings.map((heading) => (
          <li key={heading.id}>
            <a
              style={{
                marginLeft: `${heading.level - 2}em`,
                fontWeight: activeId === heading.id ? 'bold' : 'normal',
              }}
              href={`#${heading.id}`}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </StyledDiv>
  );
}

TableOfContent.propTypes = {
};

TableOfContent.defaultProps = {
};

export default TableOfContent;
