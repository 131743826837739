import { createTheme } from '@mui/material/';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
    ].join(','),
    h1: {
      fontFamily: 'Montserrat',
    },
    h2: {
      fontFamily: 'Montserrat',
    },
    h3: {
      fontFamily: 'Montserrat',
    },
    body1: {
      fontFamily: 'Montserrat',
    },
    body2: {
      fontFamily: 'Montserrat',
    },
    button: {
      fontFamily: 'Montserrat',
    },
    caption: {
      fontFamily: 'Montserrat',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#273B7C',
      light: '#d4edf7',
    },
    secondary: {
      main: '#f1652a',
      dark: '#c7440d',
      light: '#f26f38',
    },
    cardsBackground: {
      main: 'rgba(2, 2, 70, 0.02)',
    },
  },
});

export default theme;
