import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent, Dialog, IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../styles/theme';
import useDetectMobile from '../../../hooks/useDetectMobile';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => !['isPopular'].includes(prop) && !['isMobileMode'].includes(prop),
})(({ isPopular, isMobileMode }) => ({
  fontFamily: 'Montserrat',
  color: isPopular ? theme.palette.common.white : 'rgb(132, 129, 153)',
  backgroundColor: isPopular ? theme.palette.primary.main : theme.palette.common.white,
  borderRadius: '26px',
  marginRight: '8px',
  marginLeft: '5px',
  marginBottom: '10px',
  minHeight: isMobileMode ? '' : '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    marginTop: isPopular ? '-40px' : 'none',
  },
  boxShadow: isPopular ? '0px 25px 34px rgba(82, 67, 194, 0.295755)' : 'none',
}));

const MostPopularChip = styled('div')({
  fontFamily: 'Montserrat',
  width: '140px',
  height: '35px',
  borderRadius: '13.5px',
  backgroundColor: '#c7440d',
  border: 'none',
  color: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledBoldTypography = styled(Typography, {
  shouldForwardProp: (prop) => !['isPopular'].includes(prop),
})(({ isPopular }) => ({
  fontFamily: 'Montserrat',
  color: isPopular ? theme.palette.common.white : theme.palette.primary.main,
  fontWeight: 900,
}));

const StyledAcceptButton = styled(Button, {
  shouldForwardProp: (prop) => !['isPopular'].includes(prop),
})(({ isPopular }) => ({
  fontFamily: 'Montserrat',
  color: isPopular ? theme.palette.common.white : theme.palette.secondary.dark,
  backgroundColor: isPopular ? 'rgba(255, 255, 255, 0.1)' : 'rgba(199, 68, 13, 0.1)',
  height: '60px',
  borderRadius: '24px',
  border: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: isPopular ? 'rgba(255, 255, 255, 0.3)' : 'rgba(199, 68, 13, 0.3)',
    boxShadow: 'none',
  },
  marginBottom: '8px',
}));

function PricingCard({
  mentorName,
  pricePlanId,
  pricePlanAmount,
  pricePlanCurrency,
  pricePlanSymbol,
  pricePlanName,
  pricePlanDescription,
  pricePlanOptions,
  pricePlanIsPopular,
  pricePlanDiscount,
  onClickAccept,
  ...props
}) {
  const [description, setDescription] = useState(pricePlanDescription);
  const [priceBefore, setPriceBefore] = useState(0);
  const [priceDiscounted, setPriceDiscounted] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { isMobile } = useDetectMobile();

  useEffect(() => {
    if (pricePlanDiscount) {
      const { discountAmount, isPercentage, pricePlanAmountOriginal } = pricePlanDiscount;
      if (discountAmount > 0) {
        if (isPercentage > 0) {
          const temporalDiscountedPrice = (pricePlanAmountOriginal * discountAmount) / 100;
          if (pricePlanAmount === pricePlanAmountOriginal) {
            setPriceBefore(pricePlanAmountOriginal + temporalDiscountedPrice);
          } else {
            setPriceBefore(pricePlanAmountOriginal);
          }
          setPriceDiscounted(temporalDiscountedPrice);
        } else {
          if (pricePlanAmount === pricePlanAmountOriginal) {
            setPriceBefore(pricePlanAmountOriginal + discountAmount);
          } else {
            setPriceBefore(pricePlanAmountOriginal);
          }
          setPriceDiscounted(discountAmount);
        }
      }
    }
  }, [pricePlanAmount, pricePlanDiscount]);

  useEffect(() => {
    if (mentorName && pricePlanDescription) {
      const foo = pricePlanDescription.replace(/<MENTOR_NAME>/g, mentorName.trim());
      setDescription(foo);
    }
  }, [mentorName, pricePlanDescription]);

  const handleOnSelect = () => {
    onClickAccept(pricePlanId);
  };

  return (
    <StyledCard
      id={pricePlanId}
      isPopular={pricePlanIsPopular}
      isMobileMode={isMobile}
      {...props}
    >
      <CardContent sx={{ mx: 3 }}>
        <Box sx={{
          display: 'flex', justifyContent: 'right', mb: 3, height: '20px',
        }}
        >
          {pricePlanIsPopular > 0 && <MostPopularChip>Más popular</MostPopularChip>}
        </Box>
        <Stack>
          <p id="losinfo">
            <span style={{
              fontSize: '26px',
              fontWeight: 900,
              fontFamily: 'Montserrat',
              color: pricePlanIsPopular ? theme.palette.common.white : 'rgb(35, 29, 79)',
            }}
            >
              {pricePlanSymbol}
              {pricePlanAmount.toLocaleString()}
              {' '}
              {pricePlanCurrency}
            </span>
            <span style={{ fontSize: '17px' }}>
              {'   '}
              /pago único
            </span>
          </p>
          {pricePlanDiscount && priceBefore > 0 && (
          <>
            <Typography variant="body2" sx={{ fontSize: '0.75rem', fontFamily: 'Montserrat', textDecoration: 'line-through' }}>
              Antes
              {' '}
              {pricePlanSymbol}
              {priceBefore.toLocaleString()}
              {' '}
              {pricePlanCurrency}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.75rem', mb: 2, fontFamily: 'Montserrat' }}>
              Ahorras
              {' '}
              {pricePlanSymbol}
              {priceDiscounted.toLocaleString()}
              {' '}
              {pricePlanCurrency}
              {' '}
            </Typography>
          </>
          )}
          <StyledBoldTypography isPopular={pricePlanIsPopular}>
            {pricePlanName}
          </StyledBoldTypography>
          <Typography variant="body2" sx={{ fontSize: '0.75rem', mb: 2, fontFamily: 'Montserrat' }}>{description}</Typography>
          {isMobile ? <Button sx={{ fontFamily: 'Montserrat', textTransform: 'none', color: pricePlanIsPopular ? theme.palette.common.white : theme.palette.primary.main }} onClick={() => setOpenModal(true)}>Ver detalles</Button> : (
            <Stack>
              {pricePlanOptions.map((item) => (
                <Box sx={{ mt: 2 }} key={`priceCard_${pricePlanId}_${item}`}>
                  <Typography
                    sx={{
                      fontSize: '0.77rem',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    <CheckCircleOutlineIcon />
                    {' '}
                    {item}
                  </Typography>
                </Box>
              ))}
            </Stack>
          )}
          <Dialog open={openModal} onClose={() => setOpenModal(false)}>
            <Stack margin="25px">
              <IconButton
                aria-label="close"
                onClick={() => setOpenModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: '#273B7C',
                }}
              >
                <CloseIcon />
              </IconButton>
              <StyledBoldTypography>
                {pricePlanName}
              </StyledBoldTypography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.75rem', mb: 2, fontFamily: 'Montserrat', marginTop: '10px',
                }}
              >
                {description}
              </Typography>
              <Stack>
                {pricePlanOptions.map((item) => (
                  <Box sx={{ mt: 2 }} key={`priceCard_${pricePlanId}_${item}`}>
                    <Typography
                      sx={{
                        fontSize: '0.77rem',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      <CheckCircleOutlineIcon />
                      {' '}
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Dialog>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', mx: 3 }}>
        <StyledAcceptButton isPopular={pricePlanIsPopular} onClick={handleOnSelect} fullWidth size="large">
          Elegir plan
        </StyledAcceptButton>
      </CardActions>
    </StyledCard>
  );
}

PricingCard.propTypes = {
  mentorName: PropTypes.string,
  pricePlanId: PropTypes.number.isRequired,
  pricePlanAmount: PropTypes.number,
  pricePlanCurrency: PropTypes.string,
  pricePlanSymbol: PropTypes.string,
  pricePlanName: PropTypes.string,
  pricePlanDescription: PropTypes.string,
  pricePlanOptions: PropTypes.arrayOf(PropTypes.string),
  pricePlanIsPopular: PropTypes.number,
  pricePlanDiscount: PropTypes.shape({
    discountAmount: PropTypes.number,
    isPercentage: PropTypes.number,
    pricePlanAmountOriginal: PropTypes.number,
  }),
  onClickAccept: PropTypes.func,
};

PricingCard.defaultProps = {
  mentorName: null,
  pricePlanAmount: 0,
  pricePlanCurrency: 'USD',
  pricePlanSymbol: '$',
  pricePlanName: '',
  pricePlanDescription: '',
  pricePlanOptions: [],
  pricePlanIsPopular: 0,
  pricePlanDiscount: {
    discountAmount: 0, isPercentage: 0, isDiscount: 0, pricePlanAmountOriginal: 0,
  },
  onClickAccept: () => { /* By default, this event could be empty */ },
};

export default PricingCard;
