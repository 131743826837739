import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ga from '../../AnalyticsEventTracker';
import CalendlyField from './CalendlyField';

function CalendarField({
  calendlyURL, onChange, onReturn, showBackButton, ...props
}) {
  const onSubmit = (data) => {
    ga('OnBoarding', 'Click', 'Siguiente_Calendly');
    onChange(data);
  };

  const onReturnClick = () => {
    onReturn();
  };
  const [t] = useTranslation('common');
  return (
    <Stack
      direction="column"
      ql="qa-ctnr-resetpassword"
      {...props}
    >
      <CalendlyField
        onFinish={onSubmit}
        calendlyURL={calendlyURL}
        {...props}
      />
      <div style={{ alignSelf: 'flex-end', marginTop: '40px' }}>
        {onReturn && showBackButton && (
        <Button
          variant="contained"
          onClick={() => {
            ga('OnBoarding', 'Click', 'Anterior_Calendly');
            onReturnClick();
          }}
          sx={{
            borderRadius: '20px',
            margin: '5px',
            fontFamily: 'Montserrat',
            textTransform: 'none',
            color: '#273B7C',
            backgroundColor: '#fff',
            '&:hover': { backgroundColor: 'rgba(39, 59, 124, 0.04)' },
          }}
          data-testid="back"
        >
          {t('ClientOnboarding.onboardingSteps.Atras')}
        </Button>
        )}
      </div>
    </Stack>
  );
}

CalendarField.propTypes = {
  calendlyURL: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
};

CalendarField.defaultProps = {
  showBackButton: true,
};

export default CalendarField;
