import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, Grid,
  IconButton, Link as MuiLink,
  styled,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import Footer from '../components/footer/Footer';
import BasicLayout from '../components/layout/BasicLayout';
import theme from '../styles/theme';
import useDetectMobile from '../hooks/useDetectMobile';
import { validateEmail } from '../common/Util';
import TextField from '../components/controllerFormFields/TextField';
import { forgotPassword } from '../services/authService';
import AuthCard from '../components/cards/AuthCard/AuthCard';
import LoadingDialog from '../components/dialog/LoadingDialog';
import ga from '../AnalyticsEventTracker';

const StyledTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '20px',
});

const StyledButton = styled(Button)({
  borderRadius: '5px',
  fontSize: '24px',
  textTransform: 'inherit',
});

function ForgotPassword() {
  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });
  const [t] = useTranslation('common');
  const [open, setOpen] = useState(false);
  const { windowWidth } = useDetectMobile();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = ({ email }) => {
    setErrorMessage(null);
    setIsLoading(true);
    ga('ForgotPassword', 'Click', 'PasswordReset');
    forgotPassword(email, i18next.language)
      .then((response) => response.json())
      .then((forgotResponse) => {
        if (forgotResponse.status === 200) {
          setOpen(true);
          ga('ForgotPassword', 'Event', 'SuccessfulPasswordReset');
        } else {
          setErrorMessage(t('ForgotPassword.Mensaje'));
          ga('ForgotPassword', 'Event', 'FailedPasswordReset');
        }
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setOpen(false);
    navigate('/login');
  };

  return (
    <BasicLayout showBanner displayLinks>
      <Box sx={{
        my: 5,
        marginRight: windowWidth < 800 ? '10px' : '25%',
        marginLeft: windowWidth < 800 ? '10px' : '25%',
      }}
      >
        <AuthCard>
          {isLoading && <LoadingDialog showModal={isLoading} text="Buscando ..." />}
          <StyledTypography sx={{ fontSize: '32px' }}>
            {t('ForgotPassword.Olvidécontraseña')}
          </StyledTypography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 5 }}
          >
            <StyledTypography>
              {t('login.Correoelectrónico')}
            </StyledTypography>
            <TextField
              control={control}
              fullWidth
              placeholder={t('login.Direccióncorreo')}
              rules={{ required: t('Signup.DcorreoN'), validate: { email: (v) => validateEmail(v) || t('Signup.EmailInvalido') } }}
              name="email"
              autoFocus
            />
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={!isValid}
              sx={{
                mt: 3,
                mb: 2,
              }}
            >
              {t('ForgotPassword.Recuperar')}
            </StyledButton>
            {errorMessage && <Typography color="#d32f2f" variant="subtitle1" sx={{ marginTop: '-10px', fontWeight: 'bold' }}>{errorMessage}</Typography>}
            <Grid container sx={{ paddingTop: '45px' }}>
              <Grid
                item
                xs
              >
                <StyledTypography sx={{ fontSize: '18px' }}>
                  {t('ForgotPassword.AunNotienesCuenta')}
                  {'  '}
                  <MuiLink
                    onClick={() => { ga('ForgotPassword', 'Click', 'Signup'); navigate('/signup'); }}
                    component="button"
                    color="secondary"
                    type="button"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {t('login.Registrate')}
                  </MuiLink>
                </StyledTypography>
              </Grid>
            </Grid>
          </Box>
        </AuthCard>
      </Box>
      <Footer />
      <Dialog
        onClose={closeModal}
        open={open}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ marginTop: '10px' }}>
            {t('ForgotPassword.RecuperarMensaje')}
          </Typography>
        </DialogContent>
      </Dialog>
    </BasicLayout>
  );
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
