import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Box, styled, Typography,
} from '@mui/material';
import {
  WorkspacePremium,
  BusinessCenter as BusinessIcon,
  Payments as PaymentIcon,
  Numbers as NumbersIcon,
  CalendarMonth as CalendarMonthIcon,
  AddLink as AddLinkIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import theme from '../../../styles/theme';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  marginTop: '5px',
});

function SimpleMentoringCard({
  mentorId,
  mentorPhoto,
  mentorName,
  mentorLastName,
  mentorUserName,
  packageName,
  packagePrice,
  packageCurrency,
  demonstrationStartTime,
  demonstrationMeetingLink,
  paymentId,
  avatarSize,
  ...props
}) {
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      {...props}
    >
      <Box sx={{
        borderRadius: '15px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        padding: '5px',
      }}
      >
        <Avatar
          onClick={() => navigate(`/p/${mentorUserName}-${mentorId}`)}
          src={mentorPhoto}
          sx={{
            width: avatarSize, height: avatarSize, marginTop: '5px', cursor: 'pointer',
          }}
        />
        <StyledTypography sx={{ mt: 1, textAlign: 'center', color: theme.palette.primary.main }}>
          <WorkspacePremium />
          <b>{mentorName}</b>
          {' '}
          <b>{mentorLastName}</b>
        </StyledTypography>
      </Box>
      <Box sx={{
        borderRadius: '15px',
        marginTop: '5px',
        padding: '5px',
      }}
      >
        {packageName && (
          <StyledTypography>
            <BusinessIcon sx={{ color: theme.palette.primary.main }} />
            {` ${t('SimpleMentoringCard.Paquete')}${packageName}`}
          </StyledTypography>
        )}
        {demonstrationStartTime && (
          <StyledTypography>
            <CalendarMonthIcon sx={{ color: theme.palette.primary.main }} />
            {` ${t('SimpleMentoringCard.Fecha')}`}
            {moment.utc(demonstrationStartTime).locale('es').local().format('DD-MM-YYYY, h:mm:ss a')}
          </StyledTypography>
        )}
        {demonstrationMeetingLink && (
          <StyledTypography>
            <AddLinkIcon sx={{ color: theme.palette.primary.main }} />
            {` ${t('SimpleMentoringCard.EnlaceLlamada')}`}
            <a target="_blank" href={demonstrationMeetingLink} style={{ textDecoration: 'underline' }} rel="noreferrer">{t('SimpleMentoringCard.Aqui')}</a>
          </StyledTypography>
        )}
        {packagePrice != null && (
          <StyledTypography>
            <PaymentIcon sx={{ color: theme.palette.primary.main }} />
            {` ${t('SimpleMentoringCard.Precio')} ${packagePrice.toLocaleString()} ${packageCurrency}`}
          </StyledTypography>
        )}
        {paymentId && (
          <StyledTypography>
            <NumbersIcon sx={{ color: theme.palette.primary.main }} />
            {` 0100${paymentId}`}
          </StyledTypography>
        )}
      </Box>
    </Box>
  );
}

SimpleMentoringCard.propTypes = {
  mentorPhoto: PropTypes.string.isRequired,
  mentorName: PropTypes.string.isRequired,
  mentorLastName: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
  packageCurrency: PropTypes.string.isRequired,
  mentorId: PropTypes.number.isRequired,
  mentorUserName: PropTypes.string.isRequired,
  packagePrice: PropTypes.number,
  demonstrationStartTime: PropTypes.string,
  demonstrationMeetingLink: PropTypes.string,
  paymentId: PropTypes.number,
  avatarSize: PropTypes.string,
};

SimpleMentoringCard.defaultProps = {
  packagePrice: null,
  paymentId: null,
  demonstrationStartTime: null,
  demonstrationMeetingLink: null,
  avatarSize: '50px',
};

export default SimpleMentoringCard;
