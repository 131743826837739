import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import SelectMultipleField from '../formFields/SelectMultipleField';
import SelectSimpleField from '../formFields/SelectSimpleField';

function Select({
  name, control, label, rules, other, otherPlaceholderTextField,
  otherSelectedByDefault, options, isSelectMultiple, ...props
}) {
  const [otherAlreadyAdded, setOtherAlreadyAdded] = useState(false);

  const addValues = (value) => {
    if (otherSelectedByDefault && !otherAlreadyAdded && value && ((isSelectMultiple && value[0] && value[0].id && value[0].id === 'other') || (!isSelectMultiple && value.id && value.id === 'other'))) {
      setOtherAlreadyAdded(true);
    }

    if (otherSelectedByDefault && !otherAlreadyAdded) {
      if (!isSelectMultiple) {
        return 'other';
      }
      return ['other'];
    }

    if (!isSelectMultiple) {
      if (value && value.id) {
        return value.id;
      }
      return value;
    }

    return value?.map((item) => item.id);
  };
  const [t] = useTranslation('common');
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        if (isSelectMultiple) {
          return (
            <SelectMultipleField
              label={t(label)}
              id={name}
              value={addValues(value)}
              other={other}
              otherPlaceholderTextField={otherPlaceholderTextField}
              options={options}
              onChange={onChange}
              {...props}
            />
          );
        }
        return (
          <SelectSimpleField
            label={t(label)}
            id={name}
            value={addValues(value)}
            other={other}
            otherPlaceholderTextField={otherPlaceholderTextField}
            options={options}
            onChange={onChange}
            {...props}
          />
        );
      }}
    />
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  other: PropTypes.bool,
  otherPlaceholderTextField: PropTypes.string,
  otherSelectedByDefault: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  isSelectMultiple: PropTypes.bool,
};

Select.defaultProps = {
  label: null,
  rules: null,
  other: false,
  otherSelectedByDefault: false,
  otherPlaceholderTextField: 'Escriba aquí',
  isSelectMultiple: true,
};

export default Select;
