import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ga from '../../AnalyticsEventTracker';
import MentorCardField from '../formFields/MentorCardField';
import { setMessage, unsetMessage } from '../../store/slices/loadingDialogMessageSlice';
import fetchPricingPackages from '../../services/pricePackageService';
import {
  updatePricePackages, selectMentor, questionsAndResponsesSelector, mentorsSelector,
} from '../../store/slices/clientOnboardingSlice';
import registerDataLogV1 from '../../services/dataLogService';
import useAuth from '../../hooks/useAuth';
import { AuthStates } from '../../constants/authConstants';
import useDetectMobile from '../../hooks/useDetectMobile';

function GalileoStep({
  onChange, onReturn, isServerError, showPagination, ...props
}) {
  const [t] = useTranslation('common');
  const questionsAndResponses = useSelector(questionsAndResponsesSelector);
  const mentors = useSelector(mentorsSelector);
  const dispatch = useDispatch();
  const { isMobile } = useDetectMobile();
  const { authStatus } = useAuth();

  const onSubmit = (mentorId) => {
    ga('OnBoarding', 'Click', 'Siguiente_Galileo');
    const selectedMentorTemp = mentors.find(
      (mentor) => mentor.mentorId === mentorId,
    );

    if (authStatus === AuthStates.LOGGED_IN) {
      registerDataLogV1({
        event: 'SELECTED_MENTOR',
        newData: `${JSON.stringify(selectedMentorTemp)}`,
        description: isMobile ? 'From phone' : 'From tablet or computer',
      }).then((response) => response.json());
    }

    onChange();
    if (selectedMentorTemp) {
      let packageTypeName = 'Employability';
      if (!selectedMentorTemp.canWorkAreas) {
        packageTypeName = 'General';
      }
      dispatch(selectMentor(selectedMentorTemp));
      dispatch(setMessage(t('ClientOnboarding.onboardingSteps.Cargando')));
      fetchPricingPackages(selectedMentorTemp.liveCountryId, packageTypeName, t('Idioma'))
        .then((response) => response.json())
        .then((pricingPackageData) => {
          if (pricingPackageData.status === 200) {
            if (
              pricingPackageData.packages
              && pricingPackageData.packages.length > 0
            ) {
              ga('OnBoarding', 'Event', 'PricingPackagesSuccessful');
              dispatch(updatePricePackages(pricingPackageData.packages));
            } else {
              ga('OnBoarding', 'Event', 'PricingPackagesNotAvailable');
            }
          } else {
            ga('OnBoarding', 'Event', 'PricingPackagesNotOK');
            isServerError(true);
          }
          dispatch(unsetMessage());
        })
        .catch(() => {
          ga('OnBoarding', 'Event', 'PricingPackageFailed');
          isServerError(true);
          dispatch(unsetMessage());
        });
    }
  };

  const onReturnClick = () => {
    onReturn();
  };
  return (
    <Stack
      sx={{ marginTop: '10px', marginBottom: '40px' }}
      direction="column"
      ql="qa-ctnr-resetpassword"
      {...props}
    >
      <MentorCardField
        id="Galigeo TalentMatch"
        options={mentors}
        onFinish={onSubmit}
        onChange={onChange}
        showPagination={showPagination}
        clientJobArea={(questionsAndResponses && questionsAndResponses.desiredEmploymentArea) ? questionsAndResponses.desiredEmploymentArea : { id: '', label: '' }}
        {...props}
      />
      {showPagination && (
        <div style={{ alignSelf: 'flex-end', marginTop: '40px' }}>
          <Button
            variant="contained"
            onClick={() => {
              ga('OnBoarding', 'Click', 'Anterior_Galileo');
              onReturnClick();
            }}
            sx={{
              borderRadius: '20px',
              margin: '5px',
              fontFamily: 'Montserrat',
              textTransform: 'none',
              color: '#273B7C',
              backgroundColor: '#fff',
              '&:hover': { backgroundColor: 'rgba(39, 59, 124, 0.04)' },
            }}
            data-testid="back"
          >
            {t('ClientOnboarding.onboardingSteps.Atras')}
          </Button>
        </div>
      )}
    </Stack>
  );
}

GalileoStep.propTypes = {
  isServerError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onReturn: PropTypes.func,
  showPagination: PropTypes.bool,
};

GalileoStep.defaultProps = {
  onReturn: null,
  showPagination: true,
};

export default GalileoStep;
