import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Card, CardContent, styled, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CrossArrowIcon } from '../../assets/icons/crossArrow.svg';
import theme from '../../styles/theme';
import useDetectMobile from '../../hooks/useDetectMobile';

const StyledCard = styled(Card)(() => ({
  backgroundColor: 'rgba(39, 59, 124, 0.18)',
  borderRadius: '5px',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(39,59,124,0.32)',
    boxShadow: '2px 2px 10px #888888',
    color: 'rgba(31,46,97,0.85)',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  fontSize: '2rem',
}));

function FindMentorButton({ onClick, margin }) {
  const { isMobile } = useDetectMobile();
  const [t] = useTranslation('common');
  return (
    <StyledCard
      onClick={onClick}
      elevation={3}
      sx={{
        cursor: 'pointer',
        p: 1,
        marginRight: isMobile ? '0px' : margin,
        marginLeft: isMobile ? '0px' : margin,
        padding: 0,
      }}
    >
      <CardContent sx={{ textAlign: 'center', marginTop: isMobile ? '0px' : '30px', marginBottom: isMobile ? '0px' : '30px' }}>
        <div style={{ marginTop: '10px' }}><CrossArrowIcon /></div>
        <StyledTypography>
          {t('FindMentorButton.Descubrir')}
        </StyledTypography>
      </CardContent>
    </StyledCard>
  );
}

FindMentorButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  margin: PropTypes.string,
};

FindMentorButton.defaultProps = {
  margin: '20%',
};

export default FindMentorButton;
