import { useTranslation } from 'react-i18next';
import ContentWithIndex from '../components/contentWithIndex/ContentWithIndex';
import Heading from '../components/contentWithIndex/Heading';
import BasicLayout from '../components/layout/BasicLayout';
import { AuthStates } from '../constants/authConstants';
import useAuth from '../hooks/useAuth';

const WEB_URL = process.env.REACT_APP_MENSIS_WEB_URL;

function TermsData() {
  const [t] = useTranslation('common');
  return (
    <>
      <h3>{t('Terms.Titulo')}</h3>
      <p>
        {t('Terms.Cuerpo.p1')}
        {' '}
        <a href="mailto:adrian.diaz@mensismentor.com">adrian.diaz@mensismentor.com</a>
        {' '}
        {t('Terms.Cuerpo.p2')}
        {' '}
        <a href={WEB_URL}>www.mensismentor.com</a>
        {' '}
        {t('Terms.Cuerpo.p3')}
      </p>
      <p>
        {t('Terms.Cuerpo.p4')}
      </p>

      <Heading as="h2">{t('Terms.GLOSARIO.Titulo')}</Heading>
      <ul>
        <li>
          <b>{t('Terms.GLOSARIO.Contraseña.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.Contraseña.Cuerpo')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.CuentaUsuario.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.CuentaUsuario.Cuerpo')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.DatoPersonal.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.DataPersonal.Cuerpo')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.LosServicios.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.LosServicios.Cuerpo')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.SitioWeb.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.SitioWeb.Cuerpo.p1')}
          {' '}
          <a href={WEB_URL}>https://mensismentor.com</a>
          {' '}
          {t('Terms.GLOSARIO.SitioWeb.Cuerpo.p2')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.Miembro.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.Miembro.Cuerpo')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.Usuario.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.Usuario.Cuerpo')}
        </li>
        <li>
          <b>{t('Terms.GLOSARIO.Visitante.Titulo')}</b>
          {' '}
          {t('Terms.GLOSARIO.Visitante.Cuerpo')}
        </li>
      </ul>
      <br />
      <Heading as="h2">{t('Terms.GeneralidadesTitulo')}</Heading>
      <p>
        {t('Terms.GeneralidadesCuerpo')}
      </p>
      <Heading as="h3">{t('Terms.Generalidades.AcercaDeMensis')}</Heading>
      <p>
        {t('Terms.Generalidades.AcercaDeMensisCuerpo.p1')}
      </p>
      <p>
        {t('Terms.Generalidades.AcercaDeMensisCuerpo.p2')}
      </p>
      <p>
        {t('Terms.Generalidades.AcercaDeMensisCuerpo.p3')}
      </p>
      <Heading as="h3">{t('Terms.Generalidades.Consentimiento')}</Heading>
      <p>
        {t('Terms.Generalidades.ConsentimientoCuerpo.p1')}
      </p>
      <p>
        {t('Terms.Generalidades.ConsentimientoCuerpo.p2')}
      </p>
      <p>
        {t('Terms.Generalidades.ConsentimientoCuerpo.p3')}
      </p>
      <p>
        <a href="/privacy">
          {t('Terms.Generalidades.ConsentimientoCuerpo.p4')}
        </a>
      </p>
      <p>
        {' '}
        {t('Terms.Generalidades.ConsentimientoCuerpo.p5')}
      </p>
      <p>
        {' '}
        {t('Terms.Generalidades.ConsentimientoCuerpo.p6')}
      </p>
      <Heading as="h3">{t('Terms.Generalidades.MiembrosVisitantes')}</Heading>
      <p>
        {t('Terms.Generalidades.MiembrosVisitantesCuerpo.p1')}
      </p>
      <p>
        {t('Terms.Generalidades.MiembrosVisitantesCuerpo.p2')}
      </p>
      <Heading as="h3">{t('Terms.Generalidades.Requisitos')}</Heading>
      <p>
        {t('Terms.Generalidades.RequisitosCuerpo.p1')}
      </p>
      <ul>
        <li>
          {t('Terms.Generalidades.RequisitosItem.1')}
        </li>
        <li>
          {t('Terms.Generalidades.RequisitosItem.2')}
        </li>
        <li>
          {t('Terms.Generalidades.RequisitosItem.3')}
        </li>
      </ul>
      <br />
      <p>
        {t('Terms.Generalidades.RequisitosCuerpo.p2')}
      </p>

      <Heading as="h2">
        {t('Terms.DerechosObligacionesTitulo')}
      </Heading>
      <Heading as="h3">{t('Terms.DerechosObligaciones.ServiciosTitulo')}</Heading>
      <p>
        {t('Terms.DerechosObligaciones.ServiciosCuerpo.p1')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.ServiciosCuerpo.p2')}
      </p>
      <Heading as="h3">
        {t('Terms.DerechosObligaciones.Licencia')}
      </Heading>
      <p>
        {t('Terms.DerechosObligaciones.LicenciaCuerpo.p1')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.LicenciaCuerpo.p2')}
      </p>
      <ul>
        <li>
          {t('Terms.DerechosObligaciones.LicenciaItem.1')}
        </li>
        <li>
          {t('Terms.DerechosObligaciones.LicenciaItem.2')}
        </li>
        <li>
          {t('Terms.DerechosObligaciones.LicenciaItem.3')}
        </li>
        <li>
          {t('Terms.DerechosObligaciones.LicenciaItem.4')}
          {' '}
        </li>
      </ul>
      <br />
      <Heading as="h3">{t('Terms.DerechosObligaciones.Disponibilidad')}</Heading>
      <p>
        {t('Terms.DerechosObligaciones.DisponibilidadCuerpo.p1')}
        {' '}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.DisponibilidadCuerpo.p2')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.DisponibilidadCuerpo.p3')}
        {' '}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.DisponibilidadCuerpo.p4')}
      </p>
      <Heading as="h3">{t('Terms.DerechosObligaciones.ContenidosLicitos')}</Heading>
      <p>
        {t('Terms.DerechosObligaciones.ContenidosLicitosCuerpo.p1')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.ContenidosLicitosCuerpo.p2')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.ContenidosLicitosCuerpo.p3')}
      </p>
      <Heading as="h3">{t('Terms.DerechosObligaciones.PropiedadIntelectual')}</Heading>
      <p>
        {t('Terms.DerechosObligaciones.PropiedadIntelectualCuerpo.p1')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.PropiedadIntelectualCuerpo.p2')}
      </p>
      <p>
        {t('Terms.DerechosObligaciones.PropiedadIntelectualCuerpo.p3')}
      </p>
      <Heading as="h3">{t('Terms.DerechosObligaciones.EnlacesExternos')}</Heading>
      <p>
        {t('Terms.DerechosObligaciones.EnlacesExternosCuerpo')}
      </p>

      <Heading as="h2">
        {t('Terms.TratamientoDatosPersonalesTitulo')}
      </Heading>
      <p>
        {t('Terms.TratamientoDatosPersonalesCuerpo.p1')}
        {' '}
        <a href="/privacy">
          {t('Terms.TratamientoDatosPersonalesCuerpo.p2')}
        </a>
        {t('Terms.TratamientoDatosPersonalesCuerpo.p3')}
      </p>
      <p>
        {t('Terms.TratamientoDatosPersonalesCuerpo.p4')}
        {' '}
      </p>
      <Heading as="h3">{t('Terms.TratamientoDatosPersonales.Veracidad')}</Heading>
      <p>
        {t('Terms.TratamientoDatosPersonales.VeracidadCuerpo')}
      </p>

      <Heading as="h2">{t('Terms.SeguridadTitulo')}</Heading>
      <Heading as="h3">{t('Terms.Seguridad.Credenciales')}</Heading>
      <p>
        {t('Terms.Seguridad.CredencialesCuerpo')}
      </p>
      <Heading as="h3">{t('Terms.Seguridad.SeguridadSitioWeb')}</Heading>
      <p>
        {t('Terms.Seguridad.SeguridadSitioWebCuerpo.p1')}
      </p>
      <p>
        {t('Terms.Seguridad.SeguridadSitioWebCuerpo.p2')}
      </p>
      <Heading as="h3">{t('Terms.Seguridad.AceptacionRiesgos')}</Heading>
      <p>
        {t('Terms.Seguridad.AceptacionRiesgosCuerpo')}
      </p>

      <Heading as="h2">{t('Terms.Jurisdiccion')}</Heading>
      <p>
        {t('Terms.JurisdiccionCuerpo')}
      </p>
      <Heading as="h2">{t('Terms.ModificacionTerminos')}</Heading>
      <p>
        {t('Terms.ModificacionTerminosCuerpo.p1')}
      </p>
      <p>
        {t('Terms.ModificacionTerminosCuerpo.p2')}
      </p>
      <p>
        {t('Terms.ModificacionTerminosCuerpo.p3')}
        {' '}
        <a href="tel:+50687566313">
          +506 8756 6313
        </a>
        {' '}
        {t('Terms.ModificacionTerminosCuerpo.p4')}
        {' '}
        <a href="mailto:info@mensismentor.com">info@mensismentor.com</a>
        .
      </p>
    </>
  );
}

function Terms() {
  const { authStatus } = useAuth();

  return (
    <BasicLayout displayLinks={authStatus !== AuthStates.LOGGED_IN}>
      <ContentWithIndex>
        <TermsData />
      </ContentWithIndex>
    </BasicLayout>
  );
}

Terms.propTypes = {};

Terms.defaultProps = {};

export default Terms;
