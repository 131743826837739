import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import {
  Check as CheckIcon,
  ThreePOutlined as ThreePOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';
import theme from '../../../styles/theme';

const StyledAcceptButton = styled(Button)(() => ({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '16px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.dark,
  height: '42px',
  width: '230px',
  borderRadius: '7px',
  textTransform: 'none',
  marginBottom: '8px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const StyledBox = styled(Box)({
  fontFamily: 'Montserrat',
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '16px',
  textTransform: 'none',
  textAlign: 'left',
  color: theme.palette.common.black,
});

function SimplePricingCard({
  pricePlanId,
  pricePlanAmount,
  pricePlanCurrency,
  pricePlanSymbol,
  pricePlanOptions,
  pricePlanDiscount,
  onClickAccept,
  ...props
}) {
  const [priceBefore, setPriceBefore] = useState(0);
  const [priceDiscounted, setPriceDiscounted] = useState(0);

  useEffect(() => {
    if (pricePlanDiscount && pricePlanDiscount.discountAmount > 0) {
      const { discountAmount, isPercentage, pricePlanAmountOriginal } = pricePlanDiscount;
      if (isPercentage > 0) {
        const temporalDiscountedPrice = (pricePlanAmountOriginal * discountAmount) / 100;
        if (pricePlanAmount === pricePlanAmountOriginal) {
          setPriceBefore(pricePlanAmountOriginal + temporalDiscountedPrice);
        } else {
          setPriceBefore(pricePlanAmountOriginal);
        }
        setPriceDiscounted(temporalDiscountedPrice);
      } else {
        if (pricePlanAmount === pricePlanAmountOriginal) {
          setPriceBefore(pricePlanAmountOriginal + discountAmount);
        } else {
          setPriceBefore(pricePlanAmountOriginal);
        }
        setPriceDiscounted(discountAmount);
      }
    }
  }, [pricePlanAmount, pricePlanDiscount]);

  const handleOnSelect = () => {
    onClickAccept(pricePlanId);
  };
  const [t] = useTranslation('common');
  return (
    <StyledBox>
      <Stack {...props}>
        <Typography
          style={{
            fontSize: '36px',
            fontWeight: 800,
            fontFamily: 'Montserrat',
            color: 'rgba(40, 167, 69, 1)',
            lineHeight: '43.88px',
          }}
        >
          {pricePlanSymbol}
          {pricePlanAmount.toLocaleString()}
          {' '}
          {pricePlanCurrency}
        </Typography>
        {pricePlanDiscount && priceBefore > 0 && (
        <>
          <StyledTypography variant="body2" sx={{ textDecoration: 'line-through' }}>
            {t('SimplePricingCard.Antes')}
            {' '}
            {pricePlanSymbol}
            {priceBefore.toLocaleString()}
            {' '}
            {pricePlanCurrency}
          </StyledTypography>
          <StyledTypography variant="body2" sx={{ mb: 2 }}>
            {t('SimplePricingCard.Ahorras')}
            {' '}
            {pricePlanSymbol}
            {priceDiscounted.toLocaleString()}
            {' '}
            {pricePlanCurrency}
            {' '}
          </StyledTypography>
        </>
        )}
        <Stack>
          {pricePlanOptions.map((item) => (
            <StyledTypography
              sx={{
                mt: 2,
              }}
              key={`priceCard_${pricePlanId}_${item}`}
            >
              <CheckIcon sx={{ mx: 1 }} />
              {' '}
              {item}
            </StyledTypography>
          ))}
          <Box display="flex" alignItems="left" sx={{ mt: 5 }}>
            <ThreePOutlinedIcon fontSize="large" sx={{ mr: 2, color: theme.palette.primary.main }} />
            <StyledTypography>
              {t('SimplePricingCard.Conversartunecesidad')}
            </StyledTypography>
          </Box>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', mt: 5 }}
        >
          <StyledAcceptButton onClick={handleOnSelect} fullWidth size="large">
            {t('SimplePricingCard.EscogerPlan')}
          </StyledAcceptButton>
        </Box>
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Box display="flex" alignItems="left" sx={{ mt: 2 }} disableRipple>
        <LockOutlinedIcon fontSize="large" sx={{ mr: 2, mt: 2, color: theme.palette.primary.main }} />
        <Stack>
          <StyledTypography sx={{
            mt: 2,
            textAlign: 'left',
            color: theme.palette.primary.main,
            fontSize: '16px',
            fontWeight: 800,
          }}
          >
            {t('SimplePricingCard.Proteccion')}
          </StyledTypography>
          <StyledTypography sx={{ mt: 2, textAlign: 'left' }}>
            {t('SimplePricingCard.Cobroservicio')}
          </StyledTypography>
        </Stack>
      </Box>
    </StyledBox>
  );
}

SimplePricingCard.propTypes = {
  pricePlanId: PropTypes.number.isRequired,
  pricePlanAmount: PropTypes.number,
  pricePlanCurrency: PropTypes.string,
  pricePlanSymbol: PropTypes.string,
  pricePlanOptions: PropTypes.arrayOf(PropTypes.string),
  pricePlanDiscount: PropTypes.shape({
    discountAmount: PropTypes.number,
    isPercentage: PropTypes.number,
    pricePlanAmountOriginal: PropTypes.number,
  }),
  onClickAccept: PropTypes.func,
};

SimplePricingCard.defaultProps = {
  pricePlanAmount: 0,
  pricePlanCurrency: 'USD',
  pricePlanSymbol: '$',
  pricePlanOptions: [],
  pricePlanDiscount: {
    discountAmount: 0, isPercentage: 0, isDiscount: 0, pricePlanAmountOriginal: 0,
  },
  onClickAccept: () => { /* By default, this event could be empty */ },
};

export default SimplePricingCard;
