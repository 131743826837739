/* eslint-disable max-len */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  css,
  DialogContent, DialogContentText, Slide, useMediaQuery, useTheme,
} from '@mui/material';

const Transition = React.forwardRef((props, ref) => <Slide direction="right" ref={ref} {...props} />);

function LoadingDialog({
  showModal, text,
}) {
  const styles = {
    root: css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: white;
    `,
    circularProgress: css`
      @keyframes changeColor {
        12.5% {
          color: #FF0000;
        }
        25% {
          color: #FFA500;
        }
        37.5% {
          color: #FFFF00;
        }
        50% {
          color: #7FFF00;
        }
        62.5% {
          color: #00FFFF;
        }
        75% {
          color: #0000FF;
        }
        87.5% {
          color: #9932CC;
        }
        100% {
          color: #FF1493;
        }
      }
      animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite, changeColor 2s linear infinite;
    `,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{ backgroundColor: '#8fa7f58c' }}
    >
      <DialogContent style={{
        backgroundColor: 'rgba(255,255,255,0.62)', display: 'table-cell', justifyContent: 'center', textAlign: 'center',
      }}
      >
        <CircularProgress
          thickness={4}
          size={isMobile ? 75 : 100}
          disableShrink
          css={styles.circularProgress}
        />
        {text && text.trim() && (
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{
            fontFamily: 'Montserrat', fontSize: '1.2rem', color: '#273B7C', paddingBottom: '10px',
          }}
        >
          <b>{text}</b>
        </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

LoadingDialog.propTypes = {
  showModal: PropTypes.bool,
  text: PropTypes.string,
};

LoadingDialog.defaultProps = {
  showModal: false,
  text: 'Cargando ...',
};

export default LoadingDialog;
