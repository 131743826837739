import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

/**
 * Primary UI component for user interaction
 */

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  transition: 'transform .2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  borderRadius: '5px',
});

function MensisButton({ label, onClick, color }) {
  return (
    <StyledButton variant="contained" sx={{ backgroundColor: color, '&:hover': { backgroundColor: color } }} onClick={onClick}>{label}</StyledButton>
  );
}

MensisButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

MensisButton.defaultProps = {
  color: 'var(--secondary-logo)',
};

export default MensisButton;
