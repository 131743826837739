import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ga from '../AnalyticsEventTracker';
import CompletedOnBoardingDialog from '../components/dialog/CompletedOnBoardingDialog';
import UserHome from './UserHome';
import useAuth from '../hooks/useAuth';
import PublicHome from './PublicHome';
import { resetOnboarding } from '../store/slices/clientOnboardingSlice';

function Home() {
  const [
    showFinalOnBoardingModal, setShowFinalOnBoardingModal] = useState(false);
  const { state: routerState } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getSessionToken } = useAuth();

  useEffect(() => {
    if (routerState && routerState.showSuccessOnboardingModal) {
      setShowFinalOnBoardingModal(routerState.showSuccessOnboardingModal);
    }
  }, [routerState]);

  return (
    <span>
      {showFinalOnBoardingModal && (
        <CompletedOnBoardingDialog
          showModal={showFinalOnBoardingModal}
          onClose={() => {
            ga('Modal', 'Click', 'CloseCompletedOnBoardingDialog');
            setShowFinalOnBoardingModal(false);
            dispatch(resetOnboarding());
            navigate('/', { state: { showSuccessOnboardingModal: false } });
          }}
        />
      )}
      {getSessionToken() !== null ? <UserHome /> : <PublicHome />}
    </span>
  );
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
