import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ComplexButtonField from '../formFields/ComplexButtonField';

function ButtonGroup({
  name, control, label, rules, other, otherPlaceholderTextField, options, ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <ComplexButtonField
          label={label}
          id={name}
          value={value ? value.id : null}
          other={other}
          options={options}
          onChange={onChange}
          otherPlaceholderTextField={otherPlaceholderTextField}
          {...props}
        />
      )}
    />
  );
}

ButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  other: PropTypes.bool,
  otherPlaceholderTextField: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string || PropTypes.element,
  })).isRequired,
};

ButtonGroup.defaultProps = {
  label: null,
  rules: null,
  other: false,
  otherPlaceholderTextField: 'Escriba aquí',
};

export default ButtonGroup;
