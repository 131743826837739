import { setOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;
const serviceRoute = '/logs';

const registerDataLogV1 = (body) => fetch(
  `${SERVER_URL}${serviceRoute}/v1/logRegister`,
  { ...setOptions('POST'), body: JSON.stringify(body) },
);

export default registerDataLogV1;
