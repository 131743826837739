/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useEffect, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button,
  DialogContent, DialogTitle, Grid, IconButton, Slide, styled, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { socialMediaIcons } from '../../data/GeneralData';
import { onboardingAnswersSelector } from '../../store/slices/onboardingAnswersSlice';

const Transition = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  transition: 'transform .2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  borderRadius: '5px',
});

function CompletedOnBoardingDialog({
  showModal, onClose,
}) {
  const { data } = useSelector(onboardingAnswersSelector);
  useEffect(() => {
    if (data === null || data === undefined) onClose();
  }, [data, onClose]);
  const [t] = useTranslation('common');
  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      onClose={onClose}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{ backgroundColor: '#8fa7f58c' }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#273B7C',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Image src="/favicon.ico" width="20%" style={{ marginLeft: '42%' }} />
        <Typography
          variant="h3"
          sx={{
            m: 5, textAlign: 'center', fontSize: '2rem', color: '#273B7C', margin: 0, marginBottom: '10px',
          }}
        >
          ¡
          {data?.response?.demonstrationDetails?.clientName.trim()}
          {t('CompletedOnBoardingDialog.textouno')}
        </Typography>
        {data && data.response && data?.response?.paymentId !== 0 && (
        <p
          style={{
            marginLeft: '5%',
            marginRight: '5%',
            fontFamily: 'Montserrat',
          }}
        >
          {t('CompletedOnBoardingDialog.textodos')}
          {' '}
          <b>
            {' 0100'}
            {data.response.paymentId}
          </b>
          {t('CompletedOnBoardingDialog.textotres')}
        </p>
        )}
        {!data?.response?.paymentId && (
          <p
            style={{
              marginLeft: '5%',
              marginRight: '5%',
              fontFamily: 'Montserrat',
            }}
          >
            {t('CompletedOnBoardingDialog.textocuatro')}
          </p>
        )}
        <p
          style={{
            marginLeft: '5%',
            marginRight: '5%',
            fontFamily: 'Montserrat',
          }}
        >
          {data?.changeJobReasons?.label && (
          <>
            <b>{t('CompletedOnBoardingDialog.textocinco')}</b>
            {data?.changeJobReasons?.label}
            <br />
          </>
          )}
          {data?.response?.demonstrationDetails.demonstrationTimeUTC && (
          <>
            <b>{t('CompletedOnBoardingDialog.textoseis')}</b>
            {moment.utc(data?.response?.demonstrationDetails.demonstrationTimeUTC).locale('es').local().format('DD-MM-YYYY, h:mm:ss a')}
            <br />
          </>
          )}
          {data?.favoriteEmploymentModality && data?.favoriteEmploymentModality.length > 0 && (
          <>
            <b>{t('CompletedOnBoardingDialog.textosiete')}</b>
            {data?.favoriteEmploymentModality.map((modalityItem, index) => {
              let modalityResult = ' ';
              if (index > 0) modalityResult += '- ';
              modalityResult += `${modalityItem.label} `;
              return modalityResult;
            })}
            <br />
          </>
          )}

          {data?.appliedEmployments?.label && (
          <>
            <b>{t('CompletedOnBoardingDialog.textoocho')}</b>
            {data?.appliedEmployments?.label}
            <br />
          </>
          )}
          {data?.englishLevel?.label && (
          <>
            <b>{t('CompletedOnBoardingDialog.textonueve')}</b>
            {data?.englishLevel?.label}
          </>
          )}
        </p>
        <Grid sx={{ textAlign: 'center' }}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            {t('CompletedOnBoardingDialog.textodiez')}
          </StyledButton>
        </Grid>
        <div style={{ paddingRight: '15%', paddingLeft: '15%' }}>
          <Grid container style={{ marginTop: '20px', textAlign: 'center' }}>
            {socialMediaIcons.map((socialMedia) => (
              <Grid key={socialMedia.text} sx={{ padding: '5px' }} item xs={4} sm={2} md={2} lg={2}>
                <a
                  href={socialMedia.link}
                >
                  <Image
                    alt={socialMedia.text}
                    src={socialMedia.images}
                    width="50px"
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

CompletedOnBoardingDialog.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

CompletedOnBoardingDialog.defaultProps = {
  showModal: false,
};

export default CompletedOnBoardingDialog;
