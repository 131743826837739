import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {
  Box, Button, Container, Grid, Stack, Typography, styled, Card,
} from '@mui/material';
import {
  Tune as TuneIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import PrivateBasicLayout from '../components/layout/PrivateBasicLayout';
import {
  countryList, englishLevelList, workAreaList,
} from '../data/QuestionsData';
import RecommendedMentorCard from '../components/cards/MentorCard/RecommendedMentorCard';
import { useGetMentorsFilterQuery } from '../store/slices/mentorsApi';
import useDetectMobile from '../hooks/useDetectMobile';
import { desktopMentorPageSize, mobileMentorPageSize } from '../constants/queryConstants';
import {
  countrySelector,
  englishLevelSelector,
  pageSelector,
  categorySelector,
  changeCountry,
  changeCategory,
  changeEnglishLevel,
  nextPage,
  prevPage,
  resetFilters,
  changePage,
} from '../store/slices/mentorsPageSlice';
import SelectFilter from '../components/formFields/SelectFilter';
import ga from '../AnalyticsEventTracker';
import Footer from '../components/footer/Footer';
import mentorsHome from '../assets/img/mentorsHome.png';

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  backgroundColor: '#273B7C',
  color: 'white',
  marginLeft: '20px',
  textTransform: 'none',
  '&:hover': {
    color: '#273B7C',
    backgroundColor: '#B4C5FF',
    boxShadow: '2px 2px 10px #888888',
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontFamily: 'Montserrat',
  color: '#273b7c',
  fontSize: '22px',
  marginLeft: '20px',
  marginTop: '10px',
  [theme.breakpoints.up('md')]: {
    margin: '50px',
  },
}));

function Mentors() {
  const [t] = useTranslation('common');
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(0);
  const [displayList, setDisplayList] = useState([]);

  const country = useSelector(countrySelector);
  const category = useSelector(categorySelector);
  const englishLevel = useSelector(englishLevelSelector);
  const page = useSelector(pageSelector);

  const { data: mentorsList } = useGetMentorsFilterQuery(
    {
      page,
      size: isMobile ? mobileMentorPageSize : desktopMentorPageSize,
      english: englishLevel,
      country,
      area: category,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    const totalItems = mentorsList?.totalItems || 0;
    const pageSize = isMobile ? mobileMentorPageSize : desktopMentorPageSize;
    const newTotalPages = Math.ceil(totalItems / pageSize);
    setTotalPages(newTotalPages);
    if (page > newTotalPages) {
      dispatch(changePage(0));
    }
  }, [isMobile, page, mentorsList?.totalItems, totalPages, dispatch]);

  useEffect(() => {
    if (mentorsList?.mentors && mentorsList?.mentors.length > 0) {
      const newList = [...mentorsList.mentors];
      for (let i = newList.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [newList[i], newList[j]] = [newList[j], newList[i]];
      }
      setDisplayList(newList);
      ga('Mentors', 'Event', `FilterFoundMentors_${country}_${category}_${englishLevel}`);
    } else {
      setDisplayList([]);
      ga(
        'Mentors',
        'Event',
        `FilterNotFoundMentors_${country}_${category}_${englishLevel}`,
      );
    }
  }, [mentorsList, category, country, englishLevel]);

  const handleClearFilter = () => {
    dispatch(resetFilters());
    ga('Mentors', 'Event', 'ClearFilter');
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      dispatch(nextPage());
      ga('Mentors', 'Event', 'NextPage');
    }
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    if (page > 0) {
      dispatch(prevPage());
      ga('Mentors', 'Event', 'PrevPage');
    }
    window.scrollTo(0, 0);
  };

  return (
    <PrivateBasicLayout showBanner displayLinks>
      <Box sx={{ width: '100wv', backgroundColor: '#273B7C0D', py: isMobile ? 0 : 2 }}>
        <Container>
          <Grid
            container
            mt={{ xs: 10, sm: 10, md: 2 }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                gutterBottom
                sx={{
                  color: '#273b7c', fontSize: '22px', marginTop: isMobile ? '0px' : '15px',
                }}
              >
                <b>
                  {t('Mentors.Titulo_1')}
                  {' '}
                  {t('Mentors.Titulo_2')}
                </b>
              </Typography>
              {!isMobile && (
                <Typography gutterBottom variant="h2" sx={{ color: '#273b7c', fontSize: '16px' }}>
                  {t('Mentors.Cuerpo_1')}
                  {' '}
                  {t('Mentors.Cuerpo_2')}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Card sx={{ boxShadow: 0, backgroundColor: 'transparent', textAlign: 'center' }}>
                <img width={isMobile ? '80%' : '50%'} src={mentorsHome} alt="Sketch Mensis team" />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container sx={{ marginTop: '20px' }}>
        <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c' }}>{t('Mentors.Titulo_3')}</h2>
        <p style={{ fontFamily: 'Montserrat', fontSize: '22px !important' }}>
          {t('Mentors.Cuerpo_3')}
          {' '}
          {t('Mentors.Cuerpo_4')}
        </p>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1} sx={{ mt: 4 }} style={{ marginBottom: '30px' }}>
          <Typography gutterBottom sx={{ fontFamily: 'Montserrat', fontSize: '18px' }}>
            <TuneIcon />
            {' '}
            {t('Mentors.Filtrar')}
          </Typography>
          <SelectFilter id="country_filter" label={t('Mentors.País')} options={countryList} value={country} onChange={(e) => { dispatch(changePage(0)); dispatch(changeCountry(e)); }} onEmpty={() => dispatch(changeCountry(''))} />
          <SelectFilter id="category_filter" label={t('Mentors.Categoría')} options={workAreaList} value={category} onChange={(e) => { dispatch(changePage(0)); dispatch(changeCategory(e)); }} onEmpty={() => dispatch(changeCategory(''))} />
          <SelectFilter id="english_level_filter" label={t('Mentors.NivelInglés')} options={englishLevelList} value={englishLevel} onChange={(e) => { dispatch(changePage(0)); dispatch(changeEnglishLevel(e)); }} onEmpty={() => dispatch(changeEnglishLevel(''))} />
          { (country || category || englishLevel) && (
            <Button variant="outlined" size="small" onClick={handleClearFilter} sx={{ textTransform: 'none' }}>
              {t('Mentors.Limpiar')}
            </Button>
          )}
        </Stack>
        <Grid container spacing={4}>
          {displayList.length > 0 ? displayList.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={item.mentorId}>
              <RecommendedMentorCard {...item} showBackgroundColor={false} />
            </Grid>
          )) : (
            <StyledTypography>
              {t('Mentors.Disculpa')}
              <br />
              <br />
              {t('Mentors.Despedida')}
            </StyledTypography>
          )}
        </Grid>
        <Grid container justifyContent="center" spacing={2} sx={{ my: 8, width: '100%' }}>
          <Grid item xs={0} sm={0} md={4} lg={4} xl={4} />
          <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
            {page > 0 && (<Button variant="outlined" onClick={handlePrevPage}><ArrowBackIcon /></Button>)}
            {totalPages > 1 && page < totalPages - 1 && (
            <StyledButton onClick={handleNextPage}>
              {t('Mentors.Siguiente')}
              {' '}
              <ArrowForwardIcon />
            </StyledButton>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={4} lg={4} xl={4} sx={{ textAlign: 'right' }}>
            {totalPages > 1 && (
            <Typography>
              {t('Mentors.Pagina')}
              {' '}
              {page + 1}
              {' '}
              {t('Mentors.De')}
              {' '}
              {totalPages}
            </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer showImportantLinks={false} />
    </PrivateBasicLayout>
  );
}

Mentors.propTypes = {};

Mentors.defaultProps = {};

export default Mentors;
