import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import store from './store/store';
import App from './App';
import theme from './styles/theme';
import reportWebVitals from './reportWebVitals';
import commonEn from './translations/en/common.json';
import commonEs from './translations/es/common.json';

const options = {
  order: ['querystring', 'navigator'],
  lookupquerystring: 'lng',
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    interpolation: { escapeValue: false },
    lng: localStorage.getItem('internationalizationLanguage') || '',
    resources: {
      en: {
        common: commonEn,
      },
      es: {
        common: commonEs,
      },
    },
    ns: ['common'],
    defaultns: 'common',
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </ThemeProvider>
  </Provider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
