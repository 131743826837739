import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, styled, Typography,
} from '@mui/material';
import { WorkspacePremium } from '@mui/icons-material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../styles/theme';
import useDetectMobile from '../../hooks/useDetectMobile';

const StyledTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
});

function ProfileService({ serviceName, description, onClick }) {
  const [t] = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);
  const { isMobile } = useDetectMobile();

  return (
    <Box sx={{
      backgroundColor: '#e9ecef',
      borderRadius: '15px',
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex !important',
      padding: '5px',
      height: isMobile ? '100%' : '200px',
    }}
    >
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <StyledTypography sx={{
            mt: 1, textAlign: 'center', color: '#c7440d', marginTop: '5px',
          }}
          >
            <WorkspacePremium />
          </StyledTypography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <StyledTypography sx={{
            mt: 1, textAlign: 'center',
          }}
          >
            <b>{ serviceName }</b>
          </StyledTypography>
          { description && description.trim() && (
            <StyledTypography sx={{
              textAlign: 'center',
            }}
            >
              <Button
                sx={{ textTransform: 'none', fontFamily: 'Montserrat' }}
                variant="text"
                onClick={() => setOpenModal(true)}
              >
                {t('ProfileService.VerMas')}
              </Button>
            </StyledTypography>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <StyledTypography sx={{
            mt: 1, textAlign: 'center',
          }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{
                borderRadius: '20px',
                margin: '5px',
                fontFamily: 'Montserrat',
                textTransform: 'none',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  color: '#273B7C',
                  backgroundColor: '#B4C5FF',
                },
              }}
              onClick={onClick}
              data-testid="booking-mentor"
            >
              {t('ProfileService.Reservar')}
            </Button>
          </StyledTypography>
        </Grid>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setOpenModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#273B7C',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                textAlign: 'center', fontSize: '1.3rem', color: '#273B7C',
              }}
            >
              <b>
                { serviceName }
              </b>
            </Typography>
            <Stack margin="25px">
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1rem', mb: 2, fontFamily: 'Montserrat', marginTop: '5px', whiteSpace: 'pre-line',
                }}
              >
                {description}
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      </Grid>
    </Box>
  );
}

ProfileService.propTypes = {
  serviceName: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ProfileService.defaultProps = {
  description: '',
};

export default ProfileService;
