import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  styled,
  Tab,
  Tabs,
} from '@mui/material';
import ga, { gtag } from '../../AnalyticsEventTracker';
import theme from '../../styles/theme';
import TabPanel from '../tabPanel/TabPanel';
import SimplePricingCard from '../cards/PricingCard/SimplePricingCard';

const StyledBox = styled(Box)({
  borderRadius: '0px 0px 14px 14px',
  borderStyle: 'solid',
  borderWidth: '0px 2px 2px 2px',
  borderColor: '#E3E3E3',
});

function SimplePricingCardField({
  id, pricingPlans, onFinish,
}) {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (pricingPlans) {
      pricingPlans.map((plan) => gtag('event', 'view_cart', {
        currency: plan.pricePlanCurrency,
        value: plan.pricePlanAmount,
        items: [{
          item_id: plan.pricePlanId,
          item_name: plan.pricePlanName,
          index: 0,
          price: plan.pricePlanAmount,
          quantity: 1,
        }],
      }));
      const isPopularIdx = pricingPlans.findIndex((plan) => plan.pricePlanIsPopular === 1);
      if (isPopularIdx >= 0) {
        setTabIndex(isPopularIdx);
      }
    }
  }, [pricingPlans]);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleAccept = (pricePlanId) => {
    const selectedPlan = pricingPlans.find((element) => element.pricePlanId === pricePlanId);
    if (selectedPlan) {
      gtag('event', 'add_to_cart', {
        currency: selectedPlan.pricePlanCurrency,
        value: selectedPlan.pricePlanAmount,
        items: [{
          item_id: selectedPlan.pricePlanId,
          item_name: selectedPlan.pricePlanName,
          index: 0,
          price: selectedPlan.pricePlanAmount,
          quantity: 1,
        }],
      });
    }
    ga('OnBoarding', 'Click', `Selected_package_${pricePlanId}`);
    onFinish(pricePlanId);
  };

  if (pricingPlans && pricingPlans.length > 0) {
    return (
      <Box
        sx={{
          minWidth: '250px',
          width: '100%',
          backgroundColor: theme.palette.cardsBackground.main,
        }}
        id={id}
      >
        <Box sx={{
          borderColor: 'divider', backgroundColor: 'rgba(221, 226, 239, 1)', borderRadius: '14px 14px 0px 0px',
        }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            aria-label="simplePricingTabs"
            variant="fullWidth"
            indicatorColor="secondary"
          >
            {pricingPlans.map(
              (pricingPlan) => (
                <Tab
                  label={pricingPlan.pricePlanName}
                  key={pricingPlan.pricePlanId}
                  sx={{ textTransform: 'none' }}
                />
              ),
            )}
          </Tabs>
        </Box>
        <StyledBox>
          {pricingPlans.map((pricingPlan, index) => (
            <TabPanel value={tabIndex} index={index} key={pricingPlan.pricePlanId}>
              <SimplePricingCard
                pricePlanId={pricingPlan.pricePlanId}
                pricePlanAmount={pricingPlan.pricePlanAmount}
                pricePlanCurrency={pricingPlan.pricePlanCurrency}
                pricePlanSymbol={pricingPlan.pricePlanSymbol}
                pricePlanOptions={pricingPlan.pricePlanOptions}
                pricePlanDiscount={pricingPlan.discount}
                onClickAccept={handleAccept}
              />
            </TabPanel>
          ))}
        </StyledBox>
      </Box>
    );
  }
}

SimplePricingCardField.propTypes = {
  id: PropTypes.string.isRequired,
  pricingPlans: PropTypes.arrayOf(PropTypes.shape({
    pricePlanId: PropTypes.number.isRequired,
    pricePlanAmount: PropTypes.number,
    pricePlanCurrency: PropTypes.string,
    pricePlanName: PropTypes.string,
    pricePlanDescription: PropTypes.string,
    pricePlanOptions: PropTypes.arrayOf(PropTypes.string),
    pricePlanIsPopular: PropTypes.number,
    onClickAccept: PropTypes.func,
  })).isRequired,
  onFinish: PropTypes.func.isRequired,
};

SimplePricingCardField.defaultProps = {};

export default SimplePricingCardField;
