import { getCookie } from '../utils/cookiesUtils';
import { MensisToken } from '../constants/authConstants';

export const postRequestOptions = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
};

export const getRequestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
};

export const setOptions = (
  requestType = 'GET',
  privateRoute = true,
  accept = 'application/json',
  contentType = 'application/json',
) => {
  const headers = {
    Accept: accept,
    'Content-Length': '<calculated when request is sent>',
  };

  // The browser will handle multipart files
  if (!contentType.includes('multipart/form-data')) {
    headers['Content-Type'] = contentType;
  }

  const cookie = getCookie(MensisToken);

  if (privateRoute) {
    if (cookie) {
      headers.authorization = `Bearer ${cookie}`;
    } else {
      // eslint-disable-next-line no-restricted-globals
      location.href = '/bye';
    }
  }

  return {
    method: requestType,
    headers,
    timeout: 10000,
  };
};
