import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import PricingCardField from '../formFields/PricingCardField';

function PricingPlanSelection({
  name, control, label, rules, options, onComplete, ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange } }) => (
        <PricingCardField
          mentorName={label}
          id={name}
          options={options}
          onFinish={(pricePlanId) => {
            if (onComplete) { onComplete(pricePlanId); }
          }}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
}

PricingPlanSelection.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  onComplete: PropTypes.func,
};

PricingPlanSelection.defaultProps = {
  label: null,
  rules: null,
  onComplete: null,
};

export default PricingPlanSelection;
