import { useTranslation } from 'react-i18next';
import React, { useState, useRef, useEffect } from 'react';
import {
  Alert, Avatar, Backdrop, FormControl, MenuItem, Select, Box, Button, CircularProgress,
  Divider, Fab, Grid, Snackbar, styled, Tooltip,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Image } from 'react-bootstrap';
import i18next from 'i18next';
import { useGetClientProfileQuery, useLazyGetClientProfileQuery } from '../store/slices/clientApi';
import Footer from '../components/footer/Footer';
import FindMentorButton from '../components/button/FindMentorButton';
import SimpleMentoringCard from '../components/cards/MentoringCard/SimpleMentoringCard';
import theme from '../styles/theme';
import useDetectMobile from '../hooks/useDetectMobile';
import { useGetDemonstrationsByClientQuery } from '../store/slices/mentoringHistoryByClientApi';
import PrivateBasicLayout from '../components/layout/PrivateBasicLayout';
import ga from '../AnalyticsEventTracker';
import TextField from '../components/controllerFormFields/TextField';
import { updateClientProfile, uploadProfilePhoto, registerDataLanguage } from '../services/clientService';
import ProfilePhotoDialog from '../components/dialog/ProfilePhotoDialog';
import linkedinImage from '../assets/img/social/linkedin-logo.png';
import instagramImage from '../assets/img/social/instagram-logo.png';
import tiktokImage from '../assets/img/social/tiktok-logo.png';
import youtubeImage from '../assets/img/social/youtube-logo.png';
import facebookImage from '../assets/img/social/facebook-logo.png';
import spotifyImage from '../assets/img/social/spotify-logo.png';
import useAuth from '../hooks/useAuth';
import LogoWord from '../assets/img/worldgrid.png';

const WEB_URL = process.env.REACT_APP_MENSIS_WEB_URL;

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  transition: 'transform .2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  borderRadius: '5px',
  marginRight: '15px',
  marginBottom: '5px',
});

function ClientProfile() {
  const [t, i18n] = useTranslation('common');
  const navigate = useNavigate();
  const { logOut } = useAuth();
  const { isMobile, windowWidth } = useDetectMobile();
  const { data: demonstrationsHistory } = useGetDemonstrationsByClientQuery();
  const { data: currentUser } = useGetClientProfileQuery();
  const [trigger] = useLazyGetClientProfileQuery();
  const ref = useRef();
  const [profileUrl, setProfileUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [isProfileCopied, setProfileCopied] = useState(false);
  const [showFullProfilePhoto, setShowFullProfilePhoto] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);

  const {
    control,
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });

  useEffect(() => {
    if (currentUser && currentUser.client && currentUser.client.profilePhotoUrl) {
      setProfileUrl(currentUser.client.profilePhotoUrl);
    } else if (currentUser && currentUser.mentor && currentUser.mentor.profilePhotoUrl) {
      setProfileUrl(currentUser.mentor.profilePhotoUrl);
    }
  }, [currentUser]);

  const handleClick = () => {
    ref.current.click();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setProfileCopied(false);
  };

  const uploadPhoto = (event) => {
    const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 5) {
      setAlertMessage('La imagen no puede ser mayor a 5 MiB.');
    } else {
      setAlertMessage('');
      setIsLoading(true);
      uploadProfilePhoto(event)
        .then((response) => response.json())
        .then((dataResponse) => {
          setProfileUrl(dataResponse.publicPhotoUrl);
          setIsLoading(false);
          ga('ClientProfile', 'Event', 'PhotoUploadedSuccessfully');
        }).catch(() => {
          setAlertMessage('La imagen no se pudo guardar. Por favor, inténtalo más tarde.');
          setIsLoading(false);
          ga('ClientProfile', 'Event', 'PhotoUploadedFailed');
        });
    }
  };

  function getProfileValue(clientValue, mentorValue) {
    if (clientValue) return clientValue;
    if (mentorValue) return mentorValue;
    return '';
  }

  const onSubmit = (data) => {
    setEditMode(false);
    window.scrollTo(0, 0);

    if (!data.phone && !data.profileDescription && !data.currentPosition && !data.tiktokUrl
      && !data.spotifyUrl && !data.youtubeUrl && !data.facebookUrl && !data.linkedinUrl
      && !data.instagramUrl) return;

    if (data) {
      const body = {
        phone: data.phone ? data.phone : '',
        profileDescription: data.profileDescription ? data.profileDescription : '',
        currentPosition: data.currentPosition ? data.currentPosition : '',
        tiktokUrl: data.tiktokUrl ? data.tiktokUrl : getProfileValue(
          currentUser.client.tiktokUrl,
          currentUser.mentor ? currentUser.mentor.tiktokUrl : null,
        ),
        spotifyUrl: data.spotifyUrl ? data.spotifyUrl : getProfileValue(
          currentUser.client.spotifyUrl,
          currentUser.mentor ? currentUser.mentor.spotifyUrl : null,
        ),
        youtubeUrl: data.youtubeUrl ? data.youtubeUrl : getProfileValue(
          currentUser.client.youtubeUrl,
          currentUser.mentor ? currentUser.mentor.youtubeUrl : null,
        ),
        facebookUrl: data.facebookUrl ? data.facebookUrl : getProfileValue(
          currentUser.client.facebookUrl,
          currentUser.mentor ? currentUser.mentor.facebookUrl : null,
        ),
        linkedinUrl: data.linkedinUrl ? data.linkedinUrl : getProfileValue(
          currentUser.client.linkedinUrl,
          currentUser.mentor ? currentUser.mentor.linkedinUrl : null,
        ),
        instagramUrl: data.instagramUrl ? data.instagramUrl : getProfileValue(
          currentUser.client.instagramUrl,
          currentUser.mentor ? currentUser.mentor.instagramUrl : null,
        ),
      };
      setIsLoading(true);
      updateClientProfile(body)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.status === 200) {
            ga('ClientProfile', 'Event', 'ProfileUpdatedSuccessfully');
            trigger();
          } else {
            ga('ClientProfile', 'Event', 'ProfileUpdateFailed');
            setAlertMessage('La información de tu perfil no se pudo actualizar. Por favor, inténtalo más tarde.');
          }
          setIsLoading(false);
        }).catch(() => {
          setAlertMessage('La información de tu perfil no se pudo actualizar. Por favor, inténtalo más tarde.');
          setIsLoading(false);
          ga('ClientProfile', 'Event', 'ProfileUpdateFailed');
        });
    }
  };

  function getMentorPublicProfileURL(email, mentorInfo) {
    if (email && mentorInfo) {
      return `${WEB_URL}/p/${email.split('@')[0]}-${mentorInfo.id}`;
    }
    return null;
  }

  const changeSelectedLanguage = async (e) => {
    localStorage.setItem('internationalizationLanguage', e.target.value);
    setSelectedLanguage(e.target.value);
    await i18n.changeLanguage(e.target.value);

    registerDataLanguage({
      preferredLanguageId: e.target.value,
    })
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.status === 200) {
          ga('ClientProfile', 'Event', 'UpdateLanguageSuccessfully');
          trigger();
        } else {
          ga('ClientProfile', 'Event', 'UpdateLanguageFailed');
        }
      }).catch(() => {
        ga('ClientProfile', 'Event', 'UpdateLanguageFailed');
      });
  };

  return (
    <PrivateBasicLayout showBanner displayLinks={false}>
      <Box>
        <section id="profile" style={{ paddingTop: '0px' }}>
          <div className="container">
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                my: 5,
              }}
            >
              {isLoading && (
                <Backdrop
                  sx={{ color: '#fff', zIndex: (myTheme) => myTheme.zIndex.drawer + 1 }}
                  open={isLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              {alertMessage && (
                <Alert sx={{ fontFamily: 'Montserrat', marginBottom: '10px' }} onClose={() => setAlertMessage('')} severity="error">{alertMessage}</Alert>
              )}
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={isProfileCopied}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                style={{ top: '75px' }}
              >
                <Alert color="secondary" onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                  Enlace copiado
                </Alert>
              </Snackbar>
              <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c', paddingBottom: '5px' }}>{t('ClientProfile.Miperfil')}</h2>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7}
                  xl={7}
                >
                  <Grid container>
                    <Grid item>
                      <input
                        id="upload-photo-input"
                        name="upload-photo"
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        ref={ref}
                        onChange={(event) => uploadPhoto(event)}
                      />
                      <Avatar src={profileUrl} sx={{ width: '200px', height: '200px', cursor: 'pointer' }} onClick={() => setShowFullProfilePhoto(true)} />
                      {profileUrl && (
                        <ProfilePhotoDialog
                          showModal={showFullProfilePhoto}
                          imgURL={profileUrl}
                          onClose={() => setShowFullProfilePhoto(false)}
                        />
                      )}
                      <Fab
                        onClick={handleClick}
                        sx={{
                          position: 'absolute',
                          marginTop: '-70px',
                          marginLeft: '140px',
                          zIndex: 50,
                        }}
                        color="secondary"
                        aria-label="edit"
                      >
                        <EditIcon />
                      </Fab>
                    </Grid>
                    <Grid item>
                      { currentUser && currentUser.client && (
                        <div style={{ paddingLeft: '20px', paddingTop: windowWidth < 768 ? '10px' : '' }}>
                          <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            {currentUser.client.name}
                            {' '}
                            {currentUser.client.lastName}
                          </p>
                          { isEditMode ? (
                            <div>
                              <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                {t('ClientProfile.Puesto')}
                              </p>
                              <TextField
                                control={control}
                                fullWidth
                                name="currentPosition"
                                key="currentPosition"
                                placeholder={t('ClientProfile.PuestoActual')}
                                value={getProfileValue(
                                  currentUser.client.currentPosition,
                                  currentUser.mentor
                                    ? currentUser.mentor.currentPosition : null,
                                )}
                                rules={{ validate: { currentPosition: (v) => String(v).length < 49 || 'Máximo son 50 caracteres' } }}
                                sx={{ mb: 2 }}
                              />
                            </div>
                          ) : (
                            <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                              {t('ClientProfile.Puesto')}
                              <span style={{ color: '#000000', paddingLeft: '10px' }}>{getProfileValue(currentUser.client.currentPosition, currentUser.mentor ? currentUser.mentor.currentPosition : null)}</span>
                              { !isEditMode && (
                                <Tooltip title={t('ClientProfile.EditarPuesto')}>
                                  <EditOutlinedIcon
                                    style={{
                                      marginLeft: '5px', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)', paddingBottom: '5px',
                                    }}
                                    onClick={() => setEditMode(true)}
                                  />
                                </Tooltip>
                              )}
                            </p>
                          )}
                          { isEditMode ? (
                            <div>
                              <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                {t('ClientProfile.Telefono')}
                              </p>
                              <TextField
                                control={control}
                                fullWidth
                                name="phone"
                                key="phone"
                                placeholder={t('ClientProfile.Minumero')}
                                value={getProfileValue(
                                  currentUser.client.phone,
                                  currentUser.mentor
                                    ? currentUser.mentor.phone : null,
                                )}
                                rules={{ validate: { currentPosition: (v) => String(v).length < 29 || 'Máximo son 30 caracteres' } }}
                                sx={{ mb: 2 }}
                              />
                            </div>
                          ) : (
                            <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                              {t('ClientProfile.Telefono')}
                              {' '}
                              <span style={{ color: '#000000', paddingLeft: '10px' }}>
                                {getProfileValue(
                                  currentUser.client.phone,
                                  currentUser.mentor
                                    ? currentUser.mentor.phone : null,
                                )}
                              </span>
                              { !isEditMode && (
                                <Tooltip title={t('ClientProfile.EditarTelefono')}>
                                  <EditOutlinedIcon
                                    style={{
                                      marginLeft: '5px', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)', paddingBottom: '5px',
                                    }}
                                    onClick={() => setEditMode(true)}
                                  />
                                </Tooltip>
                              )}
                            </p>
                          )}
                          { currentUser.client.email && (
                            <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                              {t('ClientProfile.Correoelectrónico')}
                              {' '}
                              <span style={{ color: '#000000', paddingLeft: '10px' }}>{currentUser.client.email}</span>
                            </p>
                          )}
                          { currentUser.mentor && getMentorPublicProfileURL(
                            currentUser.client.email,
                            currentUser.mentor,
                          ) && (
                          <p style={{ color: 'rgba(32,32,32,0.65)', wordWrap: 'break-word', width: isMobile ? `${windowWidth - 50}px` : '100%' }}>
                            {' '}
                            {t('ClientProfile.PerfilPublico')}
                            {' '}
                            <a href={getMentorPublicProfileURL(currentUser.client.email, currentUser.mentor)} style={{ paddingLeft: '10px', fontFamily: 'Montserrat' }}>
                              {getMentorPublicProfileURL(
                                currentUser.client.email,
                                currentUser.mentor,
                              )}
                            </a>
                            <CopyToClipboard
                              text={getMentorPublicProfileURL(
                                currentUser.client.email,
                                currentUser.mentor,
                              )}
                              onCopy={() => { ga('ClientProfile', 'Click', 'CopyLink'); setProfileCopied(true); }}
                            >
                              <ContentCopyIcon
                                style={{
                                  marginLeft: '5px', cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)', paddingBottom: '5px',
                                }}
                              />
                            </CopyToClipboard>
                          </p>
                          )}
                          <div>
                            <div style={{ marginRight: '10px', textAlign: 'left', paddingBottom: '10px' }}>
                              <img src={LogoWord} alt="Word" align="center" width={15} height={15} />
                              <span style={{ color: 'rgba(32,32,32,0.65)', align: 'marginLeft', paddingLeft: '10px' }}>{t('ClientProfile.Idioma')}</span>
                              {' '}
                              <FormControl variant="standard" sx={{ maxWidth: 120, paddingLeft: '10px', marginTop: -0.5 }}>
                                <Select
                                  labelId="language-simple-select-standard-label"
                                  id="language-simple-select-standard"
                                  value={selectedLanguage}
                                  onChange={(e) => changeSelectedLanguage(e)}
                                  label="Language"
                                >
                                  <MenuItem value="es"><span style={{ color: '#000000', align: 'marginLeft' }}>Español</span></MenuItem>
                                  <MenuItem value="en"><span style={{ color: '#000000', align: 'marginLeft' }}>English</span></MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          { isEditMode && (
                            <div>
                              <div>
                                <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                &nbsp;
                                  {t('ClientProfile.LinkedIn')}
                                </p>
                                <TextField
                                  control={control}
                                  fullWidth
                                  name="linkedinUrl"
                                  key="linkedinUrl"
                                  placeholder="www.linkedin.com/in/mensismentor"
                                  value={getProfileValue(
                                    currentUser.client.linkedinUrl,
                                    currentUser.mentor ? currentUser.mentor.linkedinUrl : null,
                                  )}
                                  rules={{ validate: { currentPosition: (v) => String(v).length < 150 || 'Máximo son 150 caracteres' } }}
                                  sx={{ mb: 2 }}
                                />
                              </div>
                              <div>
                                <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                  {t('ClientProfile.Facebook')}
                                </p>
                                <TextField
                                  control={control}
                                  fullWidth
                                  name="facebookUrl"
                                  key="facebookUrl"
                                  placeholder="www.facebook.com/Mensis-113581956965750"
                                  value={getProfileValue(
                                    currentUser.client.facebookUrl,
                                    currentUser.mentor ? currentUser.mentor.facebookUrl : null,
                                  )}
                                  rules={{ validate: { currentPosition: (v) => String(v).length < 150 || 'Máximo son 150 caracteres' } }}
                                  sx={{ mb: 2 }}
                                />
                              </div>
                              <div>
                                <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                  {t('ClientProfile.TikTok')}
                                </p>
                                <TextField
                                  control={control}
                                  fullWidth
                                  name="tiktokUrl"
                                  key="tiktokUrl"
                                  placeholder="www.tiktok.com/@mensis_mentor"
                                  value={getProfileValue(
                                    currentUser.client.tiktokUrl,
                                    currentUser.mentor ? currentUser.mentor.tiktokUrl : null,
                                  )}
                                  rules={{ validate: { currentPosition: (v) => String(v).length < 150 || 'Máximo son 150 caracteres' } }}
                                  sx={{ mb: 2 }}
                                />
                              </div>
                              <div>
                                <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                  {t('ClientProfile.YouTube')}
                                </p>
                                <TextField
                                  control={control}
                                  fullWidth
                                  name="youtubeUrl"
                                  key="youtubeUrl"
                                  placeholder="www.youtube.com/channel/UC9UB9UDkumz1Hr7HCDBKYKw"
                                  value={getProfileValue(
                                    currentUser.client.youtubeUrl,
                                    currentUser.mentor ? currentUser.mentor.youtubeUrl : null,
                                  )}
                                  rules={{ validate: { currentPosition: (v) => String(v).length < 150 || 'Máximo son 150 caracteres' } }}
                                  sx={{ mb: 2 }}
                                />
                              </div>
                              <div>
                                <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                  {t('ClientProfile.Instagram')}
                                </p>
                                <TextField
                                  control={control}
                                  fullWidth
                                  name="instagramUrl"
                                  key="instagramUrl"
                                  placeholder="www.instagram.com/mensis_mentor"
                                  value={getProfileValue(
                                    currentUser.client.instagramUrl,
                                    currentUser.mentor ? currentUser.mentor.instagramUrl : null,
                                  )}
                                  rules={{ validate: { currentPosition: (v) => String(v).length < 150 || 'Máximo son 150 caracteres' } }}
                                  sx={{ mb: 2 }}
                                />
                              </div>
                              <div>
                                <p style={{ color: 'rgba(32,32,32,0.65)' }}>
                                  {t('ClientProfile.Spotify')}
                                </p>
                                <TextField
                                  control={control}
                                  fullWidth
                                  name="spotifyUrl"
                                  key="spotifyUrl"
                                  placeholder="https://open.spotify.com/user/1234567"
                                  value={getProfileValue(
                                    currentUser.client.spotifyUrl,
                                    currentUser.mentor ? currentUser.mentor.spotifyUrl : null,
                                  )}
                                  rules={{ validate: { currentPosition: (v) => String(v).length < 150 || 'Máximo son 150 caracteres' } }}
                                  sx={{ mb: 2 }}
                                />
                              </div>
                            </div>
                          )}
                          {!isEditMode && (
                            <div>
                              <Grid container style={{ marginTop: '0px' }}>
                                {getProfileValue(
                                  currentUser.client.linkedinUrl,
                                  currentUser.mentor ? currentUser.mentor.linkedinUrl : null,
                                ).trim().length > 0 && (
                                  <Grid key="LinkedIn image" sx={{ padding: '5px' }}>
                                    <a
                                      href={getProfileValue(
                                        currentUser.client.linkedinUrl,
                                        currentUser.mentor ? currentUser.mentor.linkedinUrl : null,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Image
                                        alt="LinkedIn image"
                                        src={linkedinImage}
                                        width="30px"
                                      />
                                    </a>
                                  </Grid>
                                )}
                                {getProfileValue(
                                  currentUser.client.facebookUrl,
                                  currentUser.mentor ? currentUser.mentor.facebookUrl : null,
                                ).trim().length > 0 && (
                                  <Grid key="Facebook image" sx={{ padding: '5px' }}>
                                    <a
                                      href={getProfileValue(
                                        currentUser.client.facebookUrl,
                                        currentUser.mentor ? currentUser.mentor.facebookUrl : null,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Image
                                        alt="Facebook image"
                                        src={facebookImage}
                                        width="30px"
                                      />
                                    </a>
                                  </Grid>
                                )}
                                {getProfileValue(
                                  currentUser.client.youtubeUrl,
                                  currentUser.mentor ? currentUser.mentor.youtubeUrl : null,
                                ).trim().length > 0 && (
                                  <Grid key="YouTube image" sx={{ padding: '5px' }}>
                                    <a
                                      href={getProfileValue(
                                        currentUser.client.youtubeUrl,
                                        currentUser.mentor ? currentUser.mentor.youtubeUrl : null,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Image
                                        alt="YouTube image"
                                        src={youtubeImage}
                                        width="30px"
                                      />
                                    </a>
                                  </Grid>
                                )}
                                {getProfileValue(
                                  currentUser.client.tiktokUrl,
                                  currentUser.mentor ? currentUser.mentor.tiktokUrl : null,
                                ).trim().length > 0 && (
                                  <Grid key="TikTok image" sx={{ padding: '5px' }}>
                                    <a
                                      href={getProfileValue(
                                        currentUser.client.tiktokUrl,
                                        currentUser.mentor ? currentUser.mentor.tiktokUrl : null,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Image
                                        alt="TikTok image"
                                        src={tiktokImage}
                                        width="30px"
                                      />
                                    </a>
                                  </Grid>
                                )}
                                {getProfileValue(
                                  currentUser.client.instagramUrl,
                                  currentUser.mentor ? currentUser.mentor.instagramUrl : null,
                                ).trim().length > 0 && (
                                  <Grid key="Instagram image" sx={{ padding: '5px' }}>
                                    <a
                                      href={getProfileValue(
                                        currentUser.client.instagramUrl,
                                        currentUser.mentor ? currentUser.mentor.instagramUrl : null,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Image
                                        alt="Instagram image"
                                        src={instagramImage}
                                        width="30px"
                                      />
                                    </a>
                                  </Grid>
                                )}
                                {getProfileValue(
                                  currentUser.client.spotifyUrl,
                                  currentUser.mentor ? currentUser.mentor.spotifyUrl : null,
                                ).trim().length > 0 && (
                                  <Grid key="Spotify image" sx={{ padding: '5px' }}>
                                    <a
                                      href={getProfileValue(
                                        currentUser.client.spotifyUrl,
                                        currentUser.mentor ? currentUser.mentor.spotifyUrl : null,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Image
                                        alt="Spotify image"
                                        src={spotifyImage}
                                        width="30px"
                                      />
                                    </a>
                                  </Grid>
                                )}
                              </Grid>
                            </div>
                          )}
                        </div>
                      )}
                      {windowWidth > 1200 && !isEditMode && (
                        <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                          <StyledButton
                            onClick={() => navigate('/updatePassword', { replace: true })}
                            color="secondary"
                            variant="contained"
                          >
                            {t('ClientProfile.Cambiarpws')}
                          </StyledButton>
                          <StyledButton
                            onClick={() => { ga('ClientProfile', 'Click', 'SignOff'); logOut({ showModalLogAgain: false }); }}
                            color="secondary"
                            variant="contained"
                          >
                            {t('ClientProfile.CerrarSesion')}
                          </StyledButton>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                { currentUser
                  && (currentUser.client || currentUser.mentor)
                  && (
                    <Grid item xs={12} sm={12} lg={5} xl={5} sx={{ paddingLeft: windowWidth < 1200 ? '20px' : '5px' }}>
                      <p style={{
                        fontWeight: 600,
                        paddingTop: windowWidth < 1200 ? '15px' : '5px',
                      }}
                      >
                        {t('ClientProfile.Acercademí')}
                        { !isEditMode && (
                          <Tooltip title={t('ClientProfile.EditarDescripcion')}>
                            <EditOutlinedIcon style={{ marginLeft: '5px', cursor: 'pointer', paddingBottom: '5px' }} onClick={() => setEditMode(true)} />
                          </Tooltip>
                        )}
                      </p>
                      { isEditMode && (
                        <TextField
                          control={control}
                          fullWidth
                          name="profileDescription"
                          key="profileDescription"
                          placeholder={t('ClientProfile.Descripcion')}
                          multiline
                          rowsNumber={8}
                          value={getProfileValue(
                            currentUser.client.profileDescription,
                            currentUser.mentor
                              ? currentUser.mentor.profileDescription : null,
                          )}
                          rules={{ validate: { currentPosition: (v) => String(v).length < 499 || 'Máximo son 500 caracteres' } }}
                          sx={{ mb: 2 }}
                        />
                      )}
                      { !isEditMode && getProfileValue(
                        currentUser.client.profileDescription,
                        currentUser.mentor
                          ? currentUser.mentor.profileDescription : null,
                      ) && (
                      <p style={{ marginTop: '-5px', whiteSpace: 'pre-line' }}>{currentUser.client.profileDescription ? currentUser.client.profileDescription : currentUser.mentor.profileDescription}</p>
                      )}
                    </Grid>
                  )}
              </Grid>
              <Grid>
                <div style={{ textAlign: 'center' }}>
                  { isEditMode && (
                    <div>
                      <StyledButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 3,
                          mb: 2,
                        }}
                      >
                        {t('ClientProfile.Actualizar')}
                      </StyledButton>
                      <StyledButton
                        onClick={() => { setEditMode(false); window.scrollTo(0, 0); }}
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 3,
                          mb: 2,
                        }}
                      >
                        {t('ClientProfile.Cancelar')}
                      </StyledButton>
                      { windowWidth <= 1200 && (
                        <Divider />
                      )}
                    </div>
                  )}
                </div>
              </Grid>
              {windowWidth <= 1200 && (
                <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                  <StyledButton
                    onClick={() => { ga('ClientProfile', 'Click', 'UpdatePassword'); navigate('/updatePassword', { replace: true }); }}
                    color="secondary"
                    variant="contained"
                  >
                    {t('ClientProfile.Cambiarpws')}
                  </StyledButton>
                  <StyledButton
                    onClick={() => { ga('ClientProfile', 'Click', 'SignOff'); logOut({ showModalLogAgain: false }); }}
                    color="secondary"
                    variant="contained"
                  >
                    {t('ClientProfile.CerrarSesion')}
                  </StyledButton>
                </div>
              )}
            </Box>
          </div>
        </section>
        <section id="purchased-packages" style={{ paddingTop: '10px' }}>
          <div className="container">
            <h2 style={{ fontFamily: 'Montserrat', color: '#273b7c' }}>{t('ClientProfile.Mentorias')}</h2>
            <p style={{ fontFamily: 'Montserrat', fontSize: '22px !important' }}>
              {t('ClientProfile.MentoriasCuerpo')}
            </p>
            <Grid
              container
              spacing={2}
              mt={{ xs: 10, sm: 10, md: 2 }}
              sx={{ mt: 0, marginTop: '20px' }}
            >
              <Grid
                key="find-mentor"
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
              >
                <Box
                  sx={{
                    width: isMobile ? '100%' : '300px',
                    boxShadow: 'none',
                    backgroundColor: '#fff0',
                  }}
                >
                  <FindMentorButton margin="0px" onClick={() => { ga('ClientProfile', 'Click', 'FindMyMentor'); navigate('/onboarding', { replace: true }); }} />
                </Box>
              </Grid>
              { demonstrationsHistory && demonstrationsHistory.demonstrations
                && demonstrationsHistory.demonstrations.map((demonstration) => (
                  <Grid
                    key={demonstration.demonstrationId}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: isMobile ? '100%' : '300px',
                        backgroundColor: 'white',
                        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                        '&:hover': {
                          backgroundColor: '#E9EBF2',
                        },
                      }}
                    >
                      <div style={{ padding: '10px' }}>
                        <SimpleMentoringCard {...demonstration} />
                      </div>
                      <Box sx={{ textAlign: 'right', paddingRight: '10px', marginBottom: '-10px' }}>
                        <p style={{ color: theme.palette.grey[500] }}>{`${t('UserHome.Hace')} ${moment().diff(moment.utc(demonstration.demonstrationCreatedAt).locale('es').local(), 'days')} ${t('UserHome.dias')}${t('UserHome.HaceEn')}`}</p>
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </div>
        </section>
      </Box>
      <Footer showImportantLinks={false} />
    </PrivateBasicLayout>
  );
}

ClientProfile.propTypes = {};

ClientProfile.defaultProps = {};

export default ClientProfile;
