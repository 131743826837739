import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const currentCountryApi = createApi({
  reducerPath: 'ipapi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://ipapi.co/json' }),
  tagTypes: ['currentCountry'],
  endpoints: (build) => ({
    getCurrentCountry: build.query({
      query: () => ({ url: '' }),
    }),
  }),
});

export const { useGetCurrentCountryQuery } = currentCountryApi;
