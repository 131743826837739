import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import Signup from '../components/forms/Signup';
import Login from '../components/forms/Login';
import BasicLayout from '../components/layout/BasicLayout';
import useDetectMobile from '../hooks/useDetectMobile';
import { useLazyGetCurrentUserQuery } from '../store/slices/clientApi';
import AuthCard from '../components/cards/AuthCard/AuthCard';
import LoginAgainDialog from '../components/dialog/LoginAgainDialog';
import ga from '../AnalyticsEventTracker';

function Auth({ signIn }) {
  const navigate = useNavigate();
  const [firstTime, setFirstTime] = useState(signIn);
  const [trigger] = useLazyGetCurrentUserQuery();
  const { state: routerState } = useLocation();
  const { windowWidth } = useDetectMobile();

  const [showLoginAgain, setShowLoginAgain] = useState(false);

  useEffect(() => {
    if (routerState && routerState.showLoginAgainModal) {
      setShowLoginAgain(routerState.showLoginAgainModal);
    }
  }, [routerState]);

  const onSuccessLogin = () => {
    trigger();
    navigate('/home', { state: { showLoginAgainModal: false } });
  };

  const onSuccessSignup = () => {
    trigger();
    navigate('/home', { state: { showLoginAgainModal: false } });
  };

  const closeModal = () => {
    setShowLoginAgain(false);
  };

  return (
    <BasicLayout showBanner displayLinks>
      {showLoginAgain && (
      <LoginAgainDialog
        showModal={showLoginAgain}
        onClose={closeModal}
      />
      )}
      <Box
        sx={{
          my: 5,
          marginRight: (() => {
            if (windowWidth < 800) return '10px';
            if (firstTime && windowWidth >= 200) return '25%';
            return '15%';
          }),
          marginLeft: (() => {
            if (windowWidth < 800) return '10px';
            if (firstTime && windowWidth >= 200) return '25%';
            return '15%';
          }),
        }}
      >
        <AuthCard>
          {firstTime ? (
            <Login
              changeAuth={() => {
                ga('Login', 'Click', 'Signup');
                setFirstTime(false);
              }}
              onLogin={onSuccessLogin}
            />
          ) : (
            <Signup
              changeAuth={() => {
                ga('Signup', 'Click', 'Login');
                setFirstTime(true);
              }}
              onSignup={onSuccessSignup}
            />
          )}
        </AuthCard>
      </Box>
      <Footer />
    </BasicLayout>
  );
}

Auth.propTypes = {
  signIn: PropTypes.bool,
};

Auth.defaultProps = {
  signIn: false,
};

export default Auth;
