import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button, Card, Grid, Stack, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ga from '../../AnalyticsEventTracker';
import useAuth from '../../hooks/useAuth';
import { AuthStates } from '../../constants/authConstants';
import AuthField from '../formFields/AuthField';
import {
  selectPricePackage,
  selectedMentorSelector,
  pricePackagesSelector,
} from '../../store/slices/clientOnboardingSlice';
import registerDataLogV1 from '../../services/dataLogService';
import useDetectMobile from '../../hooks/useDetectMobile';
import OnboardingMentorCard from '../cards/MentorCard/OnboardingMentorCard';
import SimplePricingCardField from '../formFields/SimplePricingCardField';
import { serviceDetailsData } from '../../data/QuestionsData';
import theme from '../../styles/theme';

function ServicesStep({
  onChange, onReturn, isServerError, onSuccessLogin, ...props
}) {
  const selectedMentor = useSelector(selectedMentorSelector);
  const pricePackages = useSelector(pricePackagesSelector);
  const { isMobile } = useDetectMobile();
  const { authStatus, triggerGetCookie } = useAuth();
  const dispatch = useDispatch();

  const onSubmit = (pricePlanId) => {
    ga('OnBoarding', 'Click', 'Selected_package');
    const selectedPricingTemp = pricePackages.find(
      (pricingPackage) => pricingPackage.pricePlanId === pricePlanId,
    );

    registerDataLogV1({
      event: 'SELECTED_PACKAGE',
      description: `Package selected from ${window.location.href}`,
      newData: `${JSON.stringify(selectedPricingTemp)}`,
    }).then((response) => response.json());

    dispatch(selectPricePackage(selectedPricingTemp));
    onChange();
  };

  const onReturnClick = () => {
    onReturn();
  };

  const successLogin = () => {
    triggerGetCookie();
    onSuccessLogin();
  };
  const [t] = useTranslation('common');
  return (
    <Stack
      direction="column"
      ql="qa-ctnr-pricing-plans"
      sx={{ mt: 3 }}
      {...props}
    >
      {authStatus === AuthStates.LOGGED_IN ? (
        <>
          <Grid container spacing={3}>
            {!isMobile && (
            <Grid item md={6} lg={6} xl={6}>
              <OnboardingMentorCard {...selectedMentor} />
            </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography sx={{
                fontFamily: 'Montserrat', fontSize: '18px', fontWeight: '600', mb: 2,
              }}
              >
                {t('ServicesStep.Plan')}
                {' '}
                {selectedMentor.mentorName}
                ?
              </Typography>
              <SimplePricingCardField id="Select_pricing_price" pricingPlans={pricePackages} onFinish={onSubmit} />
            </Grid>
          </Grid>
          <Typography sx={{
            fontFamily: 'Montserrat', fontSize: '25px', fontWeight: '600', textAlign: 'center', mt: 3, paddingBottom: '10px',
          }}
          >
            {t('ServicesStep.EsperarDelServicio')}
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            {serviceDetailsData.map((serviceDetail) => (
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={serviceDetail.id}>
                <Card sx={{ backgroundColor: theme.palette.cardsBackground.main, minHeight: isMobile ? '100%' : '300px', height: '100%' }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ textAlign: 'center', padding: '20px' }}
                  >
                    {serviceDetail.icon}
                    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: '600' }}>{t(serviceDetail.title)}</Typography>
                    <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: '400' }}>{t(serviceDetail.description)}</Typography>
                  </Stack>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : <AuthField onSuccess={successLogin} showLoginFirst={false} />}
      <div style={{ alignSelf: 'flex-end', marginTop: '40px' }}>
        {onReturn && (
        <Button
          variant="contained"
          onClick={() => {
            ga('OnBoarding', 'Click', 'BackFromPackages');
            onReturnClick();
          }}
          sx={{
            borderRadius: '20px',
            margin: '5px',
            fontFamily: 'Montserrat',
            textTransform: 'none',
            color: '#273B7C',
            backgroundColor: '#fff',
            '&:hover': { backgroundColor: 'rgba(39, 59, 124, 0.04)' },
          }}
          data-testid="back"
        >
          {t('ClientOnboarding.onboardingSteps.Atras')}
        </Button>
        )}
      </div>
    </Stack>
  );
}

ServicesStep.propTypes = {
  isServerError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onReturn: PropTypes.func,
  onSuccessLogin: PropTypes.func,
};

ServicesStep.defaultProps = {
  onReturn: null,
  onSuccessLogin: null,
};

export default ServicesStep;
