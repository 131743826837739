import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl, Grid, styled, Typography,
} from '@mui/material';
import ga, { gtag } from '../../AnalyticsEventTracker';
import PricingCard from '../cards/PricingCard/PricingCard';
import theme from '../../styles/theme';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: '1.1rem',
  color: '#273B7C',
  paddingBottom: '20px',
});

function PricingCardField({
  id, mentorName, pricingPlans, onChange, onFinish, ...props
}) {
  useEffect(() => {
    if (pricingPlans) {
      pricingPlans.map((plan) => gtag('event', 'view_cart', {
        currency: plan.pricePlanCurrency,
        value: plan.pricePlanAmount,
        items: [{
          item_id: plan.pricePlanId,
          item_name: plan.pricePlanName,
          index: 0,
          price: plan.pricePlanAmount,
          quantity: 1,
        }],
      }));
    }
  }, [pricingPlans]);

  const handleAccept = (pricePlanId) => {
    const selectedPlan = pricingPlans.find((element) => element.pricePlanId === pricePlanId);
    if (selectedPlan) {
      gtag('event', 'add_to_cart', {
        currency: selectedPlan.pricePlanCurrency,
        value: selectedPlan.pricePlanAmount,
        items: [{
          item_id: selectedPlan.pricePlanId,
          item_name: selectedPlan.pricePlanName,
          index: 0,
          price: selectedPlan.pricePlanAmount,
          quantity: 1,
        }],
      });
    }
    ga('OnBoarding', 'Click', `Selected_package_${pricePlanId}`);
    onChange(pricePlanId);
    onFinish(pricePlanId);
  };

  return (
    <FormControl
      sx={{
        minWidth: '300px', width: '100%', marginTop: '5px', backgroundColor: theme.palette.cardsBackground.main,
      }}
      id={id}
    >
      {mentorName && (
      <StyledTypography sx={{ my: 1 }}>
        ¡Felicidades! Estás a un solo click de conectar con
        {' '}
        <b>{mentorName}</b>
      </StyledTypography>
      )}
      <Box sx={{
        display: 'flex', justifyContent: 'center', mt: '30px', mb: 1,
      }}
      >
        <Grid
          container
          {...props}
          sx={{
            width: '300px',
            [theme.breakpoints.up('md')]: {
              width: '900px',
            },
            borderRadius: '26px',
          }}
        >
          {pricingPlans && pricingPlans.length > 0 && pricingPlans.map((pricingPlan) => (
            <Grid
              key={pricingPlan.pricePlanId}
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ zIndex: pricingPlan.pricePlanIsPopular && 1 }}
            >
              <PricingCard
                {...pricingPlan}
                mentorName={mentorName}
                onClickAccept={handleAccept}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </FormControl>
  );
}

PricingCardField.propTypes = {
  mentorName: PropTypes.string,
  id: PropTypes.string.isRequired,
  pricingPlans: PropTypes.arrayOf(PropTypes.shape({
    pricePlanId: PropTypes.number.isRequired,
    pricePlanAmount: PropTypes.number,
    pricePlanCurrency: PropTypes.string,
    pricePlanName: PropTypes.string,
    pricePlanDescription: PropTypes.string,
    pricePlanOptions: PropTypes.arrayOf(PropTypes.string),
    pricePlanIsPopular: PropTypes.number,
    onClickAccept: PropTypes.func,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

PricingCardField.defaultProps = {
  mentorName: null,
};

export default PricingCardField;
