import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete, FormControl, TextField, styled, Typography,
} from '@mui/material';
import theme from '../../styles/theme';
import ga from '../../AnalyticsEventTracker';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: '1.5rem',
});

const StyledAutocomplete = styled(Autocomplete)({
  '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    fontFamily: 'Montserrat',
  },
  fontFamily: 'Montserrat',
  textTransform: 'none',
  fieldset: {
    legend: {
      width: 'unset',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
});

function AutocompleteField({
  id, label, options, value, other, onChange, placeholder, ...props
}) {
  const [selection, setSelection] = useState(value);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setSelection(value);
  }, [value]);

  const onSelectOption = (_event, newValue) => {
    if (newValue && newValue.id) {
      ga('OnBoarding', 'Click', `AutocompleteFieldSelect_${newValue.id}`);
      setSelection(newValue);
      onChange(newValue);
    } else {
      setSelection({ id: 'other', label: '' });
      onChange({ id: 'other', label: '' });
    }
  };

  const onInputChange = (_event, newValue) => {
    ga('OnBoarding', 'Type', `AutocompleteField_${newValue}`);
    setInputValue(newValue);
    if (other) { onChange({ id: 'other', label: newValue }); }
  };
  const [t] = useTranslation('common');
  return (
    <FormControl sx={{ width: '100%' }}>
      {label && <StyledTypography sx={{ my: 5, marginBottom: '10px' }}>{t(label)}</StyledTypography>}
      <StyledAutocomplete
        noOptionsText="Favor utilizar la opción de 'Mentores en otras áreas'"
        disablePortal={false}
        forcePopupIcon
        id={id}
        options={options}
        inputValue={inputValue}
        onInputChange={onInputChange}
        value={selection}
        getOptionLabel={(item) => t(item.label)}
        freeSolo={other}
        onChange={onSelectOption}
        data-testid="autocomplete"
        renderInput={(params) => <TextField placeholder={placeholder} sx={{ fontFamily: 'Montserrat', color: 'red' }} {...params} />}
        renderOption={(insideProps, option) => (
          <li {...insideProps} style={{ fontFamily: 'Montserrat' }}>
            {t(option.label)}
          </li>
        )}
        {...props}
      />
    </FormControl>
  );
}

AutocompleteField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  other: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

AutocompleteField.defaultProps = {
  label: null,
  value: null,
  other: false,
  placeholder: '',
};

export default AutocompleteField;
