import { configureStore } from '@reduxjs/toolkit';

import { currentCountryApi } from './slices/currentCountryApi';
import { bannerMessageApi } from './slices/bannerMessageApi';
import { clientApi } from './slices/clientApi';

import rootReducer from './rootReducer';
import { recommendedMentors } from './slices/recommendedMentorsApi';
import { mentors } from './slices/mentorsApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(currentCountryApi.middleware)
    .concat(bannerMessageApi.middleware)
    .concat(clientApi.middleware)
    .concat(recommendedMentors.middleware)
    .concat(mentors.middleware),
});

export default store;
