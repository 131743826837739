/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

const onboardingAnswersSlice = createSlice({
  name: 'onboardingAnswers',
  initialState,
  reducers: {
    changeAnswers: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { changeAnswers } = onboardingAnswersSlice.actions;

export const onboardingAnswersSelector = (state) => state.onboardingAnswersSlice;

export default onboardingAnswersSlice.reducer;
