import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as BookIconSvg } from './book.svg';

function BookIcon(props) {
  return (
    <SvgIcon component={BookIconSvg} inheritViewBox {...props} />
  );
}

export default BookIcon;
