import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';
import ga from '../../AnalyticsEventTracker';

const StyledMuiTelInput = styled(MuiTelInput)({
  fontFamily: 'Montserrat',
  '&.MuiTextField-root .MuiFormHelperText-root': {
    fontFamily: 'Montserrat',
  },
  '&.MuiTextField-root .MuiIconButton-root': {
    padding: 0,
  },
  fieldset: {
    legend: {
      width: 'unset',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    fontFamily: 'Montserrat',
  },
});

function Phone({
  name, control, isRequired, currentCountry, ...props
}) {
  const [telInfo, setTelInfo] = useState(null);
  const [t] = useTranslation('common');

  const validatePhone = (newValue) => {
    if (newValue !== undefined && newValue !== null && telInfo !== null) {
      const validTel = matchIsValidTel(newValue, telInfo.countryCode);
      return (validTel);
    }
    return true;
  };

  const handleChangeTel = (newData, onChange) => {
    const { valueOld, info } = newData;
    setTelInfo(info);
    ga('General', 'Type', `TextField_${name}`);
    onChange(valueOld, info);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          isPhone: (value) => validatePhone(value) || t('Signup.InvalidPhone'),
        },
        isRequired: t('Signup.RequiredPhone'),
      }}
      render={({ field: { onChange, value }, fieldState }) => (
        <StyledMuiTelInput
          defaultCountry={currentCountry}
          preferredCountries={['CR', 'CO', 'MX']}
          continents={['NA', 'SA', 'EU']}
          value={value}
          onChange={(valueOld, info) => handleChangeTel({ valueOld, info }, onChange)}
          helperText={!!fieldState.error && fieldState.error.message}
          error={!!fieldState.error}
          {...props}
        />

      )}
    />
  );
}

Phone.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  currentCountry: PropTypes.string,
};

Phone.defaultProps = {
  isRequired: false,
  currentCountry: 'CR',
};

export default Phone;
