import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, FormGroup, Switch, styled, Typography,
} from '@mui/material';
import TextFormFieldWithId from './TextFormFieldWithId';
import ga from '../../AnalyticsEventTracker';

const StyledFormControlLabel = styled(FormControlLabel)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  '&.MuiFormControlLabel-root': {
    textAlign: 'center',
  },
  color: '#273B7C',
});

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: '1.5rem',
});

function SwitchFormField({
  id, label, options, value, other, onChange,
  switchLabelPlacement, switchWidth,
}) {
  const [selected, setSelected] = useState(null);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherText, setOtherText] = useState('');

  useEffect(() => {
    if (selected === 'other') {
      onChange({
        id: 'other',
        label: otherText,
      });
    }
  }, [selected, otherText, onChange]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onSelect = (itemId) => {
    setSelected(selected === itemId ? null : itemId);
    if (itemId === 'other') {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
    }
    const temporalIdx = options.findIndex((x) => x.id === itemId);
    ga('OnBoarding', 'Click', `Switch_${itemId}`);
    onChange(selected === itemId ? null : options[temporalIdx]);
  };
  const [t] = useTranslation('common');
  return (
    <FormControl sx={{ width: '100%' }}>
      {label && <StyledTypography sx={{ my: 5, marginBottom: '10px' }}>{label}</StyledTypography>}
      <FormGroup
        aria-label={id}
        row
        sx={{
          borderRadius: 5, border: '1px solid #273B7C', p: 2, alignSelf: 'center', justifyContent: 'center', padding: 0,
        }}
      >
        {options.map((item) => (
          <StyledFormControlLabel
            value={item.id}
            key={item.id}
            onChange={() => onSelect(item.id)}
            checked={selected === item.id}
            label={t(item.label)}
            labelPlacement={switchLabelPlacement}
            sx={{
              width: switchWidth,
              marginLeft: 0,
              marginRight: 0,
              fontFamily: 'Montserrat',
              '.MuiTypography-root': {
                fontFamily: 'Montserrat',
              },
            }}
            control={(
              <Switch />
            )}
          />
        ))}

        {other && (

        <StyledFormControlLabel
          value="other"
          key={`${id}_other`}
          onChange={() => onSelect('other')}
          checked={selected === 'other'}
          label="Otro"
          labelPlacement={switchLabelPlacement}
          sx={{
            width: switchWidth, marginLeft: 0, marginRight: 0, fontFamily: 'Montserrat',
          }}
          control={(
            <Switch />
            )}
        />

        )}
      </FormGroup>
      {otherSelected && (
        <TextFormFieldWithId
          id={`${id}_other`}
          value={otherText}
          onChange={(text) => setOtherText(text.label)}
          sx={{ mt: 2 }}
        />
      )}
    </FormControl>
  );
}

SwitchFormField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string,
  switchWidth: PropTypes.number,
  switchLabelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  other: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SwitchFormField.defaultProps = {
  label: null,
  value: null,
  switchWidth: 150,
  other: false,
  switchLabelPlacement: 'bottom',
};

export default SwitchFormField;
