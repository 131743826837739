import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  MenuItem,
  IconButton,
  Select,
  styled,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

const StyledSelect = styled(Select)({
  fontFamily: 'Montserrat',
  fontSize: '15px',
  height: '32px',
  width: '175px',
  borderRadius: '16px',
  backgroundColor: '#E6E6E6',
  fieldset: {
    legend: {
      width: 'unset',
    },
  },
});

function SelectFilter({
  id, label, options, value, noValueOption, onChange, onEmpty,
}) {
  const [t] = useTranslation('common');
  return (
    <StyledSelect
      labelId={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      displayEmpty
      renderValue={(selectedValue) => ((selectedValue && selectedValue !== '') ? t(options.find((item) => item.id === selectedValue)?.label) : t(label))}
      sx={{ '& .MuiSelect-iconOutlined': { display: value ? 'none' : '' }, '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' } }}
      endAdornment={value && <IconButton size="small" onClick={onEmpty}><Clear /></IconButton>}
    >
      {noValueOption && <MenuItem value="">{t('SelectFilter.SinFiltro')}</MenuItem>}
      {options.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {t(item.label)}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

SelectFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.any,
  noValueOption: PropTypes.bool,
  onEmpty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  id: `select_${Math.random().toString(5)}`,
  noValueOption: true,
  label: '',
  value: {},
};

export default SelectFilter;
