import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextFormFieldWithId from '../formFields/TextFormFieldWithId';

function TextFieldWithId({
  name, control, label, rules, multiline, ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState }) => (
        <TextFormFieldWithId
          label={label}
          id={name}
          value={value?.label ? value.label : ''}
          multiline={multiline}
          onChange={onChange}
          helperText={!!fieldState.error && fieldState.error.message}
          error={!!fieldState.error}
          {...props}
        />

      )}
    />
  );
}

TextFieldWithId.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  multiline: PropTypes.bool,
};

TextFieldWithId.defaultProps = {
  label: null,
  rules: null,
  multiline: false,
};

export default TextFieldWithId;
