// based on https://blog.openreplay.com/creating-a-table-of-content-widget-in-react/
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper } from '@mui/material';
import TableOfContent from './TableOfContent';
import useDetectMobile from '../../hooks/useDetectMobile';

function ContentWithIndex({ children }) {
  const { isMobile } = useDetectMobile();

  return (
    <Paper sx={{ py: 2, px: 3, fontFamily: 'Montserrat' }}>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={9} md={9} lg={9}>
          <Box
            sx={{
              overflowY: isMobile ? 'scroll' : 'auto',
              maxHeight: '90vh',
              paddingRight: '7px',
            }}
          >
            {children}
          </Box>
        </Grid>
        {!isMobile
        && (
        <Grid
          item
          sm={3}
          md={3}
          lg={3}
        >
          <TableOfContent />
        </Grid>
        ) }
      </Grid>
    </Paper>
  );
}

ContentWithIndex.propTypes = {
  children: PropTypes.node.isRequired,
};

ContentWithIndex.defaultProps = {
};

export default ContentWithIndex;
