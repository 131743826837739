import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Box, Button, Divider, Grid, Stack, styled, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import ga, { gtag } from '../../../AnalyticsEventTracker';
import theme from '../../../styles/theme';
import useDetectMobile from '../../../hooks/useDetectMobile';
import Phone from '../../controllerFormFields/Phone';
import { validateEmail } from '../../../common/Util';
import { useGetCurrentCountryQuery } from '../../../store/slices/currentCountryApi';
import TextField from '../../controllerFormFields/TextField';
import { useGetCurrentUserQuery } from '../../../store/slices/clientApi';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontWeight: 900,
});

const StyledLabel = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '1rem',
  marginBottom: '0px',
});

function StyledRequiredLabel(text) {
  return (
    <span style={{ color: theme.palette.grey[500], fontSize: '0.7rem' }}>{text}</span>
  );
}

function PaymentForm({
  onChange, onLoading,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const {
    control, handleSubmit,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });

  const { data: currentCountry } = useGetCurrentCountryQuery();
  const { data: userData } = useGetCurrentUserQuery();
  const [paymentIsComplete, setPaymentIsComplete] = useState(false);
  const [contactInformation, setContactInformation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [t] = useTranslation('common');
  const [buttonText, setButtonText] = useState(t('PaymentForm.ConfirmAndPay'));
  const { isMobile } = useDetectMobile();

  useEffect(() => {
    setContactInformation(null);
    if (elements) {
      const paymentElementRef = elements.getElement('payment');
      paymentElementRef.on('change', (event) => {
        if (event.complete) setPaymentIsComplete(true);
        else if (event.empty) setPaymentIsComplete(false);
      });
    }
  }, [elements]);

  const onSubmit = (data) => {
    if (!stripe || !elements) {
      return;
    }
    ga('OnBoarding', 'Click', 'Pay');
    if (!contactInformation) {
      const contactInfo = {};
      Object.keys(data).forEach((key) => {
        if (data[key].label) {
          contactInfo[key] = data[key].label;
        } else {
          contactInfo[key] = data[key];
        }
      });
      onLoading(true);

      const billingDetails = {};
      if (userData && userData.client.email) {
        billingDetails.email = userData.client.email;
      } else if (contactInfo && contactInfo.clientEmail) {
        billingDetails.email = contactInfo.clientEmail;
      }

      if (userData && userData.client.name) {
        billingDetails.name = `${userData.client.name} ${userData.clientLastName}`;
      } else if (contactInfo && contactInfo.clientName) {
        billingDetails.name = `${contactInfo.clientName} ${contactInfo.clientLastName}`;
      }

      if (userData && userData.client.phone) {
        billingDetails.phone = userData.client.phone;
      } else if (contactInfo && contactInfo.clientPhone) {
        billingDetails.phone = contactInfo.clientPhone;
      }

      stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
        redirect: 'if_required',
      }).then((response) => {
        const { paymentIntent, error } = response;
        onLoading(false);
        if (!error) {
          ga('OnBoarding', 'Event', 'Payment_succeeded');
          gtag('event', 'purchase', { transaction_id: paymentIntent.id, value: paymentIntent.amount, currency: paymentIntent.currency });
          contactInfo.paymentIntentId = paymentIntent.id;
          contactInfo.paymentMethodId = paymentIntent.payment_method;
          contactInfo.paymentMethodType = paymentIntent.payment_method_types[0].trim();
          contactInfo.paymentStatus = paymentIntent.status;

          setContactInformation(contactInfo);
          setButtonText(t('PaymentForm.RegisterButton'));
          onChange({ ...contactInfo });
        } else {
          ga('OnBoarding', 'Event', 'Payment_error');
          setErrorMessage(error.message);
        }
      }).catch(() => {
        ga('OnBoarding', 'Event', 'Payment_error');
        setErrorMessage('Ha ocurrido un error con el pago');
        onLoading(false);
      });
    } else {
      const contactInfo = contactInformation;
      Object.keys(data).forEach((key) => {
        if (data[key].label) {
          contactInfo[key] = data[key].label;
        } else {
          contactInfo[key] = data[key];
        }
      });
      setContactInformation(contactInfo);
      onChange({ ...contactInfo });
    }
  };

  return (
    <Box
      component="form"
      ql="qa-ctnr-resetpassword"
      id="payment-form"
      sx={{ marginTop: isMobile ? '0px' : '30px' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} sx={{ boxShadow: 'rgb(0 0 0 / 45%) -15px 0px 10px -20px;', marginLeft: isMobile ? 'none' : '-25px' }}>
        {!userData.client && (
        <Grid item xs={12} md={12} lg={12}>
          <StyledTypography color={theme.palette.primary.main}>
            {t('PaymentForm.InformacionContacto')}
          </StyledTypography>
        </Grid>
        )}
        {!userData.client.name && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledLabel>
              {t('PaymentForm.Nombre')}
              <StyledRequiredLabel text={t('PaymentForm.RequiredField')} />
            </StyledLabel>
            <TextField
              name="clientName"
              key="clientName"
              placeholder=""
              size="small"
              control={control}
              rules={{ required: 'Nombre necesario' }}
              fullWidth
            />
          </Grid>
        )}
        {!userData.client && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledLabel>
              {t('PaymentForm.Apellidos')}
              <StyledRequiredLabel text={t('PaymentForm.RequiredField')} />
            </StyledLabel>
            <TextField
              name="clientLastName"
              key="clientLastName"
              placeholder=""
              size="small"
              control={control}
              rules={{ required: t('PaymentForm.ApellidoN') }}
              fullWidth
            />
          </Grid>
        )}
        {!userData.client && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledLabel>
              {t('PaymentForm.Telefono')}
              <StyledRequiredLabel text={t('PaymentForm.RequiredField')} />
            </StyledLabel>
            <Phone
              name="clientPhone"
              key="clientPhone"
              placeholder=""
              size="small"
              required
              control={control}
              fullWidth
              currentCountry={currentCountry?.country_code}
            />
          </Grid>
        )}
        {!userData.client && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StyledLabel>
              {t('PaymentForm.Correo')}
              <StyledRequiredLabel text={t('PaymentForm.RequiredField')} />
            </StyledLabel>
            <TextField
              name="clientEmail"
              key="clientEmail"
              placeholder=""
              size="small"
              control={control}
              rules={{ required: t('PaymentForm.EmailN'), validate: { email: (v) => validateEmail(v) || t('PaymentForm.EmailInv') } }}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <StyledTypography color={theme.palette.primary.main}>
            {t('PaymentForm.InformacionPago')}
          </StyledTypography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <PaymentElement />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {errorMessage && (<Alert sx={{ marginBottom: '15px', fontFamily: 'Montserrat' }} onClose={() => setErrorMessage(null)} severity="error">{errorMessage}</Alert>)}
          <Button
            variant="contained"
            type="submit"
            sx={{
              borderRadius: '20px',
              width: '100%',
              fontFamily: 'Montserrat',
              textTransform: 'none',
              '&:hover': {
                color: '#273B7C',
                backgroundColor: '#B4C5FF',
              },
            }}
            data-testid="pay"
            disabled={!(paymentIsComplete && isValid)}
          >
            {buttonText}
          </Button>
          <Divider sx={{ mt: 4 }} />
        </Grid>
        <Grid container item style={{ marginTop: '-20px' }}>
          <Box display="flex" alignItems="left" sx={{ mt: 2 }} disableRipple>
            <LockOutlinedIcon fontSize="large" sx={{ mr: 2, mt: 2, color: theme.palette.primary.main }} />
            <Stack>
              <StyledTypography sx={{
                mt: 2,
                textAlign: 'left',
                color: theme.palette.primary.main,
                fontSize: '16px',
                fontWeight: 800,
              }}
              >
                {t('PaymentForm.Proteccion')}
              </StyledTypography>
              <Typography sx={{
                mt: 2, textAlign: 'left', fontSize: '16px', color: theme.palette.common.black,
              }}
              >
                {t('PaymentForm.MentorCobra')}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

PaymentForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
};

export default PaymentForm;
