import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Box, Button,
  Card, Divider, Grid, Stack, styled, Typography,
} from '@mui/material';
import { Fade, Zoom } from 'react-reveal';
import ReactPlayer from 'react-player';
import { YouTube as YouTubeIcon } from '@mui/icons-material';
import useDetectMobile from '../hooks/useDetectMobile';
import BasicLayout from '../components/layout/BasicLayout';
import MensisButton from '../components/button/MensisButton';
import ga from '../AnalyticsEventTracker';
import business1 from '../assets/img/business1.png';
import business2 from '../assets/img/business2.png';
import business3 from '../assets/img/business3.png';
import business4 from '../assets/img/business4.png';
import google from '../assets/img/Google.png';
import castroCarazo from '../assets/img/CastroCarazo.jpeg';
import goque from '../assets/img/goque.jpeg';
import remax from '../assets/img/remax.png';
import procomer from '../assets/img/procomer.jpg';
import Footer from '../components/footer/Footer';
import { businessBenefitsData } from '../data/GeneralData';
import useAuth from '../hooks/useAuth';
import { AuthStates } from '../constants/authConstants';

const StyledButton = styled(Button)({
  fontFamily: 'Montserrat',
  backgroundColor: '#273B7C',
  color: 'white',
  height: '50px',
  borderRadius: '5px',
  '&:hover': {
    color: '#273B7C',
    backgroundColor: '#B4C5FF',
    boxShadow: '2px 2px 10px #888888',
  },
  textTransform: 'none',
  marginTop: '25px',
  fontSize: '1rem',
  boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
});

function Business() {
  const [t] = useTranslation('common');
  const { isMobile } = useDetectMobile();
  const { authStatus } = useAuth();

  return (
    <BasicLayout showBanner displayLinks={authStatus !== AuthStates.LOGGED_IN}>
      <Box>
        <main id="main">
          <section id="business-main">
            <div className="container">
              <Fade right>
                <Grid
                  container
                  spacing={2}
                  mt={{ xs: 10, sm: 10, md: 2 }}
                  sx={{ mt: isMobile ? -5 : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ boxShadow: 0, backgroundColor: '#FCFCFC' }}>
                      <Stack mx="10px" my="10px">
                        <Typography
                          variant="h1"
                          sx={{
                            textAlign: 'left', fontSize: '40px', color: '#ec5c24', marginBottom: '15px',
                          }}
                        >
                          {t('Business.Cuerpo.p1')}
                        </Typography>
                        <Typography
                          variant="h2"
                          sx={{
                            textAlign: 'left', fontSize: '20px', marginBottom: '30px', color: '#273B7C',
                          }}
                        >
                          {t('Business.Cuerpo.p2')}
                        </Typography>
                        <StyledButton
                          sx={{ width: isMobile ? '100%' : '280px' }}
                          label={t('Business.ObtenerDemo')}
                          onClick={() => { ga('Business', 'Click', 'GetBusinessDemonstration'); window.open('https://calendly.com/mensislearning/mensis-para-empresas', '_self'); }}
                        >
                          {t('Business.ObtenerDemo')}
                        </StyledButton>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    {isMobile ? (
                      <ReactPlayer
                        url="https://youtu.be/v5MdlovHBcU"
                        light
                        playing
                        loop
                        controls
                        width="100%"
                        playIcon={<YouTubeIcon sx={{ color: '#f00', fontSize: 70 }} />}
                        onClickPreview={() => ga('Business', 'Click', 'PlayVideo')}
                      />
                    ) : (
                      <ReactPlayer
                        url="https://youtu.be/v5MdlovHBcU"
                        light
                        playing
                        loop
                        controls
                        playIcon={<YouTubeIcon sx={{ color: '#f00', fontSize: 70 }} />}
                        onClickPreview={() => ga('Business', 'Click', 'PlayVideo')}
                      />
                    )}
                  </Grid>
                </Grid>
              </Fade>
            </div>
          </section>

          <section id="business-benefits">
            <Fade right>
              <Grid container spacing={1}>
                {businessBenefitsData.map((data) => (
                  <Grid
                    key={data.description}
                    item
                    container
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    spacing={1}
                    style={{
                      backgroundColor: '#273B7C', height: isMobile ? '100px' : '150px', alignItems: 'center', justifyContent: 'center', borderColor: 'white', borderStyle: 'solid',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography sx={{
                        color: 'white',
                        fontSize: '20px',
                        marginRight: '10px',
                      }}
                      >
                        {t(data.description)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Fade>
          </section>

          <section id="unblock-hidden-power">
            <div className="container">
              <Fade left>
                <Grid
                  container
                  spacing={2}
                  mt={{ xs: 10, sm: 10, md: 2 }}
                  sx={{ mt: isMobile ? -5 : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ boxShadow: 0, marginBottom: '10px', backgroundColor: '#FCFCFC' }}>
                      <Stack mx="10px" my="10px">
                        <Typography
                          variant="h2"
                          sx={{
                            textAlign: 'left', fontSize: '30px', color: '#273B7C', marginBottom: '5px',
                          }}
                        >
                          {t('Business.DesbloqueaTitulo')}
                        </Typography>
                        <Divider style={{ background: '#ec5c24', height: 3 }} />
                        <ol style={{ color: '#273B7C', marginTop: '20px', fontSize: '18px' }}>
                          <li>
                            {t('Business.Desbloquea.Item.1')}
                          </li>
                          <li>
                            {t('Business.Desbloquea.Item.2')}
                          </li>
                          <li>
                            {t('Business.Desbloquea.Item.3')}
                          </li>
                        </ol>
                      </Stack>
                    </Card>
                    <div style={{ marginLeft: '20px' }}>
                      <MensisButton
                        color="#ec5c24"
                        label={t('Business.ObtenerDemo')}
                        onClick={() => { ga('Business', 'Click', 'GetBusinessDemonstration'); window.open('https://calendly.com/mensislearning/mensis-para-empresas', '_self'); }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    <Card sx={{ boxShadow: 0, backgroundColor: 'transparent', borderRadius: '20px' }}>
                      <img width="100%" src={business1} alt="Sketch Mensis team" />
                    </Card>
                  </Grid>
                </Grid>
              </Fade>
            </div>
          </section>

          <section id="talent-retention" style={{ backgroundColor: '#DDE3F5' }}>
            <div className="container">
              <Fade right>
                <Grid
                  container
                  spacing={2}
                  mt={{ xs: 10, sm: 10, md: 2 }}
                  sx={{ mt: isMobile ? -5 : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{
                      boxShadow: 0, backgroundColor: 'transparent', borderRadius: '20px',
                    }}
                    >
                      <img width="100%" src={business2} alt="Sketch Mensis team" />
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    <Card sx={{ boxShadow: 0, backgroundColor: 'transparent', marginBottom: '10px' }}>
                      <Stack mx="10px" my="10px">
                        <Typography
                          variant="h2"
                          sx={{
                            textAlign: 'left', fontSize: '30px', color: '#273B7C', marginBottom: '5px',
                          }}
                        >
                          {t('Business.RetencionTitulo')}
                        </Typography>
                        <Divider style={{ background: '#ec5c24', height: 3 }} />
                        <ol style={{ color: '#273B7C', marginTop: '20px', fontSize: '18px' }}>
                          <li>
                            {t('Business.Retencion.Item.1')}
                          </li>
                          <li>
                            {t('Business.Retencion.Item.2')}
                          </li>
                          <li>
                            {t('Business.Retencion.Item.3')}
                          </li>
                        </ol>
                      </Stack>
                    </Card>
                    <div style={{ marginLeft: '20px' }}>
                      <MensisButton
                        color="#ec5c24"
                        label={t('Business.ObtenerDemo')}
                        onClick={() => { ga('Business', 'Click', 'GetBusinessDemonstration'); window.open('https://calendly.com/mensislearning/mensis-para-empresas', '_self'); }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Fade>
            </div>
          </section>

          <section id="future-labor-power">
            <div className="container">
              <Fade left>
                <Grid
                  container
                  spacing={2}
                  mt={{ xs: 10, sm: 10, md: 2 }}
                  sx={{ mt: isMobile ? -5 : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ boxShadow: 0, marginBottom: '10px', backgroundColor: '#FCFCFC' }}>
                      <Stack mx="10px" my="10px">
                        <Typography
                          variant="h2"
                          sx={{
                            textAlign: 'left', fontSize: '30px', color: '#273B7C', marginBottom: '5px',
                          }}
                        >
                          {t('Business.ConstruirTitulo')}
                        </Typography>
                        <Divider style={{ background: '#ec5c24', height: 3 }} />
                        <ol style={{ color: '#273B7C', marginTop: '20px', fontSize: '18px' }}>
                          <li>
                            {t('Business.Construir.Item.1')}
                          </li>
                          <li>
                            {t('Business.Construir.Item.2')}
                          </li>
                          <li>
                            {t('Business.Construir.Item.3')}
                          </li>
                        </ol>
                      </Stack>
                    </Card>
                    <div style={{ marginLeft: '20px' }}>
                      <MensisButton
                        color="#ec5c24"
                        label={t('Business.ObtenerDemo')}
                        onClick={() => { ga('Business', 'Click', 'GetBusinessDemonstration'); window.open('https://calendly.com/mensislearning/mensis-para-empresas', '_self'); }}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    <Card sx={{ boxShadow: 0, backgroundColor: 'transparent', borderRadius: '20px' }}>
                      <img width="100%" src={business3} alt="Sketch Mensis team" />
                    </Card>
                  </Grid>
                </Grid>
              </Fade>
            </div>
          </section>

          <section id="client-retention" style={{ backgroundColor: '#DDE3F5' }}>
            <div className="container">
              <Fade right>
                <Grid
                  container
                  spacing={2}
                  mt={{ xs: 10, sm: 10, md: 2 }}
                  sx={{ mt: isMobile ? -5 : 0 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ boxShadow: 0, backgroundColor: 'transparent', borderRadius: '20px' }}>
                      <img width="100%" src={business4} alt="Sketch Mensis team" />
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                  >
                    <Card sx={{ boxShadow: 0, backgroundColor: 'transparent', marginBottom: '10px' }}>
                      <Stack mx="10px" my="10px">
                        <Typography
                          variant="h2"
                          sx={{
                            textAlign: 'left', fontSize: '30px', color: '#273B7C', marginBottom: '5px',
                          }}
                        >
                          {t('Business.RetencionClientesTitulo')}
                        </Typography>
                        <Divider style={{ background: '#ec5c24', height: 3 }} />
                        <ol style={{ color: '#273B7C', marginTop: '20px', fontSize: '18px' }}>
                          <li>
                            {t('Business.RetencionClientes.Item.1')}
                          </li>
                          <li>
                            {t('Business.RetencionClientes.Item.2')}
                          </li>
                        </ol>
                      </Stack>
                    </Card>
                    <div style={{ marginLeft: '20px' }}>
                      <MensisButton
                        color="#ec5c24"
                        label={t('Business.ObtenerDemo')}
                        onClick={() => { ga('Business', 'Click', 'GetBusinessDemonstration'); window.open('https://calendly.com/mensislearning/mensis-para-empresas', '_self'); }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Fade>
            </div>
          </section>
          <section
            id="logoGridSection"
            style={{
              padding: '50px 0',
              textAlign: 'center',
              paddingBottom: 80,
            }}
          >
            <div className="container">
              <h1 style={{ fontFamily: 'Montserrat', color: '#273b7c', paddingBottom: 30 }}>
                {t('Business.successStories')}
              </h1>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: '20px',
                }}
              >
                <div><img src={procomer} alt="Logo 1" style={{ maxWidth: '200px' }} /></div>
                <div><img src={goque} alt="Logo 2" style={{ maxWidth: '200px' }} /></div>
                <div><img src={google} alt="Logo 3" style={{ maxWidth: '200px' }} /></div>
                <div><img src={castroCarazo} alt="Logo 4" style={{ maxWidth: '200px' }} /></div>
                <div><img src={remax} alt="Logo 5" style={{ maxWidth: '200px' }} /></div>
              </div>
            </div>
          </section>
          <section
            id="cta1"
            style={{
              backgroundColor: '#273b7c',
            }}
          >
            <div className="container">
              <Zoom>
                <div className="container">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                  >
                    <h1 style={{ fontFamily: 'Montserrat', color: 'white' }}>
                      {t('Business.PiePaginaCuerpo.5')}
                    </h1>
                    <h5 style={{ fontFamily: 'Montserrat', color: 'white', paddingBottom: 20 }}>
                      {t('Business.PiePaginaCuerpo.6part1')}
                      <strong>
                        {t('Business.PiePaginaCuerpo.6part2')}
                      </strong>
                    </h5>
                    <MensisButton
                      color="#ec5c24"
                      label={t('Business.ObtenerDemo')}
                      onClick={() => {
                        ga('Business', 'Click', 'GetBusinessDemonstration');
                        window.open('https://calendly.com/mensislearning/mensis-para-empresas', '_self');
                      }}
                    />
                  </div>
                </div>
              </Zoom>
            </div>
          </section>
        </main>
        <Footer />
      </Box>
    </BasicLayout>
  );
}

Business.propTypes = {};

Business.defaultProps = {};

export default Business;
