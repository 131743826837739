import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Typography,
  Select,
  styled,
} from '@mui/material';
import TextFormFieldWithId from './TextFormFieldWithId';
import theme from '../../styles/theme';
import ga from '../../AnalyticsEventTracker';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const otherOption = {
  id: 'other',
  label: 'Otro',
};

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  textAlign: 'center',
  fontSize: '1.5rem',
});

const StyledSelect = styled(Select)({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  width: '80%',
  marginLeft: '10%',
  fieldset: {
    legend: {
      width: 'unset',
    },
  },
  '&.MuiInputBase-root .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
});

const StyledListItemText = styled(ListItemText)({
  span: {
    fontFamily: 'Montserrat',
  },
});

function SelectMultipleField({
  id, label, options, value, other, otherPlaceholderTextField, onChange,
}) {
  const [selection, setSelection] = useState(value);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherText, setOtherText] = useState('');

  useEffect(() => {
    if (selection.indexOf(otherOption.id) > -1) {
      onChange(selection.map((element) => {
        if (element === otherOption.id) {
          return { id: element, label: otherText };
        }
        return options.find((optionTemp) => optionTemp.id === element);
      }));
    }
  }, [selection, otherText, onChange, options]);

  useEffect(() => {
    setSelection(value);
    if (value.indexOf(otherOption.id) > -1) {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
    }
  }, [value]);

  const handleChangeSelection = ({ target }) => {
    const { value: tempValue } = target;
    setSelection(tempValue);
    ga('OnBoarding', 'Click', `SelectMultipleField_${id}`);
    if (tempValue.indexOf(otherOption.id) > -1) {
      setOtherSelected(true);
    } else {
      setOtherSelected(false);
      onChange(tempValue.map((item) => options.find((optionTemp) => optionTemp.id === item)));
    }
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      {label && <StyledTypography sx={{ my: 5, marginBottom: '10px' }}>{label}</StyledTypography>}
      <StyledSelect
        id={id}
        multiple
        value={selection}
        onChange={handleChangeSelection}
        input={<OutlinedInput />}
        data-testid={id}
        name={id}
        renderValue={(selected) => (
          selected.map((valueTemp) => (valueTemp === otherOption.id
            ? (otherOption.label)
            : (options.find((optionTemp) => optionTemp.id === valueTemp)?.label)
          )).join(', '))}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selection.indexOf(option.id) > -1} />
            <StyledListItemText primary={option.label} sx={{ overflowX: 'auto' }} />
          </MenuItem>
        ))}
        {other
        && (
          <MenuItem key={otherOption.id} value={otherOption.id}>
            <Checkbox checked={selection.indexOf(otherOption.id) > -1} />
            <StyledListItemText primary={otherOption.label} />
          </MenuItem>
        )}
      </StyledSelect>
      {otherSelected && (
      <TextFormFieldWithId
        id={`${id}_other`}
        value={otherText}
        onChange={(text) => { setOtherText(text.label); }}
        placeholder={otherPlaceholderTextField}
        sx={{ mt: 2, width: '80%', marginLeft: '10%' }}
      />
      )}
    </FormControl>
  );
}

SelectMultipleField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  other: PropTypes.bool,
  otherPlaceholderTextField: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SelectMultipleField.defaultProps = {
  id: `select_${Math.random().toString(5)}`,
  label: null,
  value: [],
  other: false,
  otherPlaceholderTextField: 'Escriba aquí',
};

export default SelectMultipleField;
