import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Box, CssBaseline, Fab,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavBar from '../nav/navbar/Navbar';
import { useGetBannerMessageQuery } from '../../store/slices/bannerMessageApi';
import Banner from '../banner/Banner';
import ScrollTop from './ScrollTop';
import PrivateNavbar from '../nav/navbar/PrivateNavbar';
import useAuth from '../../hooks/useAuth';
import { AuthStates } from '../../constants/authConstants';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function BasicLayout({
  displayLinks, showBanner, children, showBackToTopAnchor,
}) {
  const { authStatus } = useAuth();
  const { data: activeBannerMessage } = useGetBannerMessageQuery();

  return (
    <Box sx={{
      display: 'flex', width: '100%', flexDirection: 'column', backgroundColor: '#FCFCFC',
    }}
    >
      <CssBaseline />
      { authStatus === AuthStates.LOGGED_IN
        ? <PrivateNavbar /> : <NavBar displayLinks={displayLinks} />}
      {activeBannerMessage && activeBannerMessage.banner && showBanner && (
        <Banner
          {...activeBannerMessage?.banner}
          showCountDown={
            activeBannerMessage?.banner.showCountDown === 1 || false
          }
          customMargin={65}
        />
      )}
      <DrawerHeader id="back-to-top-anchor" />
      {children}
      { showBackToTopAnchor && (
        <ScrollTop>
          <Fab size="small" aria-label="scroll back to top" style={{ backgroundColor: 'var(--secondary-logo)', color: 'white' }}>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      )}
    </Box>
  );
}

BasicLayout.propTypes = {
  displayLinks: PropTypes.bool,
  showBanner: PropTypes.bool,
  children: PropTypes.node.isRequired,
  showBackToTopAnchor: PropTypes.bool,
};

BasicLayout.defaultProps = {
  displayLinks: true,
  showBanner: true,
  showBackToTopAnchor: true,
};

export default BasicLayout;
