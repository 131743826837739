import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Grid, Rating, styled, Typography,
} from '@mui/material';
import {
  LocationOn as LocationOnIcon,
  MilitaryTech as MilitaryTechIcon,
  Person as PersonIcon,
  People as PeopleIcon,
  WorkspacePremium,
} from '@mui/icons-material';
import ReactCountryFlag from 'react-country-flag';
import { countryToAlpha2 } from 'country-to-iso';
import { Image } from 'react-bootstrap';
import theme from '../../../styles/theme';
import { addThousandSeparator } from '../../../common/Util';
import ProfilePhotoDialog from '../../dialog/ProfilePhotoDialog';
import linkedinImage from '../../../assets/img/social/linkedin-logo.png';
import instagramImage from '../../../assets/img/social/instagram-logo.png';
import tiktokImage from '../../../assets/img/social/tiktok-logo.png';
import youtubeImage from '../../../assets/img/social/youtube-logo.png';
import facebookImage from '../../../assets/img/social/facebook-logo.png';
import spotifyImage from '../../../assets/img/social/spotify-logo.png';

const StyledTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  marginTop: '5px',
});

function SimpleMentorCard({
  mentorId,
  profilePhotoUrl,
  mentorName,
  mentorLastName,
  userName,
  currentPosition,
  liveCountry,
  mentorStars,
  servicesNumber,
  englishLevel,
  experienceYears,
  score,
  liveCountryId,
  avatarSize,
  showBackgroundColor,
  tiktokUrl,
  youtubeUrl,
  facebookUrl,
  linkedinUrl,
  instagramUrl,
  spotifyUrl,
  ...props
}) {
  const [showFullProfilePhoto, setShowFullProfilePhoto] = useState(false);
  const [t] = useTranslation('common');
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} {...props}>
      <Box sx={{
        backgroundColor: showBackgroundColor ? '#e9ecef' : '',
        borderRadius: '15px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        padding: '10px',
      }}
      >
        <Avatar
          onClick={() => setShowFullProfilePhoto(true)}
          src={profilePhotoUrl}
          sx={{
            width: avatarSize, height: avatarSize, marginTop: '5px', cursor: 'pointer',
          }}
        />
        {profilePhotoUrl && (
          <ProfilePhotoDialog
            showModal={showFullProfilePhoto}
            imgURL={profilePhotoUrl}
            onClose={() => setShowFullProfilePhoto(false)}
          />
        )}
        <StyledTypography sx={{ mt: 1, textAlign: 'center', color: theme.palette.primary.main }}>
          <WorkspacePremium />
          <b>{mentorName}</b>
          {' '}
          <b>{mentorLastName}</b>
        </StyledTypography>
        <Typography style={{ fontFamily: 'Montserrat', textAlign: 'center' }}>{currentPosition}</Typography>
        {mentorStars && (
          <Rating readOnly defaultValue={mentorStars} precision={0.5} />
        )}
        <div style={{ paddingRight: '15%', paddingLeft: '15%' }}>
          <Grid container style={{ marginTop: '0px', textAlign: 'center' }}>
            {linkedinUrl && (
              <Grid key="Linkedin image" sx={{ padding: '5px' }} item>
                <a
                  href={linkedinUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    alt="Linkedin image"
                    src={linkedinImage}
                    width="30px"
                  />
                </a>
              </Grid>
            )}
            {youtubeUrl && (
              <Grid key="Youtube image" sx={{ padding: '5px' }} item>
                <a
                  href={youtubeUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    alt="Youtube image"
                    src={youtubeImage}
                    width="30px"
                  />
                </a>
              </Grid>
            )}
            {tiktokUrl && (
              <Grid key="Tiktok image" sx={{ padding: '5px' }} item>
                <a
                  href={tiktokUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    alt="Tiktok image"
                    src={tiktokImage}
                    width="30px"
                  />
                </a>
              </Grid>
            )}
            {facebookUrl && (
              <Grid key="Facebook image" sx={{ padding: '5px' }} item>
                <a
                  href={facebookUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    alt="Facebook image"
                    src={facebookImage}
                    width="30px"
                  />
                </a>
              </Grid>
            )}
            {instagramUrl && (
              <Grid key="Instagram image" sx={{ padding: '5px' }}>
                <a
                  href={instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    alt="Instagram image"
                    src={instagramImage}
                    width="30px"
                  />
                </a>
              </Grid>
            )}
            {spotifyUrl && (
              <Grid key="Spotify image" sx={{ padding: '5px' }}>
                <a
                  href={spotifyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    alt="Spotify image"
                    src={spotifyImage}
                    width="30px"
                  />
                </a>
              </Grid>
            )}
          </Grid>
        </div>
      </Box>
      <Box sx={{
        borderRadius: '15px',
        marginTop: '5px',
        padding: '5px',
      }}
      >
        {liveCountry && (
          <StyledTypography>
            <LocationOnIcon sx={{ color: theme.palette.primary.main }} />
            {` ${liveCountry}`}
            {' '}
            <ReactCountryFlag countryCode={`${countryToAlpha2(liveCountry)}`} />
          </StyledTypography>
        )}
        {servicesNumber > -1 && (
          <StyledTypography>
            <MilitaryTechIcon sx={{ color: theme.palette.primary.main }} />
            {` ${addThousandSeparator(servicesNumber)} ${t('SimpleMentorCard.serviciosCertificados')} `}
          </StyledTypography>
        )}
        {englishLevel && (
          <StyledTypography>
            <PersonIcon sx={{ color: theme.palette.primary.main }} />
            {` ${t('SimpleMentorCard.NivelIngles')} ${englishLevel}`}
          </StyledTypography>
        )}
        {experienceYears > 0 && (
          <StyledTypography>
            <PeopleIcon sx={{ color: theme.palette.primary.main }} />
            {experienceYears >= 11 && t('SimpleMentorCard.masde')}
            {` ${experienceYears} ${t('SimpleMentorCard.aexperiencia')}`}
          </StyledTypography>
        )}
      </Box>
    </Box>
  );
}

SimpleMentorCard.propTypes = {
  mentorId: PropTypes.number.isRequired,
  profilePhotoUrl: PropTypes.string,
  mentorName: PropTypes.string.isRequired,
  mentorLastName: PropTypes.string,
  userName: PropTypes.string,
  currentPosition: PropTypes.string.isRequired,
  liveCountry: PropTypes.string.isRequired,
  liveCountryId: PropTypes.string,
  mentorStars: PropTypes.number,
  servicesNumber: PropTypes.number,
  englishLevel: PropTypes.string.isRequired,
  experienceYears: PropTypes.number.isRequired,
  score: PropTypes.string,
  avatarSize: PropTypes.string,
  showBackgroundColor: PropTypes.bool,
  tiktokUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  spotifyUrl: PropTypes.string,
};

SimpleMentorCard.defaultProps = {
  profilePhotoUrl: null,
  mentorLastName: null,
  userName: null,
  liveCountryId: null,
  mentorStars: 0,
  servicesNumber: 0,
  score: '0.2',
  avatarSize: '150px',
  showBackgroundColor: true,
  tiktokUrl: null,
  youtubeUrl: null,
  facebookUrl: null,
  linkedinUrl: null,
  instagramUrl: null,
  spotifyUrl: null,
};

export default SimpleMentorCard;
