/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  country: '',
  category: '',
  englishLevel: '',
  page: 0,
};

const mentorsPageSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeCountry: (state, { payload }) => {
      state.country = payload;
    },
    changeCategory: (state, { payload }) => {
      state.category = payload;
    },
    changeEnglishLevel: (state, { payload }) => {
      state.englishLevel = payload;
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    nextPage: (state) => {
      state.page += 1;
    },
    prevPage: (state) => {
      state.page -= 1;
    },
    resetFilters: () => initialState,
  },
});

export const {
  changeCountry, changeCategory, changeEnglishLevel, changePage, nextPage, prevPage, resetFilters,
} = mentorsPageSlice.actions;

const mentorsPageState = (state) => state.mentorsPageSlice;

export const countrySelector = createSelector([mentorsPageState], (state) => state.country);
export const categorySelector = createSelector([mentorsPageState], (state) => state.category);
export const englishLevelSelector = createSelector(
  [mentorsPageState],
  (state) => state.englishLevel,
);
export const pageSelector = createSelector([mentorsPageState], (state) => state.page);

export default mentorsPageSlice.reducer;
