import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-reveal';
import useDetectMobile from '../hooks/useDetectMobile';
import LoadingDialog from '../components/dialog/LoadingDialog';
import BasicLayout from '../components/layout/BasicLayout';
import ClientOnboardingQuestionsStep from '../components/onboarding/ClientOnboardingQuestionsStep';
import { loadingDialogMessageSelector } from '../store/slices/loadingDialogMessageSlice';
import GalileoStep from '../components/onboarding/GalileoStep';
import ConfirmMentorshipStep from '../components/onboarding/ConfirmMentorshipStep';
import ServicesStep from '../components/onboarding/ServicesStep';
import { registerOnboardingResponsesV3 } from '../services/onboardingService';
import {
  setResponsesId,
  questionsAndResponsesSelector,
  responsesIdSelector,
} from '../store/slices/clientOnboardingSlice';
import useAuth from '../hooks/useAuth';
import { AuthStates } from '../constants/authConstants';
import ga from '../AnalyticsEventTracker';

const StyledStep = styled(Step)({
  '& .MuiStepLabel-root .Mui-completed': {
    fill: '#C7440D',
  },
  '& .MuiStepLabel-root .Mui-active': {
    fill: '#C7440D',
  },
});

const StyledStepLabel = styled(StepLabel)({
  fontFamily: 'Montserrat',
  '.MuiStepLabel-label': { fontFamily: 'Montserrat' },
});

const StyledTitleTypography = styled(Typography)({
  fontFamily: 'Montserrat',
  mb: 2,
  textAlign: 'center',
  fontSize: '2rem',
  color: '#273B7C',
});

function ClientOnboarding() {
  const [t] = useTranslation('common');
  const [step, setStep] = useState(0);
  const [isThereServerError, setIsThereServerError] = useState(false);
  const { authStatus, triggerGetCookie } = useAuth();
  const { message } = useSelector(loadingDialogMessageSelector);
  const questionsAndResponses = useSelector(questionsAndResponsesSelector);
  const responsesId = useSelector(responsesIdSelector);
  const { isMobile } = useDetectMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authStatus === AuthStates.LOGGED_IN
      && questionsAndResponses
      && responsesId === null) {
      const body = questionsAndResponses;
      registerOnboardingResponsesV3(body)
        .then((response) => response.json())
        .then((dataResponse) => {
          if (dataResponse.status === 200) {
            ga('OnBoarding', 'Event', 'RegisterOnboardingResponsesOK');
            dispatch(setResponsesId(dataResponse.responsesId));
          } else {
            ga('OnBoarding', 'Event', 'RegisterOnboardingResponsesNotOK');
          }
        })
        .catch(() => {
          ga('OnBoarding', 'Event', 'RegisterOnboardingResponsesFailed');
        });
    }
  }, [responsesId, questionsAndResponses, authStatus, dispatch]);

  window.scrollTo(0, 0);

  const successLogin = () => {
    triggerGetCookie();
  };

  const clickReturn = () => {
    setIsThereServerError(false);
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/');
      window.scrollTo(0, 0);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const onboardingSteps = [
    {
      title: t('ClientOnboarding.onboardingSteps.title.1'),
      steppersTitle: t('ClientOnboarding.onboardingSteps.steppersTitle.1'),
      description: t('ClientOnboarding.onboardingSteps.description.1'),
      component: <ClientOnboardingQuestionsStep
        onFinish={nextStep}
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
      />,
    },
    {
      title: t('ClientOnboarding.onboardingSteps.title.2'),
      steppersTitle: t('ClientOnboarding.onboardingSteps.steppersTitle.2'),
      description: t('ClientOnboarding.onboardingSteps.description.2'),
      component: <GalileoStep
        onChange={nextStep}
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
      />,
    },
    {
      title: t('ClientOnboarding.onboardingSteps.title.3'),
      steppersTitle: t('ClientOnboarding.onboardingSteps.steppersTitle.3'),
      description: t('ClientOnboarding.onboardingSteps.description.3'),
      component: <ServicesStep
        onChange={nextStep}
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
        onSuccessLogin={successLogin}
      />,
    },
    {
      title: t('ClientOnboarding.onboardingSteps.title.4'),
      steppersTitle: t('ClientOnboarding.onboardingSteps.steppersTitle.4'),
      description: t('ClientOnboarding.onboardingSteps.description.4'),
      component: <ConfirmMentorshipStep
        onReturn={clickReturn}
        isServerError={setIsThereServerError}
      />,
    },
  ];

  return (
    <BasicLayout showBanner displayLinks={false} showBackToTopAnchor={false}>
      <Fade right>
        <div style={{ marginTop: '15px' }}>
          {message && (
            <LoadingDialog
              showModal={!!message}
              text={message}
            />
          )}
          {isThereServerError && (
            <Alert
              sx={{ fontFamily: 'Montserrat' }}
              onClose={() => setIsThereServerError(false)}
              severity="error"
            >
              {t('ClientOnboarding.Error')}
            </Alert>
          )}
          <StyledTitleTypography variant="h1">
            {onboardingSteps[step].title}
          </StyledTitleTypography>
          <Typography
            variant="h3"
            sx={{
              mb: 2,
              textAlign: 'center',
              fontSize: '1rem',
              marginBottom: '15px',
              marginTop: '15px',
            }}
          >
            {onboardingSteps[step].description}
          </Typography>
          <Grid container>
            <Grid item md={2} sm={12} xs={12} />
            <Grid item md={8} sm={12} xs={12}>
              <Paper
                elevation={3}
                sx={{ m: 0, p: 2 }}
              >
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={step}>
                    {onboardingSteps.map((stepItem) => (
                      <StyledStep key={stepItem.steppersTitle}>
                        <StyledStepLabel>
                          {!isMobile && stepItem.steppersTitle}
                        </StyledStepLabel>
                      </StyledStep>
                    ))}
                  </Stepper>
                  {onboardingSteps[step].component}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </BasicLayout>
  );
}

ClientOnboarding.propTypes = {};

ClientOnboarding.defaultProps = {};

export default ClientOnboarding;
