import { useTranslation } from 'react-i18next';
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Typography,
  styled,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../styles/theme';
import useAuth from '../../hooks/useAuth';
import { validateEmail } from '../../common/Util';
import { signIn } from '../../services/authService';
import TextField from '../controllerFormFields/TextField';
import LoadingDialog from '../dialog/LoadingDialog';
import ga from '../../AnalyticsEventTracker';

const StyledTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontFamily: 'Montserrat',
  fontSize: '20px',
});

const StyledButton = styled(Button)({
  borderRadius: '5px',
  fontSize: '24px',
  textTransform: 'inherit',
});

function Login({ onLogin, changeAuth }) {
  const { setSessionToken, setJSessionID } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
  });
  const [t] = useTranslation('common');
  const onSubmit = (data) => {
    ga('Login', 'Click', 'Enter');
    setErrorMessage(null);
    setIsLoading(true);
    signIn(data)
      .then((response) => response.json())
      .then((authResponse) => {
        if (authResponse.error) {
          setErrorMessage(t('login.ErrorContraseña'));
          ga('Login', 'Event', 'FailedLogin');
        } else {
          ga('Login', 'Event', 'SuccessfulLogin');
          setSessionToken(authResponse.token);
          setJSessionID(authResponse.sessionId);
          onLogin(authResponse);
        }
        setIsLoading(false);
      });
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
    >
      {isLoading && <LoadingDialog showModal={isLoading} text={t('login.Ingresando')} />}
      <StyledTypography sx={{ fontSize: '32px' }}>
        {t('login.IncioSesion')}
      </StyledTypography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 5 }}
      >
        <StyledTypography>
          {t('login.Correoelectrónico')}
        </StyledTypography>
        <TextField
          control={control}
          fullWidth
          placeholder={t('login.Direccióncorreo')}
          rules={{ required: t('login.Emailnecesario'), validate: { email: (v) => validateEmail(v) || t('login.Emailinvalido') } }}
          name="email"
          autoFocus
        />
        <StyledTypography sx={{ mt: 2 }}>
          {t('login.Contraseña')}
        </StyledTypography>
        <TextField
          control={control}
          fullWidth
          name="password"
          type="password"
          placeholder={t('login.Contraseña')}
          isPasswordType
          rules={{ required: t('Signup.ContraseñaN') }}
          sx={{ mb: 2 }}
        />
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          disabled={!isValid}
          sx={{
            mt: 3,
            mb: 2,
          }}
        >
          {t('login.Iniciarsesión')}
        </StyledButton>
        {errorMessage && <Typography color="#d32f2f" variant="subtitle1" sx={{ marginTop: '-10px', fontWeight: 'bold' }}>{errorMessage}</Typography>}
        <Grid container sx={{ paddingTop: '45px' }}>
          <Grid
            item
            xs
          >
            <StyledTypography sx={{ fontSize: '18px' }}>
              <MuiLink component="button" type="button" onClick={() => { ga('Login', 'Click', 'ForgotPassword'); navigate('/forgotPassword'); }} sx={{ fontWeight: 'bold' }}>
                {t('login.Olvidécontraseña')}
              </MuiLink>
            </StyledTypography>
            <StyledTypography sx={{ fontSize: '18px' }}>
              {t('login.Notienescuenta')}
              {'  '}
              <MuiLink
                onClick={changeAuth}
                component="button"
                color="secondary"
                type="button"
                sx={{ fontWeight: 'bold' }}
              >
                {t('login.Registrate')}
              </MuiLink>
            </StyledTypography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  changeAuth: PropTypes.func.isRequired,
};

Login.defaultProps = {};

export default Login;
