import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Card, CardContent, Typography, styled,
} from '@mui/material';
import theme from '../../styles/theme';
import { ReactComponent as CircleArrowIcon } from '../../assets/icons/circleArrow.svg';

const StyledCard = styled(Card)(() => ({
  backgroundColor: 'rgba(39, 59, 124, 0.18)',
  borderRadius: '5px',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(39, 59, 124, 0.5)',
    boxShadow: '2px 2px 10px #888888',
    color: '#fcfcfc',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  textTransform: 'none',
  marginLeft: '16px',
  marginRight: '8px',
  fontWeight: 600,
}));

function IconButton({
  label, icon, onClick,
}) {
  return (
    <StyledCard
      onClick={onClick}
      elevation={3}
      sx={{ cursor: 'pointer', p: 1 }}
    >
      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {icon}
          <StyledTypography>
            {label}
          </StyledTypography>
        </Box>
        <Box sx={{ height: '35px' }}>
          <CircleArrowIcon />
        </Box>
      </CardContent>
    </StyledCard>
  );
}

IconButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  onClick: PropTypes.func.isRequired,
};

IconButton.defaultProps = {
  label: '',
  icon: null,
};

export default IconButton;
