import { setOptions } from './api';

const SERVER_URL = process.env.REACT_APP_MENSIS_API_URL;

export const uploadProfilePhoto = (event) => {
  const formData = new FormData();
  formData.append('file', event.target.files[0]);

  return fetch(
    `${SERVER_URL}/clients/v1/uploadProfilePhoto`,
    {
      ...setOptions(
        'PUT',
        true,
        '*/*',
        'multipart/form-data; boundary=AaB03x',
      ),
      body: formData,
    },
  );
};

export const updateClientProfile = (body) => fetch(
  `${SERVER_URL}/clients/v1/updateClientProfile`,
  { ...setOptions('PUT'), body: JSON.stringify(body) },
);

export const registerDataLanguage = (body) => fetch(
  `${SERVER_URL}/clients/v1/updatePreferredLanguage`,
  { ...setOptions('PUT'), body: JSON.stringify(body) },
);
