import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as FormIconSvg } from './form.svg';

function FormIcon(props) {
  return (
    <SvgIcon component={FormIconSvg} inheritViewBox {...props} />
  );
}

export default FormIcon;
